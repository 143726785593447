import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import './LoadingPage.css';

const propTypes = {
  className: PropTypes.string,
  displayText: PropTypes.string,
  displayEllipsis: PropTypes.bool,
};

type LoadingPageProps = PropTypes.InferProps<typeof propTypes>;

export default function LoadingPage(props: LoadingPageProps) {
  const { className, displayText, displayEllipsis } = props;
  return (
    <div
      id="loading-page"
      data-testid="loading-page"
      className={`Page container text-center mx-100 ${className}`}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      { displayText ? (
        <div
          className="loading-page-text text-center"
        >
          { displayText }
          { displayEllipsis ? (
            <div
              className="ellipsis-wrapper text-left"
            >
              <div
                className="ellipsis"
              />
            </div>
          ) : null }
        </div>
      ) : null }
    </div>
  );
}

LoadingPage.defaultProps = {
  children: '',
  displayText: null,
  displayEllipsis: false,
};
