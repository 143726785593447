import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader/PageHeader';

const propTypes = {
  returnLink: PropTypes.string,
  returnText: PropTypes.string,
};

type ErrorPageProps = PropTypes.InferProps<typeof propTypes>;

function ErrorPage({ returnText, returnLink }: ErrorPageProps) {
  document.title = 'Marshall Insights | Error';
  return (
    <div className="page page-not-authorized">
      <PageHeader title="500: Internal Server Error" returnText={returnText} returnLink={returnLink} />
      <div className="error-message-container">
        <p>
          Something went wrong with this page.
        </p>
      </div>
    </div>
  );
}

ErrorPage.defaultProps = {
  returnText: 'Back to Home',
  returnLink: '/',
};

export default ErrorPage;
