import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Spinner, Button, Form } from 'react-bootstrap';
import {
  login,
  validateEmail,
  isCognitoLoggedin,
} from '../../controllers/auth';
import NewPasswordRequiredForm from './NewPasswordRequiredForm';
import './Login.css';
import { AsyncState, LocationState } from '../../utils/webRequests.type';
import { updateProfile } from '../../reducers/profile/profileSlice';
import { AppDispatch } from '../../app/store';
import sendRumError from '../../utils/datadogRum';

function Login() {
  document.title = 'Marshall Insights | Login';
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loginState, setLoginState] = useState<AsyncState>('uninitialized');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [resetPasswordSession, setResetPasswordSession] = useState<any | undefined>(undefined);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { from } = location.state as LocationState || {};
  const redirectPath = from ?? '/';
  useEffect(() => {
    if (isCognitoLoggedin()) {
      dispatch(updateProfile());
      navigate(redirectPath, { replace: true });
    }
  }, [loginState]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setErrorMessage(undefined);
    }
    return (() => {
      isMounted = false;
    });
  }, [email, password]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoginState('loading');
    event.persist();
    event.currentTarget.reset();
    event.preventDefault();
    await login(email, password)
      .then((result) => {
        if (result.status === 'NEWPASSWORDREQUIRED') {
          setResetPasswordSession(result.body);
        }
        setLoginState('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setEmail('');
        setPassword('');
        setLoginState('uninitialized');
        if (error.body) {
          setErrorMessage(error.body.message);
        } else {
          setErrorMessage('Failed to login');
        }
      });
  };

  if (resetPasswordSession) {
    return (
      <div className="login-page display-grid container justify-content-center">
        <NewPasswordRequiredForm
          session={resetPasswordSession}
          redirectPath={redirectPath}
        />
      </div>
    );
  }

  return (
    <div id="login-page" className="login-page display-grid container justify-content-center ">
      <Form
        onSubmit={handleSubmit}
        id="loginForm"
        className="align-self-center"
      >
        <img className="row" src="/assets/images/Marshall_logo_insights.svg" alt="Marshall Insights" />
        <Form.Group>
          <Form.Control
            id="username-input"
            name="email"
            type="email"
            autoComplete="off"
            className="input-block-level"
            placeholder="Email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            onInput={(e) => setEmail(e.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            id="password-input"
            name="password"
            type="password"
            autoComplete="off"
            className="input-block-level"
            placeholder="Password"
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </Form.Group>
        {
          (errorMessage)
            ? (
              <div role="alert" className="fade alert alert-danger show">
                {errorMessage}
              </div>
            )
            : null
        }
        <div className="login-area">
          <Link className="pull-right reset-link text-danger" to="/forgot">Forgot password?</Link>
          <br />
          <Button
            variant="primary"
            type="submit"
            disabled={loginState !== 'uninitialized' || !validateEmail(email) || (password === '')}
          >
            {loginState === 'uninitialized' ? 'Login' : <Spinner data-testid="login-spinner" size="sm" animation="grow" />}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Login;
