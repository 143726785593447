import axios, { AxiosInstance, AxiosProgressEvent, AxiosResponse } from 'axios';
import { headers } from '..';
import { QueryParameters, ValidationError } from '../../utils/webRequests.type';
import useAuthenticationInterceptors from '../auth/useAuthenticationInterceptors';
import {
  PortfolioFilter,
  PortfolioResultsV2,
  Product,
  ProductCreateBaseV2,
  ProductFacets,
  ProductNote,
  ProductResponse,
  ProductsGroupByResult,
  ProductsGroupBySummaryResult,
  ProductUpdateWithIdBaseV2,
  RelatedNotesResponse,
  SearchSKURequest,
  SearchSKUResponse,
} from './types';

let productService: AxiosInstance;

export const initializeProductController = (serviceUrl: string) => {
  productService = axios.create({
    baseURL: serviceUrl,
  });
  useAuthenticationInterceptors(productService);
};

export const fetchProducts = (
  orgId: number,
  params: QueryParameters = {},
) => productService.get<ProductResponse[] | ValidationError>('/products/', { params, headers: headers(orgId) });

export const fetchProduct = (orgId: number, productId: string) => productService.get<ProductResponse | ValidationError>(`/product/${productId}`, { headers: headers(orgId) });

export const postProduct = (orgId: number, data: Product) => productService.post<ProductResponse | ValidationError>('/product/', data, { headers: headers(orgId) });

export const postProductSet = (orgId: number, data: ProductCreateBaseV2[]) => productService.post<ProductResponse | ValidationError>('/product-set/', data, { headers: headers(orgId) });

export const putProductSet = (orgId: number, data: ProductUpdateWithIdBaseV2[]) => productService.put<ProductResponse | ValidationError>('/product-set/', data, { headers: headers(orgId) });

export const fetchProductFacets = (
  orgId: number,
) => productService.get<ProductFacets | ValidationError>(
  '/product-facets/',
  { headers: headers(orgId) },
);

/* istanbul ignore next */
/**
 * @deprecated endpoint is not in use as of 2023-03-20
 */
export const patchProduct = (
  orgId: number,
  data: ProductResponse,
) => productService.patch<ProductResponse | ValidationError>(
  `/product/${data.id}`,
  data,
  { headers: headers(orgId) },
);

// lying a bit: sending a GET as POST because
// we need to send the filter in the request Body.
export const getPortfolioFileWithBody = (
  orgId: number,
  filterPayload: PortfolioFilter,
  empty = false,
) => productService.post<Blob>(
  '/portfolio-file/filtered/',
  filterPayload,
  {
    headers: {
      ...headers(orgId),
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: {
      empty,
    },
    responseType: 'blob',
  },
);

export const postPortfolio = (
  orgId: number,
  form: FormData,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => productService.post<FormData, AxiosResponse<PortfolioResultsV2>>(
  '/portfolio-file-v2/',
  form,
  {
    params: {
      include_retailer: 'true',
    },
    headers: {
      ...headers(orgId),
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  },
);

export const getSearchSKUProducts = (
  orgId: number,
  searchParams: SearchSKURequest,
) => productService.get<SearchSKUResponse | ValidationError>(
  '/products/search_with_summary',
  { params: searchParams, headers: headers(orgId) },
);

export const getProductGroupBy = (
  orgId: number,
  searchField: string,
) => productService.get<ProductsGroupByResult[]>(
  `/products/group_by/${searchField}`,
  {
    headers: headers(orgId),
  },
);

export const getProductGroupBySummary = (
  orgId: number,
) => productService.get<ProductsGroupBySummaryResult>(
  '/products/group_by/summary',
  {
    headers: headers(orgId),
  },
);

export const getRelatedProductNotes = (
  productId: string,
  orgId: number,
) => productService.get<RelatedNotesResponse>(
  `/product/${productId}/notes/`,
  {
    headers: headers(orgId),
  },
);

export const postProductNote = (
  orgId: number,
  productId: string,
  note: string,
) => productService.post<ProductNote>(
  '/note/',
  {
    note,
    product_id: productId,
  },
  {
    headers: headers(orgId),
  },
);

export const deleteProductNote = (
  orgId: number,
  noteId: string,
) => productService.delete(
  `/note/${noteId}`,
  {
    headers: headers(orgId),
  },
);
