import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectOrganizationsStatus,
  setOrganizationsData,
  setOrganizationsStatus,
  updateOrganizations,
} from '../reducers/organizations/organizationsSlice';
import { fetchOrganizations } from '../controllers/user-service';
import { AppDispatch } from '../app/store';
import { selectCurrentOrgId, selectProfileStatus } from '../reducers/profile/profileSlice';
import sendRumError from '../utils/datadogRum';

const useOrgKeepAlive = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organizationStatus = useSelector(selectOrganizationsStatus);
  const profileStatus = useSelector(selectProfileStatus);
  const currentOrgId = useSelector(selectCurrentOrgId);

  // event handler to pull updated organization data from user-service
  // (or not if not logged in.)
  const listenerEventFetchOrganizations = () => {
    if (
      (organizationStatus === 'completed')
      && (profileStatus === 'completed')
    ) {
      fetchOrganizations(currentOrgId)
        .then((response) => {
          dispatch(setOrganizationsData(response.data));
        })
        .catch((error) => {
          sendRumError(
            error,
            {
              requestedAction: 'useOrgKeepAlive: fetchOrganizations',
            },
          );
          setOrganizationsStatus('failed');
        });
    }
  };

  useEffect(() => {
    window.addEventListener('user_service_token_change', listenerEventFetchOrganizations);
    return (() => {
      window.removeEventListener('user_service_token_change', listenerEventFetchOrganizations);
    });
  });

  useEffect(() => {
    if (organizationStatus === 'uninitialized' && profileStatus === 'completed') {
      dispatch(updateOrganizations());
    }
  }, [organizationStatus, profileStatus]);
};

export default useOrgKeepAlive;
