import React, { useEffect } from 'react';
import * as auth from '../../controllers/auth';

function Logout() {
  document.title = 'Marshall Insights | Logout';

  useEffect(() => {
    (new Promise((resolve) => { setTimeout(resolve, 500); }))
      .finally(() => auth.logout());
  }, []);

  return (
    <div className="h-100 page container justify-content-center ">
      Logging you out...
    </div>
  );
}

export default Logout;
