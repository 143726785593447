import React from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectNotificationsData, selectNotificationsStatus } from '../../../reducers/notifications/notificationSlice';
import { Attention, Info, Like2 } from '../../../icons';
import './NotificationCard.css';
import { FeatureOutageDetails, RetailerOutageDetails } from '../../../controllers/maintenance-service/types';
import { monitoredIssueDetailTypes } from '../../../utils/constants';

export default function NotificationCard() {
  const notificationData = useSelector(selectNotificationsData).filter(
    (issue) => monitoredIssueDetailTypes.includes(issue.type),
  );
  const notificationStatus = useSelector(selectNotificationsStatus);

  switch (notificationStatus) {
    case 'uninitialized':
      return (
        <Card
          className="notification-card notication-card-loading w-100 text-center"
        >
          <Spinner animation="border" />
        </Card>
      );
    case 'loading':
      return (
        <Card
          className="notification-card notication-card-loading w-100 text-center"
          data-testid="loading-notification-card"
        >
          <Spinner animation="border" />
        </Card>
      );
    case 'failed':
      return (
        <Card
          className="notification-card notification-card-failed w-100 text-center disabled"
        >
          <p>Something went wrong with loading notifications.</p>
          <Attention fill="var(--almost-black)" />
        </Card>
      );
    default:
      break;
  }

  if (notificationData.length === 0) {
    const like2Style = {
      height: '2.5rem',
      width: '2.5rem',
    };
    return (
      <Card
        className="notication-card w-100 text-center"
        aria-disabled={notificationData.length === 0}
      >
        <p className="notication-card-no-data-text text-muted">
          <Like2 fill="var(--gizmo-grey)" style={like2Style} />
          <br />
          <br />
          There are no retailer collection or feature outages at this time.
        </p>
        <Info />
      </Card>
    );
  }
  return (
    <Card
      className="notication-card w-100 text-center"
      data-testid="notication-card"
    >
      <Table responsive className="text-left">
        <thead>
          <tr>
            <th className="col-2">
              Retailer
            </th>
            <th className="col-2">
              Feature
            </th>
            <th className="col-8">
              Details
            </th>
          </tr>
        </thead>
        <tbody className="notification-table-body">
          {
            notificationData.map((notification, index) => (
              <tr key={`home-notification-${notification.id}-${index}`}>
                <td className="col-2">
                  {
                    (notification.details as RetailerOutageDetails)?.retailer_name
                    ?? <p className="text-muted">N/A</p>
                  }
                </td>
                <td className="col-2">
                  {
                    (notification.details as FeatureOutageDetails)?.feature_name
                    ?? <p className="text-muted">N/A</p>
                  }
                </td>
                <td className="col-8">
                  {
                    notification.message
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Card>
  );
}
