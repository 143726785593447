import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';

export type NotFoundMessageTypes = 'notFound' | 'oldUrl';

export type NotFoundProps = {
  messageType?: NotFoundMessageTypes,
};

function NotFound({
  messageType,
}: NotFoundProps) {
  let calculatedMessageType: NotFoundMessageTypes = messageType ?? 'notFound';
  const location = useLocation();

  // override if oldUrl
  const lowerCasePath = location.pathname.toLocaleLowerCase();
  if (lowerCasePath.startsWith('/insights') || lowerCasePath.startsWith('/share_of_voice')) {
    calculatedMessageType = 'oldUrl';
  }

  const contentMap: Record<NotFoundMessageTypes, JSX.Element> = {
    notFound: (
      <p>
        This URL is invalid.
      </p>
    ),
    oldUrl: (
      <p>
        It looks like you are using an old link or bookmark.
        <br />
        Please delete any bookmarks that were created for
        {' '}
        <span className="text-blushing-salmon">www.marshallinsights.com</span>
        {' '}
        as they are no longer valid.
      </p>
    ),
  };

  document.title = 'Marshall Insights | 404: Not Found';
  return (
    <div className="page page-not-found">
      <PageHeader title="404: Not Found" returnText="Back to Home" returnLink="/" />
      <div className="error-message-container">
        {contentMap[calculatedMessageType]}
        <p>
          Click
          {' '}
          <Link className="ma-link" to="/">here</Link>
          {' '}
          to go to the homepage.
        </p>
      </div>
    </div>
  );
}

NotFound.defaultProps = {
  messageType: 'notFound',
};

export default NotFound;
