import React, { useState } from 'react';
import GlobalConfig from '../../app/config';
import SnackBar from '../snackBar/SnackBar';

function DevWarningSnackBar() {
  if (GlobalConfig.config.ENV !== 'development') {
    return null;
  }
  const [showDevWarning, setShowDevWarning] = useState<boolean>(true);
  return (
    <SnackBar
      style={{
        position: 'absolute',
        top: '2.8125rem',
        width: '70vw',
        zIndex: 1000,
        left: '15vw',
        textAlign: 'center',
      }}
      header="Warning"
      alertText="This is an internal DEVELOPMENT/TESTING site only - any changes to data or settings here will not be made live and are for testing purposes only."
      show={showDevWarning}
      data-testid="dev-snackbar-warning"
      onClose={() => setShowDevWarning(false)}
    />
  );
}

export default DevWarningSnackBar;
