import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';

function NotAuthorized() {
  return (
    <div className="page page-not-authorized">
      <PageHeader title="401: Unauthorized" returnText="back home" returnLink="/" />
      <div className="error-message-container">
        <p>You are not authorized to view this page.</p>
        <p>Please contact your organization administrators if this is a mistake.</p>
      </div>
    </div>
  );
}

export default NotAuthorized;
