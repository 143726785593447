import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import './ItemCountCard.css';

const propTypes = {
  datatestid: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

type ItemCountCardProps = PropTypes.InferProps<typeof propTypes>;

/* eslint-disable react/no-array-index-key */
export default function ItemCountCard(props: ItemCountCardProps) {
  const {
    datatestid,
    title,
    subTitle,
    value,
    className,
    onClick,
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Card
      className={`card-item-count text-center ${className} ${onClick ? 'clickable' : ''}`}
      data-testid={datatestid}
      onClick={handleClick}
    >
      <Card.Body>
        <div className="card-item-count-value">
          {value}
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="card-item-count-title text-blushing-salmon font-weight-bold">
          {title}
        </div>
        <div className="card-item-count-subtitle text-a8-grey">
          {subTitle}
        </div>
      </Card.Footer>
    </Card>
  );
}

ItemCountCard.propTypes = propTypes;

ItemCountCard.defaultProps = {
  datatestid: '',
  subTitle: '',
  value: '',
  className: '',
  title: '',
  onClick: null,
};
