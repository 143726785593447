import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { HelpLinkButton } from '../modal/helpModal/helpModal';

const DCAInstructionsVCPOS: JSX.Element = (
  <Container
    className="border border-info rounded mb-3"
    data-testid="dca-instructions-amazon-vcpos"
  >
    <Row>
      <h3
        className="text-capitalize mt-2"
      >
        How to add an Amazon Vendor Central account
      </h3>
    </Row>
    <Row className="ps-3 pad-child-list-elements">
      <ol>
        <li>
          <b>
            Sign into Amazon Vendor Central (VC):
          </b>
          <ul>
            <li>
              Use a separate browser tab to sign in.
            </li>
            <li>
              Ensure the VC account to be added to Insights is the active selected account in VC.
            </li>
            <li>
              Ensure you have Admin or Manage Integrations (EDI/API) permissions in your Vendor
              Central account to authorize data collection.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Fill in the Form:
          </b>
          <ul>
            <li>
              Enter the Amazon VC account name as shown in the top navigation bar on VC.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Authorize the Account:
          </b>
          <ul>
            <li>
              Click the&nbsp;
              <span className="badge bg-black">
                Add
              </span>
              &nbsp;button below.
            </li>
            <li>
              You will be redirected to VC for authorization.
            </li>
            <li>
              Verify the correct account is displayed in the top navigation bar in VC.
            </li>
            <li>
              If not, close the tab, reopen Insights, and start again.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Grant Access:
          </b>
          <ul>
            <li>
              Select the checkbox to provide Marshall Insights access.
            </li>
            <li>
              Click &quot;Confirm&quot;.
            </li>
            <li>
              If you are unable to grant access, ensure you have the necessary permissions
              in VC to authorize data collection.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Complete the Process:
          </b>
          <ul>
            <li>
              You will be redirected back to Marshall Insights.
            </li>
            <li>
              A success message will indicate the account has been added.
            </li>
          </ul>
        </li>
      </ol>
    </Row>
    <Row>
      <b>
        Troubleshooting:
      </b>
      <p>
        If you encounter any issues or have questions, contact
        <HelpLinkButton />
        .
      </p>
    </Row>
  </Container>
);

export default DCAInstructionsVCPOS;
