const generateNSizedArray = (n: number) => Array(n).fill(null);

const removeDuplicateStrings = (sourceArray: string[]) => sourceArray.filter(
  (value, index, array) => array.indexOf(value) === index,
);

export {
  removeDuplicateStrings,
  generateNSizedArray,
};
