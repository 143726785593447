/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { persistor, store } from './app/store';
import GlobalConfig, { DynamicConfig, fetchConfig } from './app/config';
import MasterRoutes from './pages';
import './index.scss';
import './fonts/Pe-icon-7-stroke.scss';
import { initializeMaintenanceController } from './controllers/maintenance-service';
import { initializeProductController } from './controllers/product-service';
import { initializeSovController } from './controllers/sov-service';
import { initializeSubscriptionController } from './controllers/subscription-service';
import { initializeUserController } from './controllers/user-service';

fetchConfig()
  .then(() => {
    // initialize all modules dependent on config here
    initializeUserController(GlobalConfig.config.USER_SVC_URL);
    initializeMaintenanceController(GlobalConfig.config.MAINTENANCE_SVC_URL);
    initializeProductController(GlobalConfig.config.PRODUCT_SVC_URL);
    initializeSovController(GlobalConfig.config.SOV_SVC_URL);
    initializeSubscriptionController(GlobalConfig.config.SUBSCRIPTION_SVC_URL);
  })
  .then(() => {
    if (['production', 'development'].includes(GlobalConfig.config.ENV)) {
      const tracedUrlKeys: (keyof DynamicConfig)[] = [
        'MAINTENANCE_SVC_URL',
        'PRODUCT_SVC_URL',
        'SOV_SVC_URL',
        'SUBSCRIPTION_SVC_URL',
        'USER_SVC_URL',
      ];
      const excludedUrlKeys:(keyof DynamicConfig)[] = [
        'AMAZON_SELLER_CENTRAL_AUTH_URL',
        'AMAZON_VENDOR_CENTRAL_AUTH_URL',
      ];

      const allowedTracingUrls = tracedUrlKeys.map((key) => GlobalConfig.config[key])
        .filter((value) => value !== undefined);

      const excludedActivityUrls = excludedUrlKeys.map((key) => GlobalConfig.config[key])
        .filter((value) => value !== undefined);

      datadogRum.init({
        actionNameAttribute: 'data-custom-name',
        allowedTracingUrls,
        applicationId: GlobalConfig.config.DD_APP_ID,
        clientToken: GlobalConfig.config.REACT_APP_RUM_CLIENT_TOKEN,
        defaultPrivacyLevel: 'mask-user-input',
        env: GlobalConfig.config.ENV,
        excludedActivityUrls,
        service: 'insights-saas-react-app',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        site: 'datadoghq.com',
        startSessionReplayRecordingManually: true,
        telemetrySampleRate: 0,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        version: GlobalConfig.config.REACT_APP_VERSION,
      });
    }
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <MasterRoutes />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });
