import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { hasGrants } from '../../controllers/auth';
import { deleteProductNote } from '../../controllers/product-service';
import { ProductNote } from '../../controllers/product-service/types';
import { Trash } from '../../icons';
import { selectCurrentOrgId, selectProfileData } from '../../reducers/profile/profileSlice';
import NotesDeleteConfirmation from './notesDeleteConfirmation';

type NoteProps = {
  note: ProductNote
  retailerName: string,
  afterDelete: () => void,
};

export default function Note({ note, retailerName, afterDelete }: NoteProps) {
  const currentOrgId = useSelector(selectCurrentOrgId);
  const userId = useSelector(selectProfileData).user_id;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const notesItemRef = useRef<HTMLDivElement | null>(null);
  const onDeleteDeleteButtonClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    notesItemRef.current?.style.setProperty('opacity', '0.2', 'important');
    setShowDeleteConfirmation(true);
  };

  const failConfirmationCallback = () => {
    notesItemRef.current?.style.setProperty('opacity', '1', 'important');
  };

  const confirmDeleteHandler = () => {
    deleteProductNote(currentOrgId, note.id)
      .finally(() => {
        afterDelete();
      });
  };

  const isAllowedToDelete = (hasGrants(currentOrgId, ['ORG_ADMIN', 'ORG_OWNER'], ['ADMIN', 'APPLICATION_RESOURCE_MANAGER'])
    || note.created_by_user_id === userId);

  return (
    <>
      <NotesDeleteConfirmation
        show={showDeleteConfirmation}
        noteText={note.note}
        setShow={setShowDeleteConfirmation}
        confirmDeleteCallBack={confirmDeleteHandler}
        failConfirmationCallback={failConfirmationCallback}
      />
      <div
        ref={notesItemRef}
        className="notes-product-item ps-2 mt-2 border border-start-0 border-end-0 border-top-0 mb-2"
      >
        <div className="d-flex small col-12">
          <div className="col-6 text-start">
            <p className="mb-0 text-coral-red text-truncate">
              {note.created_by_name}
            </p>
            <p className="mb-0 text-muted">
              {note.created_by_email}
            </p>
          </div>
          <div className="float-right pe-1 container">
            <div className="mb-0 text-end">
              {
                new Date(note.created_at).toLocaleDateString(
                  undefined,
                  {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  },
                )
              }
              <br />
              {retailerName}
            </div>
          </div>
          <div>
            <Button
              className="delete-note-button ma-btn-link"
              data-testid="delete-note-button"
              onClick={(event) => onDeleteDeleteButtonClick(event)}
              disabled={!isAllowedToDelete}
            >
              <Trash className="ma-btn ma-btn-link me-2" fill={isAllowedToDelete ? 'var(--coral-red)' : 'var(--gizmo-grey)'} />
            </Button>
          </div>
        </div>
        <p className="notes-text-content w-100 text-break pe-3">
          {note.note}
        </p>
      </div>
    </>
  );
}
