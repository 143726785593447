import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { hasViewPermission } from '../../controllers/user-service';
import {
  selectOrganizationsStatus,
  selectCurrentOrganization,
} from '../../reducers/organizations/organizationsSlice';
import LoadingPage from '../LoadingPage/LoadingPage';
import NotFound from '../NotFound/NotFound';
import QuickSightsDashboard from '../QuickSightsDashBoard/QuickSightsDashboard';
import { OrganizationType, ViewDataType } from '../../controllers/user-service/types';

const isQuickSightsView = (view: ViewDataType) => (!!(view?.quicksight_dashboard_id) && view.quicksight_dashboard_id !== '');

const getViewFromOrg = (org: OrganizationType | undefined) => {
  const views: ViewDataType[] = [];
  if (org?.modules) {
    Object.keys(org.modules).forEach((moduleKey) => {
      org.modules[moduleKey].view_data.forEach((view) => {
        views.push(view);
      });
    });
  }
  return views;
};

export default function ModuleRouting() {
  const orgStatus = useSelector(selectOrganizationsStatus);
  const orgData = useSelector(selectCurrentOrganization);
  const views = getViewFromOrg(orgData);
  const location = useLocation();
  const [currentView, setCurrentView] = useState<ViewDataType | undefined | null>(null);

  useEffect(() => {
    const [newView] = views.filter((view) => `/module/${view.path}` === location.pathname);
    setCurrentView(newView);
  });

  if (currentView === null) {
    return (
      <LoadingPage />
    );
  }

  if (currentView !== undefined) {
    if (isQuickSightsView(currentView)
      && hasViewPermission(String(orgData?.id ?? -1), (currentView?.valid_permissions ?? []))
    ) {
      document.title = `Marshall Insights | ${currentView.friendly_name}`;
      return (
        <QuickSightsDashboard
          dashboardPath={currentView.path}
          key={currentView.quicksight_dashboard_id}
        />
      );
    }
    /* This block is where other module resources will be rendered */
    return (
      <NotFound />
    );
  }

  switch (orgStatus) {
    case 'uninitialized':
    case 'loading':
      return (
        <LoadingPage />
      );
    case 'failed':
      return (
        <div className="message-container text-right">
          <p>Something went wrong please refresh</p>
          {window.location.pathname}
        </div>
      );
    default:
      return (
        <NotFound />
      );
  }
}
