// eslint-disable @typescript-eslint/no-explicit-any
import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import Home from './Home/Home';
import Login from './Login/Login';
import Logout from './Logout/LogOut';
import ProtectedRoute from '../controllers/auth/protectedRoute';
import NotFound from './NotFound/NotFound';
import Settings from './Settings/Settings';
import UsersNew from './UsersNew/UsersNew';
import UsersEdit from './UsersEdit/UsersEdit';
import UserAdministration from './UserAdministration/UserAdministration';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotAuthorized from './NotAuthorized/NotAuthorized';
import ItemAdmin from './ItemAdmin/ItemAdmin';
import RetailerUrlFormatting from './RetailerUrlFormatting/RetailerUrlFormatting';
import NavBar from '../components/navbar/Navbar';
import Reset from './Login/Reset';
import Forgot from './Login/Forgot';
import LeftNavbar from '../components/navbar/LeftNavbar';
import SkuDetails from './SkuDetails/SKUDetails';
import ModuleRouting from './ModuleRouting/ModuleRouting';
import ErrorPage from './ErrorPage/ErrorPage';
import DevWarningSnackBar from '../components/devWarningSnackBar/devWarningSnackBar';
import NavigationMessage from '../components/NavigationMessage/NavigationMessage';
import SOVAdmin from './SOVAdmin/SOVAdmin';
import PublicHome from './PublicHome/PublicHome';
import PublicCookiePolicy from './PublicCookiePolicy/PublicCookiePolicy';
import PublicFeatures from './PublicFeatures/PublicFeatures';
import PublicHelp from './PublicHelp/PublicHelp';
import PublicPrivacyPolicy from './PublicPrivacyPolicy/PublicPrivacyPolicy';
import PublicTermsOfService from './PublicTermsOfService/PublicTermsOfService';
import PublicTraining from './PublicTraining/PublicTraining';
import OrganizationAdmin from './OrganizationAdmin/OrganizationAdmin';
import useDataDog from '../hooks/useDataDog';
import useOrgKeepAlive from '../hooks/useOrgKeepAlive';
import useProfileUpdate from '../hooks/useProfileUpdate';
import PageReloadToast from '../components/PageReloadToast/PageReloadToast';
import PublicServiceLevelAgreement from './PublicServiceLevelAgreement/PublicServiceLevelAgreement';

/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
export class PageErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    console.error(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorPage />;
    }
    return children;
  }
}

/* istanbul ignore next */
// eslint-disable-next-line react/display-name
export default function MasterRoutes() {
  useDataDog();
  useOrgKeepAlive();
  useProfileUpdate();
  return (
    <>
      <LeftNavbar />
      <NavBar />
      <div className="under-nav" />
      <PageErrorBoundary>
        <NavigationMessage />
        <Routes>
          <Route
            path="/"
            element={(
              <ProtectedRoute title="Home">
                <Home />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/home"
            element={(
              <PublicHome />
            )}
          />
          <Route
            path="/cookie-policy"
            element={(
              <PublicCookiePolicy />
            )}
          />
          <Route
            path="/help"
            element={(
              <PublicHelp />
            )}
          />
          <Route
            path="/features"
            element={(
              <PublicFeatures />
            )}
          />
          <Route
            path="/privacy-policy"
            element={(
              <PublicPrivacyPolicy />
            )}
          />

          <Route
            path="/service-level-agreement"
            element={(
              <PublicServiceLevelAgreement />
            )}
          />
          <Route
            path="/terms-of-service"
            element={(
              <PublicTermsOfService />
            )}
          />
          <Route
            path="/training"
            element={(
              <PublicTraining />
            )}
          />
          <Route
            path="/login"
            element={(
              <>
                <DevWarningSnackBar />
                <Login />
              </>
            )}
          />
          <Route
            path="/forgot"
            element={(
              <Forgot />
            )}
          />
          <Route
            path="/reset"
            element={(
              <Reset />
            )}
          />
          <Route
            path="/logout"
            element={(
              <Logout />
            )}
          />
          <Route
            path="/error"
            element={(
              <ErrorPage />
            )}
          />
          <Route
            path="/settings"
            element={(
              <ProtectedRoute title="Settings">
                <DevWarningSnackBar />
                <Settings />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/NotFound"
            element={(
              <NotFound />
            )}
          />
          <Route
            path="/NotAuthorized"
            element={(
              <ProtectedRoute title="Not Authorized">
                <NotAuthorized />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/users"
            element={(
              <ProtectedRoute title="User Administration">
                <DevWarningSnackBar />
                <UserAdministration />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/users/new"
            element={(
              <ProtectedRoute
                requestedGrants={['ORG_ADMIN', 'USER_ADMIN']}
                title="Add New User"
              >
                <DevWarningSnackBar />
                <UsersNew />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/users/edit/:userId"
            element={(
              <ProtectedRoute
                requestedGrants={['ORG_ADMIN', 'USER_ADMIN']}
                title="Edit User"
              >
                <DevWarningSnackBar />
                <UsersEdit />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/itemAdmin"
            element={(
              <ProtectedRoute title="Item Administration">
                <DevWarningSnackBar />
                <ItemAdmin />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/retailerUrlFormatting"
            element={(
              <ProtectedRoute title="Retailer URL Formatting">
                <RetailerUrlFormatting />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/skuDetails/:uuid"
            element={(
              <ProtectedRoute title="SKU Details">
                <SkuDetails />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/sovAdmin"
            element={(
              <ProtectedRoute
                requestedGrants={['ADMIN', 'ORG_ADMIN', 'SOV_ADMIN', 'SOV_VIEWER', 'VIEWER']}
                title="SOV Administration"
              >
                <DevWarningSnackBar />
                <SOVAdmin />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/organizationAdmin"
            element={(
              <ProtectedRoute
                requestedGrants={[]}
                adminGrants={['ADMIN', 'APPLICATION_RESOURCE_MANAGER']}
                title="Organization Administration"
              >
                <DevWarningSnackBar />
                <OrganizationAdmin />
              </ProtectedRoute>
            )}
          />
          <Route
            path="*"
            element={(
              <ProtectedRoute>
                <ModuleRouting />
              </ProtectedRoute>
            )}
          />
        </Routes>
        <PageReloadToast />
      </PageErrorBoundary>
    </>
  );
}
