import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, Button, Form } from 'react-bootstrap';
import { Column } from 'react-table';

import PageHeader from '../../components/PageHeader/PageHeader';
import SearchInput from '../../components/SearchInput/SearchInput';
import './SOVAdmin.css';
import { AsyncState } from '../../utils/webRequests.type';
import LoadingPage from '../LoadingPage/LoadingPage';
import Table, { AccessorTip } from '../../components/Table/Table';
import ErrorPage from '../ErrorPage/ErrorPage';
import {
  CheckFilters,
  displayFilterSelectors,
  valueArrayToCheckFilterMap,
} from '../../components/Table/TableFilters';
import { UploadTermsModal } from '../../components/modal';
import { Download } from '../../icons';
import { selectCurrentOrganization } from '../../reducers/organizations/organizationsSlice';
import { getSearchTermsFile, getSearches } from '../../controllers/sov-service';
import { SearchTermFull, SearchTermStatus } from '../../controllers/sov-service/types';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import DownloadFileStatus from '../../components/downloadFileStatus/downloadFileStatus';
import { fetchOrganizationSubscriptions } from '../../controllers/subscription-service';
import sendRumError from '../../utils/datadogRum';

const statusText = (status: SearchTermStatus | null) => {
  switch (status) {
    case 'active':
      return 'Tracked';
    case 'requires attention':
      return status;
    default:
      return 'disabled';
  }
};

// returns status display element (colors)
const statusTd = (props: { status: SearchTermStatus | null }) => {
  const { status } = props;
  const isDisplayedRed = status === 'requires attention';

  return (
    <span
      className={`capitalize ${isDisplayedRed ? 'text-coral-red' : ''}`}
    >
      {statusText(status)}
    </span>
  );
};

const columns: Column<SearchTermFull>[] = [
  {
    Header: 'Search Group',
    accessor: ({ group }) => AccessorTip({
      className: 'capitalize',
      value: group,
    }),
  },
  {
    Header: 'Search Term',
    accessor: ({ term }) => AccessorTip({
      className: 'capitalize',
      value: term,
    }),
  },
  {
    Header: 'Retailer',
    accessor: ({ retailer_name }) => AccessorTip({ value: retailer_name }),
  },
  {
    Header: 'Status',
    accessor: statusTd,
  },
];

export default function SOVAdmin() {
  const [searchText, setSearchText] = useState<string>('');
  const [data, setData] = useState<SearchTermFull[]>([]);
  const [fetchStatus, setFetchStatus] = useState<AsyncState>('uninitialized');
  const [pageError, setPageError] = useState<boolean>(false);

  const [retailersFilter, setRetailersFilter] = useState<CheckFilters>({});
  const [retailerSelectAll, setRetailerSelectAll] = useState<boolean | null>(true);
  const [retailerToUUIDList, setRetailerToUUIDList] = useState<Record<string, string>[]>([]);

  const [searchGroupFilter, setSearchGroupFilter] = useState<CheckFilters>({});
  const [searchGroupSelectAll, setSearchGroupSelectAll] = useState<boolean | null>(true);

  const [searchStatusFilter, setSearchStatusFilter] = useState<CheckFilters>({});
  const [statusSelectAll, setStatusSelectAll] = useState<boolean | null>(true);

  const [downloadSearchTermsFileStatus, setDownloadSearchTermsFileStatus] = useState<AsyncState>('uninitialized');

  const orgName = useSelector(selectCurrentOrganization)?.name.toLowerCase().replace(/[^a-z0-9]/gmi, ' ').replace(/\s+/g, '-');
  const currentOrgId = useSelector(selectCurrentOrgId);

  // load initial data
  useEffect(() => {
    // or don't if we're not ready
    if (!currentOrgId || fetchStatus !== 'uninitialized') {
      return;
    }

    setFetchStatus('loading');
    const retailerPromise = fetchOrganizationSubscriptions(
      currentOrgId,
      ['retailer_search_result'],
    ).then(
      (response) => {
        const { data: subscriptionData } = response;
        const retailers = valueArrayToCheckFilterMap(
          subscriptionData.map(({ retailer_name }) => retailer_name),
        );
        setRetailersFilter(retailers);
        setRetailerToUUIDList(subscriptionData.map(
          ({ retailer_name, retailer_id }) => (
            {
              id: retailer_id,
              name: retailer_name,
            }
          ),
        ));
      },
    );
    const searchesPromise = getSearches(currentOrgId)
      .then(async (response) => {
        const searchTermData = response.data as SearchTermFull[];

        const searchGroupNames = valueArrayToCheckFilterMap(
          searchTermData.map(({ group }) => group),
        );
        setSearchGroupFilter(searchGroupNames);

        const searchStatusMap = valueArrayToCheckFilterMap([
          'Tracked', // 'active'
          'disabled',
          'requires attention',
        ]);
        setSearchStatusFilter(searchStatusMap);

        setData(searchTermData);
      });

    Promise.all([retailerPromise, searchesPromise])
      .then(() => {
        setFetchStatus('completed');
      })
      .catch((error) => {
        sendRumError(error);
        console.error(error); // eslint-disable-line no-console
        setPageError(true);
      });
  }, [currentOrgId]);

  const displayedSearches = useMemo(
    () => (
      data.filter((term) => (
        (searchText === '' || term.group.toLowerCase().includes(searchText) || term.term.toLowerCase().includes(searchText))
        && retailersFilter[term.retailer_name]
        && (searchStatusFilter[statusText(term.status)])
        && searchGroupFilter[term.group]
      ))
        .sort((a, b) => a.term.localeCompare(b.term))
        .sort((a, b) => a.group.localeCompare(b.group))
    ),
    [data, searchText, retailersFilter, searchStatusFilter, searchGroupFilter],
  );

  if (pageError) {
    return <ErrorPage />;
  }

  if (['loading', 'uninitialized'].includes(fetchStatus)) {
    return <LoadingPage />;
  }

  const downloadSearchTermsFile = () => {
    setDownloadSearchTermsFileStatus('loading');

    // retailersFilter is a list of retailer_names.  I need to send back UUIDs
    const retailerList = Object.keys(retailersFilter).filter(
      (k) => retailersFilter[k],
    ).map(
      (retailerName) => retailerToUUIDList.find(({ name }) => retailerName === name)?.id,
    ).filter((id) => id !== undefined) as string[];

    getSearchTermsFile(
      currentOrgId as number,
      {
        empty: displayedSearches.length === 0,
        query: searchText,
        group: Object.keys(searchGroupFilter).filter((k) => searchGroupFilter[k]),
        retailer: retailerList,
      },
    )
      .then((searchTermsFileResponse) => {
        const dateNow = new Date();
        const timeStamp = `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}-${dateNow.getHours()}-${dateNow.getMinutes()}-${dateNow.getSeconds()}`;
        const filename = `search-terms-file-${orgName}-${timeStamp}.xlsx`;

        const searchTermsFile = new File(
          [searchTermsFileResponse.data],
          filename,
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        );
        const downloadUrl = window.URL.createObjectURL(searchTermsFile);
        const link = document.createElement('a');
        link.download = filename;
        link.href = downloadUrl;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        setDownloadSearchTermsFileStatus('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setDownloadSearchTermsFileStatus('failed');
      });
  };

  return (
    <div
      className="page sov-administration-page h-100"
      data-testid="sov-administration-page"
      id="sov-administration-page"
    >
      <PageHeader
        title="Manage Search Terms"
        returnText="Back to Home"
        returnLink="/"
      >
        <UploadTermsModal />
        <Button
          className="add-edit-button btn"
          onClick={downloadSearchTermsFile}
        >
          Add/Edit Search Terms
          <Download />
        </Button>
        <DownloadFileStatus status={downloadSearchTermsFileStatus} />
      </PageHeader>
      <div className="row gx-5" id="sov-page-body">
        <div className="col-3 h-100 filter-pane">
          <Form className="filter-form">
            <strong>Search Terms or Groups</strong>
            <SearchInput
              data-testid="term-search-input"
              placeholder="Search Terms or Groups"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText(e.currentTarget.value.toLowerCase());
              }}
            />
            <Accordion alwaysOpen defaultActiveKey={['Retailer-filter-dropdown']}>
              {displayFilterSelectors('Retailer', () => retailersFilter, setRetailersFilter, retailerSelectAll, setRetailerSelectAll, null)}
              {displayFilterSelectors('Search Group', () => searchGroupFilter, setSearchGroupFilter, searchGroupSelectAll, setSearchGroupSelectAll, null)}
              {displayFilterSelectors('Status', () => searchStatusFilter, setSearchStatusFilter, statusSelectAll, setStatusSelectAll, null)}
            </Accordion>
          </Form>
        </div>
        <div className="col-9 h-100">
          {
            displayedSearches.length > 0
              ? (
                <Table
                  columns={columns}
                  data={displayedSearches}
                  pagination={{
                    pageSize: 9,
                  }}
                />
              )
              : (<p>There are no searches matching your filters.</p>)
          }
        </div>
      </div>
    </div>
  );
}
