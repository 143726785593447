import React, {
  useState, ReactNode, useRef, useEffect,
} from 'react';
import {
  Container, Button, Modal, Form, Alert, Spinner,
} from 'react-bootstrap';
import { AsyncState } from '../../../utils/webRequests.type';
import { HelpLinkButton } from '../helpModal/helpModal';
import './deleteModal.css';

type DeleteModalProps = {
  title: ReactNode,
  buttonChildren?: ReactNode,
  children: ReactNode,
  modalSize?: 'sm' | 'lg' | 'xl',
  onDelete: (() => void) | (() => Promise<boolean>) | (() => boolean),
  onHide?: () => void,
  buttonClassName?: string,
  modalClassName?: string,
  deleteElement: ReactNode,
  challengeText?: string | undefined,
  disabled: boolean,
};

/**
 *Takes the following inputs:
  * @param {ReactNode|string} title: string or react node
  * @param {'sm' | 'lg' | 'xl'} modalSize: bootstrap standard sizes for a modal
  * @param {func} onDelete: function to execute if user confirms delete returns true if successful
  * @param {func} onHide: function to execute when hiding modal
  * @param {func} buttonClassName: css class to apply to external button
  * @param {string} modalClassName: css class to apply to modal
  * @param {ReactNode|string} deleteElement: content for what text delete button label
  * @param {string} challengeText: string the user needs to type to confirm deletion
  * @param {boolean} disabled: disables the trigger button for showing modal
*/
function DeleteModal(props: DeleteModalProps): JSX.Element {
  const {
    title,
    buttonChildren,
    children,
    onDelete,
    onHide,
    modalSize,
    buttonClassName,
    modalClassName,
    deleteElement,
    challengeText,
    disabled,
  } = props;
  const [show, setShow] = useState<boolean>(false);
  const [deleteButtonState, setDeleteButtonState] = useState<boolean>(!!challengeText);
  const [deleteState, setDeleteState] = useState<AsyncState>('uninitialized');
  const formRef = useRef<HTMLFormElement>(null);

  const isSubmiting = deleteState === 'loading';

  /* ****** */
  /* basics */
  /* ****** */
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    if (!isSubmiting) {
      setShow(false);
      (onHide as () => void)();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteButtonState(e.target.value !== challengeText);
  };

  const handleDelete = async () => {
    setDeleteState('loading');
    const isDeleteSuccess = await onDelete();
    if (isDeleteSuccess !== undefined && !isDeleteSuccess) {
      setDeleteState('failed');
      return;
    }
    setDeleteState('completed');
    handleClose();
  };

  /* *************************** */
  /* and the returned template.. */
  /* *************************** */

  useEffect(() => {
    if (formRef.current) {
      setDeleteState('uninitialized');
      formRef.current.reset();
      setDeleteButtonState(true);
    }
  }, [show]);

  return (
    <>
      <Button
        onClick={handleShow}
        role="button"
        type="button"
        tabIndex={0}
        className={buttonClassName}
        variant="danger"
        data-testid="delete-modal-trigger"
        disabled={disabled}
      >
        {buttonChildren || 'Delete'}
      </Button>

      <Modal data-testid="delete-confirm-modal" size={modalSize} className={`delete-modal mt-5 ${modalClassName}`} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger" show={deleteState === 'failed'}>
            Deletion Error: Please try again or contact
            <HelpLinkButton />
          </Alert>
          <Container className="delete-modal-content" data-testid="delete-modal">
            {children}
            <Form ref={formRef} onSubmit={(e) => { e.stopPropagation(); e.preventDefault(); }}>
              {challengeText
                ? (
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="delete-modal-input"
                      placeholder={`Type ${challengeText} here`}
                      onChange={handleChange}
                      data-testid="delete-modal-input"
                    />
                  </Form.Group>
                )
                : null}
              <Container className="delete-buttons d-flex justify-content-evenly">
                <Button
                  variant="danger"
                  className="col-4"
                  onClick={handleDelete}
                  disabled={deleteButtonState || isSubmiting}
                  data-testid="confirm-delete-button"
                >
                  {isSubmiting ? <Spinner size="sm" animation="grow" /> : deleteElement}
                </Button>
                <Button
                  className="col-4"
                  variant="primary"
                  onClick={handleClose}
                  disabled={isSubmiting}
                >
                  Close
                </Button>
              </Container>
            </Form>

          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

DeleteModal.defaultProps = {
  onHide: () => { },
  buttonChildren: undefined,
  modalSize: 'lg',
  challengeText: undefined,
  modalClassName: '',
  buttonClassName: '',
};
export default DeleteModal;
