import React, { CSSProperties } from 'react';
import Alert from 'react-bootstrap/Alert';

export type AlertTypes = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';

// a type for the calling utility to use
export type SnackBarState = {
  alertText: string,
  alertType? :AlertTypes,
  header: string,
  show: boolean,
};

// props for the below
export type SnackBarProps = {
  show: boolean,
  alertType?: AlertTypes,
  onClose?(): void,
  header: string,
  alertText: React.ReactNode,
  style: CSSProperties,
};

function SnackBar({
  show,
  alertType = 'danger',
  onClose,
  header,
  alertText,
  style,
}: SnackBarProps) {
  return (
    <Alert
      style={style}
      variant={alertType}
      onClose={onClose}
      dismissible
      data-testid="SnackBar"
      show={show}
      className={show ? '' : 'd-none'}
    >
      <Alert.Heading>{header}</Alert.Heading>
      <div>
        {alertText}
      </div>
    </Alert>
  );
}

SnackBar.defaultProps = {
  alertType: 'danger',
  onClose: () => { },
};

export default SnackBar;
