import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './PageHeader.css';

const propTypes = {
  title: PropTypes.string.isRequired,
  returnText: PropTypes.string,
  returnLink: PropTypes.string,
  children: PropTypes.node, // can't be "React.Component"
  titleWidth: PropTypes.string,
};

type PageHeaderProps = PropTypes.InferProps<typeof propTypes>;

function PageHeader(props: PageHeaderProps) {
  const {
    title,
    returnText,
    returnLink,
    children,
    titleWidth,
  } = props;
  return (
    <div className="row position-relative">
      <div className={`page-title-header ${titleWidth}`}>
        <h3>{title}</h3>
        {
          (returnText !== '' && returnLink !== '')
          && <Link className="return-link ma-link" to={returnLink as string}>{`< ${returnText}`}</Link>
        }
      </div>
      <div className="title-button-container pull-right col-6">
        {children}
      </div>
    </div>
  );
}

PageHeader.propTypes = propTypes;

PageHeader.defaultProps = {
  returnText: '',
  returnLink: '',
  children: null,
  titleWidth: 'col-6',
};

export default PageHeader;
