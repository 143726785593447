import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Spinner } from 'react-bootstrap';

import { getProductGroupBy } from '../../../controllers/product-service';
import { AsyncState } from '../../../utils/webRequests.type';
import './homeTrackedByCategory.css';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import CardTable from '../CardTable/CardTable';
import ItemCountCard from '../ItemCountCard/ItemCountCard';
import sendRumError from '../../../utils/datadogRum';

/* eslint-disable react/no-array-index-key */
export default function HomeTrackedByCategory() {
  const [dataLoadState, setDataLoadState] = useState<AsyncState>('uninitialized');
  const [categoryList, setCategoryList] = useState<string[][]>([]);
  const currentOrgId = useSelector(selectCurrentOrgId);

  useEffect(() => {
    setDataLoadState('loading');

    getProductGroupBy(
      Number(currentOrgId),
      'category',
    )
      .then((response) => {
        const categorySorted = response.data.sort((a, b) => (b.count - a.count));
        const categoryData = categorySorted.map(
          (row) => [
            row.selector,
            row.count.toLocaleString(),
          ],
        );
        setCategoryList(categoryData);

        setDataLoadState('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setCategoryList([]);
        setDataLoadState('failed');
      });
  }, [currentOrgId]);

  return (
    <>
      {
        dataLoadState === 'loading' && (
          <Card>
            <Spinner animation="border" />
          </Card>
        )
      }
      {
        dataLoadState === 'failed' && (
          <Card
            data-testid="HomeTrackedByCategory-DataLoadState-Failed"
          >
            <p>
              Something went wrong
              <br />
              while loading this resource.
            </p>
          </Card>
        )
      }
      {
        dataLoadState === 'completed' && categoryList.length > 1 && (
          <CardTable
            datatestid="HomeTrackedByCategory-DataLoadState-Completed"
            title="Tracked by Category"
            columns={[
              'Category',
              'Item Count',
            ]}
            tableData={categoryList}
            className=""
          />
        )
      }
      {
        dataLoadState === 'completed' && categoryList.length === 1 && (
          <ItemCountCard
            datatestid="HomeTrackedByCategory-DataLoadState-Completed"
            value={categoryList[0][1]}
            title={`${categoryList[0][0]} items`}
            subTitle="100% of your portfolio"
          />
        )
      }
    </>
  );
}
