import React, { useState, useEffect, useRef } from 'react';
import {
  Alert,
  Button, Form, FormGroup, Modal, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { postSearchTermsFile } from '../../../controllers/sov-service';
import { SearchTermsFileResults } from '../../../controllers/sov-service/types';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import './UploadTermsModal.css';
import UploadTermsModalSummary from './UploadTermsModalSummary';
import { AsyncState } from '../../../utils/webRequests.type';
import { hasGrants } from '../../../controllers/auth';
import sendRumError from '../../../utils/datadogRum';
import { HelpLinkButton } from '../helpModal/helpModal';

function UploadTermsModal(): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const [searchTermsFileResults, setSearchTermsFileResults] = (
    useState<SearchTermsFileResults | undefined>(undefined)
  );
  const [searchTermsFile, setSearchTermsFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<AsyncState>('uninitialized');
  const [disableUpload, setDisableUpload] = useState<boolean>(false);
  const [displaySummaryModal, setDisplaySummaryModal] = useState<boolean>(false);
  const currentOrgId = useSelector(selectCurrentOrgId) as number;
  const fileInputRefForm = useRef(null);

  const reset = () => {
    setUploadError(false);
    setSearchTermsFile(null);
    (fileInputRefForm.current as unknown as HTMLFormElement).reset();
    setUploadStatus('uninitialized');
  };

  useEffect(() => {
    setUploadStatus('uninitialized');
  }, [searchTermsFile]);

  useEffect(() => {
    if (displaySummaryModal && show) {
      setShow(false);
      setUploadStatus('uninitialized');
    }
  }, [displaySummaryModal]);

  const handleSubmit = () => {
    setUploadStatus('loading');
    const formData = new FormData();
    formData.append('file', searchTermsFile as File);
    postSearchTermsFile(currentOrgId, formData)
      .then((searchTermsFileResponse) => {
        const searchTermsFileData = searchTermsFileResponse.data;
        setSearchTermsFileResults({
          new_searches: searchTermsFileData.new_searches,
          changed_searches: searchTermsFileData.changed_searches,
          unchanged_searches: searchTermsFileData.unchanged_searches,
          incompatible_searches: searchTermsFileData.incompatible_searches,
        });
        setDisplaySummaryModal(true);
      })
      .catch((error) => {
        sendRumError(error);
        setUploadStatus('uninitialized');
        setUploadError(true);
        try {
          if (typeof error.response.data.detail !== 'undefined') {
            const message = `${error.response.data.detail}\nIf you have further questions, please email support at `;
            setUploadMessage(message);
          } else {
            setUploadMessage('Please email support at ');
          }
        } catch {
          setUploadMessage('Please email support at ');
        }
      })
      .finally(() => {
        setSearchTermsFile(null);
      });
  };

  const handleClose = () => {
    setShow(false);
    reset();
  };

  useEffect(() => {
    setDisableUpload(uploadStatus !== 'uninitialized'
      || searchTermsFile == null
      || uploadError);
  }, [uploadStatus, searchTermsFile, uploadError]);

  const isAllowedToUpdate = hasGrants(
    currentOrgId,
    [
      'APPLICATION_RESOURCE_MANAGER',
      'SOV_ADMIN',
    ],
  );

  return (
    <>
      <div
        id="upload-term-modal-container-button"
        onClick={() => isAllowedToUpdate && setShow(true)}
        role="button"
        tabIndex={0}
        className="pull-right"
      >
        <span>
          {isAllowedToUpdate ? (
            <Button>
              Upload Changes
            </Button>
          ) : (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 400 }}
              overlay={(
                <Tooltip
                  id="upload-tooltip"
                  data-testid="upload-tooltip"
                  className="upload-tooltip"
                >
                  You do not have the permissions required to upload changes.
                  Please contact an administrator if you need your permissions updated.
                </Tooltip>
              )}
            >
              <span
                className="disabled-button-wrapper"
              >
                <Button
                  disabled
                  className="not-allowed-to-use"
                >
                  Upload Changes
                </Button>
              </span>
            </OverlayTrigger>
          )}
        </span>
      </div>
      {
        searchTermsFileResults
        && (
          <UploadTermsModalSummary
            data={searchTermsFileResults}
            setDisplayModal={setDisplaySummaryModal}
            displayModal={displaySummaryModal}
          />
        )
      }

      <Modal className="upload-term-modal-container col-5 mt-5" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Search Term Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please select a file containing the search groups or search terms to add or change.
            This file must match the Marshall Insights .xlsx template,
            which can be downloaded by clicking the Add/Edit Search Terms button.
            <br />
            <br />
            Once selected, a summary of pending changes will be given before any action is taken.
          </p>
          <Form
            id="Term-Input-Form"
            ref={fileInputRefForm}
          >
            <FormGroup>
              <Form.Control
                data-testid="file-upload-input"
                id="file-upload-input"
                placeholder="Browse file(s)"
                type="file"
                name="fileInput"
                multiple={false}
                disabled={uploadStatus === 'loading'}
                onChange={(e) => {
                  const files = (e.currentTarget as HTMLInputElement).files as FileList;
                  e.preventDefault();
                  setSearchTermsFile(files[0]);
                  setUploadError(false);
                }}
              />
            </FormGroup>
          </Form>
          <hr />

          {uploadError
            && (
              <Alert variant="danger" className="w-100 mt-3 mb-0">
                <span
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {uploadMessage}
                </span>
                <HelpLinkButton />
                .
              </Alert>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            variant="primary"
            id="term-upload-modal-submit-button"
            data-testid="term-upload-modal-submit-button"
            onClick={handleSubmit}
            className="m-1"
            disabled={disableUpload}
            type="submit"
          >
            {
              uploadStatus === 'uninitialized' && <>Submit</>
            }
            {
              uploadStatus === 'loading' && <Spinner animation="grow" size="sm" />
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadTermsModal;
