import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import axios from 'axios';

import {
  useLocation, useSearchParams,
} from 'react-router-dom';
import GlobalConfig, { DynamicConfig } from '../../app/config';
import sendRumError from '../../utils/datadogRum';

export default function PageReloadToast() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setSearchParams((s) => {
      s.delete('version');
      return s;
    });
  }, [searchParams]);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    axios.get<DynamicConfig>(
      '/config.json',
      {
        headers: {
          'Cache-Control': 'no-store',
        },
        signal: abortController.signal,
      },
    ).then(({ data }) => {
      const { REACT_APP_VERSION } = data;
      if (isMounted) {
        setShow(REACT_APP_VERSION !== GlobalConfig.config.REACT_APP_VERSION);
      }
    })
      .catch((error) => {
        if (error.code !== 'ERR_CANCELED') {
          console.log(error); // eslint-disable-line no-console
          sendRumError(error);
        }
      });
    return (() => {
      isMounted = false;
      abortController.abort();
    });
  }, [location.pathname]);

  const { REACT_APP_VERSION } = GlobalConfig.config;
  const refreshUrl = new URL(
    `${location.pathname}${location.search}`,
    `https://${window.location.host}`,
  );
  refreshUrl.searchParams.set('version', REACT_APP_VERSION);
  return (
    <ToastContainer position="bottom-end" className="m-4">
      <Toast show={show}>
        <Toast.Body>
          A new update is available. Click
          <a className="ma-link" href={refreshUrl.toString()}> here </a>
          to update.
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
