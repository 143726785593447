import {
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import {
  getTokenValues,
} from '../controllers/auth';
import {
  selectProfileData,
  updateProfile,
} from '../reducers/profile/profileSlice';
import { AppDispatch, actions } from '../app/store';

const isTimeDrift = () => {
  const currentTime = Date.now() / 1000;
  const tokenData = getTokenValues();
  if (!tokenData) {
    return false;
  }
  const drift = Math.abs(currentTime - tokenData.updated_at);
  return drift > 180;
};

const useProfileUpdate = () => {
  const dispatch = useDispatch<AppDispatch>();
  const profileData = useSelector(selectProfileData);
  const navigate = useNavigate();
  // event to get profile data from user-service (if logged in)
  const tokenChangeHandler = () => {
    if (isTimeDrift()) {
      navigate('/logout', {
        state: {
          message: {
            message: 'TimeDriftMessage',
            alertType: 'danger',
          },
        },
      });
    }

    datadogRum.startSessionReplayRecording();
    if (profileData !== getTokenValues()) {
      dispatch(updateProfile());
    }
  };

  const userLogOutHandler = () => {
    datadogRum.stopSessionReplayRecording();
    dispatch(actions.resetState);
    navigate('/');
  };

  // don't need fancy logic here.. it is a set and forget.
  useEffect(() => {
    window.addEventListener('user_service_token_change', tokenChangeHandler);
    window.addEventListener('user_logout', userLogOutHandler);
    return () => {
      window.removeEventListener('user_service_token_change', tokenChangeHandler);
      window.removeEventListener('user_logout', tokenChangeHandler);
    };
  }, []);
};

export default useProfileUpdate;
