import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import {
  Form, Container, Row, Button, Modal, Spinner, Alert,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Help1 } from '../../../icons';
import './helpModal.css';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import { postIssue, postAttachment } from '../../../controllers/maintenance-service';
import { IssueDetailType } from '../../../controllers/maintenance-service/types';
import { AsyncState } from '../../../utils/webRequests.type';
import { errorMessages, oneMillion } from '../../../utils/constants';
import { getTextColor } from '../../../utils/stringFormatting';
import sendRumError from '../../../utils/datadogRum';

const MAX_HELP_TEXT_LENGTH = 1000;

function HelpModal(): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const [fileSizesInfo, setFileSizesInfo] = useState<string[]>([]);
  const [totalFileSizeInfo, setTotalFileSizeInfo] = useState<string>('');
  const [filesAreTooLarge, setFilesAreTooLarge] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<AsyncState>('uninitialized');
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [helpText, setHelpText] = useState<string>('');
  const currentOrgId = useSelector(selectCurrentOrgId) as number;

  const handleShow = () => {
    setShow(true);
  };

  let upLoadtext: React.ReactElement | null = null;

  const resetForm = () => {
    setWarningMessage('');
    setHelpText('');
    const form: HTMLFormElement = document.getElementById('help-form') as HTMLFormElement;
    form.reset();
  };

  const handleClose = () => {
    resetForm();
    setFileSizesInfo([]);
    setUploadStatus('uninitialized');
    setShow(false);
  };

  const submitIssue = async (
    message: string,
  ) => {
    const issue = {
      severity: 'critical',
      org_id: currentOrgId,
      observed_timestamp: `${new Date().toISOString()}`,
      service: 'product-service',
      type: 'help_request' as IssueDetailType,
      message,
      status: 'open',
    };

    return postIssue(currentOrgId, issue).then((data) => {
      setHelpText('');
      return data;
    });
  };

  const handleFileInput: React.ReactEventHandler<HTMLInputElement> = (event) => {
    const files = event.currentTarget.files as FileList;
    const filesInfo: string[] = [];
    let totalFileSize = 0;

    for (let index = 0; index < files.length; index += 1) {
      // allow at most 2 decimal places
      const size = (files[index].size / oneMillion).toFixed(2);
      filesInfo.push(`${files[index].name}: ${size} MB`);
      totalFileSize += files[index].size;
    }

    setTotalFileSizeInfo(totalFileSize > 0 ? `Total Size: ${(totalFileSize / oneMillion).toFixed(2)} MB` : '');

    if (totalFileSize > 20 * oneMillion) {
      setWarningMessage(errorMessages.fileTooLarge);
      setUploadStatus('failed');
      setFilesAreTooLarge(true);
    } else {
      setUploadStatus('uninitialized');
      setFilesAreTooLarge(false);
    }
    setFileSizesInfo(filesInfo);
  };

  const isMessageEmpty = helpText.trim().length === 0;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // if there is a reason to not submit.. exit now.
    if (isMessageEmpty || filesAreTooLarge) {
      return;
    }

    // file data
    const files = (document.getElementsByClassName('help-file-upload-input')[0] as HTMLInputElement).files as FileList;

    const formData = new FormData();
    for (let index = 0; index < files.length; index += 1) {
      formData.append('files', files[index]);
    }

    setWarningMessage('');

    try {
      setUploadStatus('loading');
      const textArea = document?.getElementById('ControlTextarea1') as HTMLInputElement;
      const issueMessage = textArea?.value;
      const issueRequest = await submitIssue(issueMessage);
      const { data } = issueRequest;
      let attachmentRequest: AxiosResponse | undefined;
      if (files.length) {
        if (!data.id) {
          throw new Error('issue type has an undefined id');
        }
        attachmentRequest = await postAttachment(currentOrgId, data.id, formData);
      }
      if (issueRequest?.status < 400 && (attachmentRequest?.status ?? 200) < 400) {
        setUploadStatus('completed');
      } else {
        // note: we can use setWarningMessage() if we do have something to push through...
        setUploadStatus('failed');
      }
      resetForm();
    } catch (error) {
      sendRumError(error);
      setUploadStatus('failed');
    }
  };

  switch (uploadStatus) {
    case 'loading':
      upLoadtext = (
        <Alert variant="light">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Alert>
      );
      break;
    case 'completed':
      upLoadtext = (
        <Alert variant="success">
          Upload successful!
        </Alert>
      );
      break;
    case 'failed':
      if (warningMessage.length > 0) {
        upLoadtext = (
          <Alert variant="danger">
            {warningMessage}
          </Alert>
        );
      } else {
        upLoadtext = (
          <Alert variant="danger">
            Failed to send this request. Please email support at
            {' '}
            <Link className="ma-link" to="mailto:help@marshallinsights.com">
              help@marshallinsights.com
            </Link>
            .
          </Alert>
        );
      }
      break;
    case 'uninitialized':
    default:
      upLoadtext = null;
      break;
  }

  return (
    <>
      <div onClick={handleShow} role="button" tabIndex={0}>
        <span>
          <Button
            className="black-background user-info-item"
            variant="dark"
            id="openHelpModalButton"
            data-testid="openHelpModalButton"
          >
            <Help1
              height="23"
              width="23"
            />
            Help
          </Button>
        </span>
      </div>
      <Modal className="help-modal mt-5" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Help</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="help-modal-content" data-testid="help-modal">
            <Form id="help-form" onSubmit={handleSubmit}>
              <Row>
                { /* eslint-disable max-len */}
                <p>If you need help understanding the data you are seeing, accomplishing a task, or adding/editing an item, please write us a detailed message below.</p>

                <p>To assist our support team in resolving your issue, please include information regarding what you were doing, what you expected, what you experienced, and attach any relevant file(s).</p>

                <p>If you have an idea for a new feature or a suggestion that would improve your experience, please share it with us!</p>
                { /* eslint-enable max-len */}
              </Row>
              <Row>
                <Form.Group controlId="ControlTextarea1">
                  <Form.Control
                    data-testid="help-modal-message-box"
                    as="textarea"
                    placeholder="Enter your request here"
                    rows={11}
                    name="helpText"
                    maxLength={1000}
                    onChange={(e) => { setHelpText(e.currentTarget.value); }}
                    className="help-modal-message-box pb-3"
                  />
                  <div className={`help-entry-length-container text-end ${getTextColor(helpText.length, MAX_HELP_TEXT_LENGTH)}`}>
                    {`${helpText.length}`}
                    /
                    {`${MAX_HELP_TEXT_LENGTH}`}
                  </div>
                </Form.Group>
                <Form.Group controlId="formFileMultiple" className="mb-3 help-file-upload-input-container">
                  <Form.Control
                    className="help-file-upload-input"
                    placeholder="Browse file(s)"
                    onInput={handleFileInput}
                    type="file"
                    name="fileInput"
                    multiple
                  />
                </Form.Group>
              </Row>
              {upLoadtext}
              {fileSizesInfo.length === 1 ? <strong>Selected File</strong> : null}
              {fileSizesInfo.length > 1 ? <strong>Selected Files</strong> : null}
              <div className="file-size-info-container">
                {fileSizesInfo.map((fileInfo, index) => {
                  const key = fileInfo + index;
                  return <p className="file-list-item" key={key}>{fileInfo}</p>;
                })}
              </div>
              {totalFileSizeInfo}
              <hr />
              <Container className="help-buttons">
                <Button
                  id="submit-help-button"
                  data-testid="help-modal-submit"
                  disabled={(
                    isMessageEmpty
                    || filesAreTooLarge
                    || uploadStatus === 'loading'
                  )}
                  type="submit"
                >
                  Send Request
                </Button>
                <Button variant="danger" onClick={handleClose}>Close</Button>
              </Container>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HelpModal;

export const openHelpModal = async (clickEvent: React.MouseEvent<HTMLButtonElement>) => {
  clickEvent.preventDefault();
  clickEvent.stopPropagation();

  // click #openHelpModalButton
  const buttonToClick = document.getElementById('openHelpModalButton');
  if (buttonToClick) {
    buttonToClick.click();
  }
};

export function HelpLinkButton() {
  return (
    <Button
      variant="link"
      className="p-0 ms-1 mb-1"
      id="intro-summon-help"
      data-testid="intro-summon-help"
      onClick={openHelpModal}
    >
      help@marshallinsights.com
    </Button>
  );
}
