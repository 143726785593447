import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';

import ItemCountCard from '../ItemCountCard/ItemCountCard';
import { getProductGroupBySummary } from '../../../controllers/product-service';
import { AsyncState } from '../../../utils/webRequests.type';
import './homeTrackedItemsOverview.css';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import sendRumError from '../../../utils/datadogRum';

/* eslint-disable react/no-array-index-key */
export default function HomeTrackedItemsOverview() {
  const [dataLoadState, setDataLoadState] = useState<AsyncState>('uninitialized');
  const [subTitleText, setSubTitleText] = useState<string>('URLs being visited each day');
  const [summaryCount, setSummaryCount] = useState<number>(0);
  const currentOrgId = useSelector(selectCurrentOrgId);
  const navigate = useNavigate();

  useEffect(() => {
    setDataLoadState('loading');

    getProductGroupBySummary(
      Number(currentOrgId),
    )
      .then((response) => {
        setSummaryCount(response.data.count);
        if (response.data.count > 0) {
          setSubTitleText('URLs being visited each day');
        } else {
          setSubTitleText('Click here to upload or edit items.');
        }
        setDataLoadState('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setSummaryCount(0);
        setDataLoadState('failed');
      });
  }, [currentOrgId]);

  const handleCardClick = () => {
    navigate('/itemAdmin?status=active');
  };

  return (
    <>
      {
        dataLoadState === 'loading' && (
          <Card>
            <Spinner animation="border" />
          </Card>
        )
      }
      {
        dataLoadState === 'failed' && (
          <Card
            data-testid="HomeTrackedItemsOverview-DataLoadState-Failed"
          >
            <p>
              Something went wrong
              <br />
              while loading this resource.
            </p>
          </Card>
        )
      }
      {
        dataLoadState === 'completed' && (
          <ItemCountCard
            datatestid="HomeTrackedItemsOverview-DataLoadState-Completed"
            value={summaryCount.toLocaleString()}
            title="Items Tracked"
            subTitle={subTitleText}
            onClick={handleCardClick}
          />
        )
      }
    </>
  );
}
