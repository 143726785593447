import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';

const propTypes = {
  children: PropTypes.node,
  subMenuText: PropTypes.string.isRequired,
  isActiveClass: PropTypes.string,
};

type SubMenuProps = PropTypes.InferProps<typeof propTypes>;

function SubMenu({ children, subMenuText, isActiveClass }: SubMenuProps) {
  const subMenuTextKey = subMenuText.replace(' ', '_');
  return (
    <Accordion
      data-testid={`sub-menu-accordion-${subMenuTextKey}`}
      defaultActiveKey={(!!isActiveClass && isActiveClass.length > 0) ? subMenuTextKey : ''}
    >
      <Accordion.Item
        eventKey={subMenuTextKey}
        aria-expanded="true"
      >
        <Accordion.Header
          className={isActiveClass || ''}
        >
          <i className={`pe-7s-accordion icon-${subMenuText.toLowerCase()}`} />
          {subMenuText}
        </Accordion.Header>
        <Accordion.Body className="pt-0 pb-0 pe-0">
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

SubMenu.propTypes = propTypes;
SubMenu.defaultProps = {
  children: null,
  isActiveClass: '',
};

export default SubMenu;
