import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCurrentOrganization } from '../../reducers/organizations/organizationsSlice';
import LoadingPage from '../LoadingPage/LoadingPage';
import QuickSightsDashboard from '../QuickSightsDashBoard/QuickSightsDashboard';
import { AsyncState } from '../../utils/webRequests.type';
import { fetchProduct } from '../../controllers/product-service';
import { OrganizationType } from '../../controllers/user-service/types';
import { regexTestForUuid } from '../../utils/constants';
import UntrackedProduct from '../UntrackedProduct/UntrackedProduct';
import ErrorPage from '../ErrorPage/ErrorPage';
import sendRumError from '../../utils/datadogRum';

export default function SkuDetails() {
  const { uuid } = useParams();
  const [uuidValidationState, setUuidValidationState] = useState<AsyncState | 'error'>('uninitialized');
  const currentOrg = useSelector(selectCurrentOrganization) as OrganizationType;
  const param = {
    productUuid: uuid as string,
  };

  useEffect(() => {
    // do a simple test to see if uuid meets formatting requirements (not obviously bad)
    if (!uuid || !uuid.match(regexTestForUuid)) {
      setUuidValidationState('failed');
      return;
    }

    setUuidValidationState('loading');
    // check with product service
    fetchProduct(
      currentOrg.id,
      uuid,
    ).then(() => {
      setUuidValidationState('completed');
    }, (response) => {
      if (response.response.status === 404) {
        // product service returns a 404 if the product isn't found.
        setUuidValidationState('failed');
      } else {
        sendRumError(new Error(response));
        setUuidValidationState('error');
      }
    });
  }, [uuid]);

  if (['uninitialized', 'loading'].includes(uuidValidationState) || !currentOrg) {
    return <LoadingPage />;
  }

  if (uuidValidationState === 'failed') {
    return <UntrackedProduct />;
  }

  if (uuidValidationState === 'error') {
    return <ErrorPage />;
  }

  let baseView;
  try {
    [baseView] = currentOrg.modules.base.view_data;
    if (baseView === undefined) {
      throw new Error(`baseView is note defined for org (${currentOrg})`);
    }
  } catch (error) {
    sendRumError(error);
    throw new Error('organization base path is not configured');
  }
  return <QuickSightsDashboard dashboardPath={baseView.path} parameters={param} />;
}
