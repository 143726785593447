import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function AccessorTipStatus(props: { value: string, className?: string }) {
  const { value, className } = props;
  return (
    <div className={className}>
      <OverlayTrigger
        delay={{ show: 100, hide: 400 }}
        overlay={(
          <Tooltip
            id={`assessor-tooltip-${value}`}
            data-testid={`assessor-tooltip-${value}`}
            className="text-capitalize"
          >
            { value }
          </Tooltip>
        )}
      >
        <div
          className={`tracked-icon tracked-icon-${value}`}
        />
      </OverlayTrigger>
    </div>
  );
}

AccessorTipStatus.defaultProps = {
  className: '',
};

const openLinkInNewTab = (link: string) => {
  window.open(link, '_blank');
};

export function AccessorTipWithLink(props: { value: string, link: string, className?: string }) {
  const { value, link, className } = props;

  return (
    <div className={className}>
      <OverlayTrigger
        delay={{ show: 100, hide: 400 }}
        overlay={(
          <Tooltip
            id={`assessor-tooltip-${value}`}
            data-testid={`assessor-tooltip-${value}`}
          >
            {value}
          </Tooltip>
        )}
      >
        <button
          type="button"
          className="product-link-button ma-link"
          onClick={(() => openLinkInNewTab(link))}
        >
          {value}
        </button>
      </OverlayTrigger>
    </div>
  );
}

AccessorTipWithLink.defaultProps = {
  className: '',
};
