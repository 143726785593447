import React, { FormEvent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import {
  validatePassword,
  changeRequiredPassword,
  alerts,
  isCognitoLoggedin,
} from '../../controllers/auth';
import { AlertType } from '../../controllers/auth/types';
import PageHeader from '../../components/PageHeader/PageHeader';
import { updateProfile } from '../../reducers/profile/profileSlice';
import PasswordCriteria from '../../components/passwordCriteria/PasswordCriteria';
import { AsyncState } from '../../utils/webRequests.type';
import { AppDispatch } from '../../app/store';
import sendRumError from '../../utils/datadogRum';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  session: PropTypes.any.isRequired,
  redirectPath: PropTypes.string.isRequired,
};

type NewPasswordRequiredFormPropType = PropTypes.InferProps<typeof propTypes>;
function NewPasswordRequiredForm(props: NewPasswordRequiredFormPropType) {
  const { session, redirectPath } = props;
  const [loginState, setLoginState] = useState<AsyncState>('uninitialized');
  const [proposedPassword, setProposedPassword] = useState<string>('');
  const [confirmedPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<undefined | AlertType>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (isCognitoLoggedin()) {
      dispatch(updateProfile());
      navigate(redirectPath, { replace: true });
    }
  }, [loginState]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const passwordValidation = validatePassword(proposedPassword) as any[];
  const shortPasswordDetails = passwordValidation.map((detail) => detail.message);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    setLoginState('loading');
    if (passwordValidation.length !== 0) {
      setErrorMessage(alerts.passwordInvalid);
    } else if (proposedPassword !== confirmedPassword) {
      setErrorMessage(alerts.passwordMisMatchAlert);
    } else {
      changeRequiredPassword(session, proposedPassword)
        .then(() => {
          setLoginState('completed');
          setErrorMessage(alerts.passwordSuccess);
        })
        .catch((error) => {
          sendRumError(error);
          setLoginState('failed');
          setErrorMessage(alerts.failure);
        });
    }

    event.currentTarget.reset();
    event.preventDefault();
  };

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <div className="forced-password-page">
      <PageHeader
        title="Set Your Password"
        titleWidth="col-12"
      />
      <Form className="align-self-center" onSubmit={handleSubmit}>
        <p>You are required to set a new password to access Marshall Insights.</p>
        <hr />
        <Form.Group className="mb-3" controlId="formProposedPassword">
          <span className="flex">
            <strong><Form.Label>New Password:</Form.Label></strong>
          </span>
          <Form.Control
            type="password"
            name="proposedPassword"
            placeholder="Enter new password"
            onChange={(event) => setProposedPassword(event.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formConfirmedPassword">
          <span className="flex">
            <strong><Form.Label>Confirm Password:</Form.Label></strong>
          </span>
          <Form.Control
            type="password"
            name="confirmedPassword"
            placeholder="Confirm new password"
            onChange={(event) => setConfirmPassword(event.currentTarget.value)}
          />
        </Form.Group>
        <PasswordCriteria
          proposedPassword={proposedPassword}
          confirmedPassword={confirmedPassword}
        />
        {errorMessage !== undefined
          && (
            <Alert variant={errorMessage.var}>
              {errorMessage.msg}
            </Alert>
          )}
        <div className="justify-content-md-center text-center w-100">
          <Button
            type="submit"
            variant="primary"
            className="set-password-button"
            disabled={
              shortPasswordDetails.length !== 0 || confirmedPassword !== proposedPassword
            }
          >
            {loginState === 'uninitialized'
              ? 'Set Password'
              : (
                <Spinner
                  data-testid="login-spinner"
                  size="sm"
                  animation="grow"
                />
              )}
          </Button>
        </div>
      </Form>
    </div>
  );
}

NewPasswordRequiredForm.propTypes = propTypes;

export default NewPasswordRequiredForm;
