import React, { useEffect, useRef } from 'react';
import {
  Navbar, Container, Nav, NavDropdown, Offcanvas,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TriangleDown, User, Graph3 } from '../../icons';
import {
  selectProfileData, selectProfileStatus, selectCurrentOrgId,
} from '../../reducers/profile/profileSlice';
import {
  NotificationModal,
  HelpModal,
  OrgModal as OrganizationsModal,
  SearchSKUModal,
} from '../modal';
import {
  selectOrganizationsData,
  selectOrganizationsStatus,
} from '../../reducers/organizations/organizationsSlice';
import './Navbar.css';
import { hasGrants, isCognitoLoggedin } from '../../controllers/auth';
import { OrganizationType } from '../../controllers/user-service/types';
import { publicPageList } from '../../utils/constants';
import BookADemoModal from '../modal/bookADemoModal/bookADemoModal';
import MINavDropdownItem from './MINavDropdownItem';

export type DisplayOrgType = {
  name: string,
  id: number
};

const NavBar: React.FC = function NavBar() {
  const profileStatus = useSelector(selectProfileStatus);
  const profile = useSelector(selectProfileData);
  const orgStatus = useSelector(selectOrganizationsStatus);
  const orgData = useSelector(selectOrganizationsData);
  const currentOrgId = useSelector(selectCurrentOrgId);

  const location = useLocation();
  const loggedIn = isCognitoLoggedin();

  // to help with being able to close the dropdowns when QS is in use
  const orgDropdownRef = useRef<HTMLDivElement>(null);
  const accountDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (accountDropdownRef.current !== null) {
      const dropdownToggle = accountDropdownRef.current.firstChild as HTMLAnchorElement;
      if (dropdownToggle !== undefined) {
        dropdownToggle.setAttribute('data-custom-name', 'account-nav-dropdown');
      }
    }
  }, [accountDropdownRef]);

  useEffect(() => {
    if (orgDropdownRef.current !== null) {
      const dropdownToggle = orgDropdownRef.current.firstChild as HTMLAnchorElement;
      if (dropdownToggle !== undefined) {
        dropdownToggle.setAttribute('data-custom-name', 'org-nav-dropdown');
      }
    }
  }, [orgDropdownRef]);

  const navBarLogo = (
    <Navbar.Brand href="/">
      <img id="navbar-logo" src="/assets/images/insights-logo.svg" alt="marshall-logo" />
    </Navbar.Brand>
  );

  // the public Navbar
  if (publicPageList.includes(location.pathname)) {
    return (
      <Navbar className="navbar-container white-background shadow" expand="xl">
        <Container
          className="mx-auto"
          style={{
            maxWidth: '1000px',
          }}
        >
          <Navbar.Brand
            href="/home"
            className="me-5"
          >
            <img
              id="navbar-logo-public"
              src="/assets/images/insights-large-logo-black.svg"
              alt="marshall-logo"
              style={{
                height: '2rem',
                width: 'auto',
              }}
            />
          </Navbar.Brand>
          <Navbar.Offcanvas
            id="public-navbar-nav"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasPublicNavbarLabel-expand">
                Navigate to
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav id="public-navbar-nav-guts" className="flex justify-content-between w-100">
                <Nav.Link href="/home#public-home-ecomm-central" className="fw-bold">Why Insights?</Nav.Link>
                <Nav.Link href="/features" className="fw-bold">Features</Nav.Link>
                <Nav.Link href="#home" className="fw-bold">Resources</Nav.Link>

                {
                  loggedIn ? (
                    <Nav.Link href="/" className="p-4 me-4 fw-bold">
                      <Graph3
                        fill="#000000"
                        className="me-2"
                      />
                      <span
                        style={{
                          position: 'relative',
                          top: '0.125rem',
                        }}
                      >
                        Go To App
                      </span>
                    </Nav.Link>
                  ) : (
                    <Nav.Link href="/login" className="p-4 me-4 fw-bold">
                      <User
                        fill="#000000"
                        className="me-2"
                      />
                      <span
                        style={{
                          position: 'relative',
                          top: '0.125rem',
                        }}
                      >
                        Login
                      </span>
                    </Nav.Link>
                  )
                }

                <Nav.Link href="#home">
                  <BookADemoModal />
                </Nav.Link>

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Navbar.Toggle
            aria-controls="public-navbar-nav"
          />
        </Container>
      </Navbar>
    );
  }

  if (!loggedIn || location.pathname === '/login') {
    return (
      <Navbar className="navbar-container black-background" expand="lg">
        <Container>
          {navBarLogo}
        </Container>
      </Navbar>
    );
  }

  let activeOrg: DisplayOrgType = {
    name: 'loading org',
    id: -1,
  };

  const organizationHasAnyModule = (moduleList: string[]) => {
    const currentOrg = orgData.filter((o: OrganizationType) => o.id === currentOrgId);
    if (currentOrg.length === 0) {
      return false;
    }
    const currentOrgModules = currentOrg[0].modules;
    return Object.keys(currentOrgModules).some((module) => (
      moduleList.includes(module)
    ));
  };

  switch (orgStatus) {
    case 'uninitialized':
      activeOrg.name = 'Loading...';
      break;
    case 'loading':
      activeOrg.name = 'Loading...';
      break;
    case 'failed':
      activeOrg.name = 'Error loading org';
      activeOrg.id = -1;
      break;
    default:
      if (profileStatus === 'completed' && orgData) {
        const orgID = currentOrgId;
        if (orgID) {
          const tmpOrg = orgData?.find((org) => org.id === orgID);
          if (tmpOrg !== undefined) {
            activeOrg = {
              name: tmpOrg.name,
              id: tmpOrg.id,
            };
          } else {
            activeOrg = {
              name: 'Select new org',
              id: -1,
            };
          }
        } else {
          activeOrg = {
            name: orgData[0].name,
            id: orgData[0].id,
          };
        }
      }
  }
  const orgDropDownTitle = (
    <>
      <span
        className="nav-name-box"
        data-custom-name="org-nav-dropdown-label"
      >
        {activeOrg.name}
      </span>
      <TriangleDown className="icon-angle-down" />
    </>
  );
  const userDropDownTitle = (
    <>
      <span
        className="nav-name-box"
        data-custom-name="account-nav-dropdown-label"
      >
        {profile?.first_name}
        {' '}
        {profile?.last_name}
      </span>
      <TriangleDown className="icon-angle-down" />
    </>
  );

  return (
    <Navbar
      className="navbar-container black-background"
      expand="lg"
      onSubmit={(e) => e.preventDefault()}
    >
      <Container>
        {navBarLogo}
        <SearchSKUModal />
        <Nav className="me-auto white-text">
          <div id="user-info-area">
            <div
              className="stealth-text"
            >
              <i className="pe-7s-accordion icon-content" />
            </div>
            <HelpModal />
            <NotificationModal />
            <NavDropdown
              className="user-info-item org-info-dropdown btn"
              title={orgDropDownTitle}
              id="org-nav-dropdown"
              drop="down"
              data-testid="org-dropdown-button"
              onBlur={() => orgDropdownRef.current?.click()}
              onClick={() => orgDropdownRef.current?.click()}
              ref={orgDropdownRef}
            >
              {
                orgData.length > 1 && (
                  <OrganizationsModal />
                )
              }
              <MINavDropdownItem
                data-testid="linkItemAdmin"
                data-custom-name="org-nav-dropdown-itemAdmin"
                navigateTo="/itemAdmin"
              >
                <p>Item Administration</p>
                <p className="tooltip-color">
                  Add and edit tracked products and categorical data
                </p>
              </MINavDropdownItem>
              <MINavDropdownItem
                data-testid="linkUserAdmin"
                data-custom-name="org-nav-dropdown-userAdmin"
                navigateTo="/users"
              >
                <p>User Administration</p>
                <p className="tooltip-color">
                  Add, remove, or grant permissions to users
                </p>
              </MINavDropdownItem>
              {
                hasGrants(activeOrg.id, [
                  'SOV_ADMIN',
                  'SOV_VIEWER',
                  'VIEWER',
                ], ['MEMBER'])
                  && organizationHasAnyModule(['share_of_voice'])
                  ? (
                    <MINavDropdownItem
                      data-testid="linkSOVAdmin"
                      data-custom-name="org-nav-dropdown-sovAdmin"
                      navigateTo="/sovAdmin"
                    >
                      <p>SOV Administration</p>
                      <p className="tooltip-color">
                        Add, edit, or remove SOV search terms and groups
                      </p>
                    </MINavDropdownItem>
                  )
                  : null
              }
              {
                hasGrants(activeOrg.id, [], [
                  'ADMIN',
                  'APPLICATION_RESOURCE_MANAGER',
                ])
                  ? (
                    <MINavDropdownItem
                      data-testid="linkOrganizationAdmin"
                      navigateTo="/organizationAdmin"
                    >
                      <p>Organization Administration</p>
                      <p className="tooltip-color">
                        Manage organization settings
                      </p>
                    </MINavDropdownItem>
                  )
                  : null
              }
            </NavDropdown>
            <NavDropdown
              className="user-info-item user-name-dropdown user-info-dropdown btn"
              title={userDropDownTitle}
              id="account-nav-dropdown"
              data-testid="account-nav-dropdown"
              data-custom-name="account-nav-dropdown"
              drop="down"
              onBlur={() => accountDropdownRef.current?.click()}
              ref={accountDropdownRef}
            >
              <MINavDropdownItem
                navigateTo="/settings"
                data-testid="account-nav-dropdown-settings"
                data-custom-name="account-nav-dropdown-settings"
              >
                <p>Preferences</p>
                <p className="tooltip-color">
                  Manage notifications, update user information, and defaults
                </p>
              </MINavDropdownItem>
              <MINavDropdownItem
                navigateTo="/logout"
                data-testid="account-nav-dropdown-logout"
                data-custom-name="account-nav-dropdown-logout"
              >
                <p>Logout</p>
                <p className="tooltip-color">
                  Sign out from your user profile and return to the login page
                </p>
              </MINavDropdownItem>
            </NavDropdown>
          </div>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavBar;
