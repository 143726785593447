import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { Form, Button, OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link, useNavigate } from 'react-router-dom';

import './UserAdministration.css';
import {
  selectCurrentOrgId,
} from '../../reducers/profile/profileSlice';
import PageHeader from '../../components/PageHeader/PageHeader';
import Table from '../../components/Table/Table';
import {
  consolidateUserGrants,
  fetchUsers,
  UserRoleFilterMap,
} from '../../controllers/user-service';
import SearchInput from '../../components/SearchInput/SearchInput';
import { grantTranslationDict } from '../../utils/constants';
import { AsyncState } from '../../utils/webRequests.type';
import LoadingPage from '../LoadingPage/LoadingPage';
import { hasGrants } from '../../controllers/auth';
import { UserGrant } from '../../controllers/auth/types';
import {
  UserRoleFilter,
  UserSchema,
  UserType,
} from '../../controllers/user-service/types';
import sendRumError from '../../utils/datadogRum';

function getGrantTranslation(s: UserGrant) {
  return (s in grantTranslationDict) ? grantTranslationDict[s] : s;
}

const columns: Column<UserSchema & { disabled: boolean }>[] = [
  {
    Header: 'Name/Email',
    accessor: ({ name, email }) => (
      <div className="wrapper name-email-cell">
        <div className="cell primary">
          {name}
        </div>
        <OverlayTrigger
          delay={{ show: 100, hide: 400 }}
          overlay={(
            <Tooltip
              id={`assessor-tooltip-email-${email}`}
              data-testid={`assessor-tooltip-email-${email}`}
            >
              {email}
            </Tooltip>
          )}
        >
          <div className="cell secondary">
            {email}
          </div>
        </OverlayTrigger>
      </div>
    ),
  },
  {
    Header: 'Role & Permissions',
    accessor: ({ role, permissions, email }) => (
      <div className="wrapper role-permissions-cell">
        <div className="cell primary" data-testid={`role-${email}`}>
          {role}
        </div>
        <OverlayTrigger
          delay={{ show: 100, hide: 400 }}
          overlay={(
            <Tooltip
              id={`assessor-tooltip-permissions-${email}`}
              data-testid={`assessor-tooltip-permissions-${email}`}
            >
              {permissions}
            </Tooltip>
          )}
        >
          <div className="cell secondary">
            {permissions}
          </div>
        </OverlayTrigger>
      </div>
    ),
  },
  {
    Header: 'Actions',
    accessor: ({ id, disabled }) => (
      <div className="text-center actions-cell">
        <Link
          className={`ma-link text-blushing-salmon edit-user-button ${disabled ? 'disabled' : ''}`}
          to={`/users/edit/${id}`}
        >
          Edit
        </Link>
      </div>
    ),
  },
];

export default function UserAdministration() {
  const currentOrgId = useSelector(selectCurrentOrgId);

  const isAllowedToUpdate = hasGrants(currentOrgId, ['USER_ADMIN']);

  const [users, setUsers] = useState<UserType[]>([]);
  const [usersLoadStatus, setUsersLoadStatus] = useState<AsyncState>('uninitialized');
  const navigate = useNavigate();

  useEffect(() => {
    if (currentOrgId) {
      fetchUsers(currentOrgId)
        .then((response) => {
          setUsers(response.data);
        })
        .then(() => {
          setUsersLoadStatus('completed');
        })
        .catch((error) => {
          sendRumError(error);
          setUsersLoadStatus('failed');
        });
    }
  }, [currentOrgId]);

  const tableData: UserSchema[] = [];
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState<UserRoleFilter>('All');
  users
    .map((user) => consolidateUserGrants(user, currentOrgId.toString()) as unknown as UserType)
    .forEach((item) => {
      const currentOrgIdString = String(currentOrgId);

      let role = 'Team Member';

      if (item.grants[currentOrgIdString].includes('ORG_ADMIN')) {
        role = 'Admin';
      } else if (item.grants[currentOrgIdString].includes('ORG_OWNER')) {
        role = 'Owner';
      } else if (item.grants[currentOrgIdString].includes('VIEWER')) {
        role = 'Viewer';
      }

      let permissions = '';
      if (currentOrgId && item.grants[currentOrgIdString]) {
        const sortedGrants = [...item.grants[currentOrgIdString]]
          .map((grant: UserGrant) => getGrantTranslation(grant))
          .filter((translation: string) => translation > '')
          .sort();
        permissions = sortedGrants.join(', ');
      }
      const tableItem: UserSchema = {
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
        email: item.email,
        role,
        permissions,
      };
      tableData.push(tableItem);
    });

  if (['loading', 'uninitialized'].includes(usersLoadStatus)) {
    return <LoadingPage />;
  }

  const filteredTableData = tableData.filter((item) => {
    const cleanSearchTerm = searchTerm.toLowerCase();
    const matchSearchTerm = (
      item.name.toLowerCase().includes(cleanSearchTerm) || item.email.includes(cleanSearchTerm)
    );
    const matchRoleFilter = UserRoleFilterMap[roleFilter].includes(item.role);
    return matchSearchTerm && matchRoleFilter;
  }).map((userData) => ({
    ...userData,
    disabled: !isAllowedToUpdate,
  }));

  const pagination = {
    pageSize: 7,
  };

  return (
    <div className="page user-administration-page h-100">
      <PageHeader title="Manage Users" returnText="Back to Home" returnLink="/">
        {isAllowedToUpdate ? (
          <Button
            onClick={() => navigate('/users/new')}
            className="create-user-button pull-right"
          >
            Invite User
          </Button>
        ) : (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 400 }}
            overlay={(
              <Tooltip
                id="upload-tooltip"
                data-testid="upload-tooltip"
                className="upload-tooltip"
              >
                You do not have the permissions required to invite users.
                Please contact an administrator if you need your permissions updated.
              </Tooltip>
            )}
          >
            <span
              className="disabled-button-wrapper"
            >
              <Button
                disabled
                className="create-user-button pull-right"
              >
                Invite User
              </Button>
            </span>
          </OverlayTrigger>
        )}
      </PageHeader>
      <div className="row gx-5">
        <div className="col-3 h-100 user-info-page">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Form.Group className="mb-3">
              <span className="flex">
                <strong><Form.Label>Search Users</Form.Label></strong>
              </span>
              <SearchInput
                data-testid="user-search-input"
                placeholder="Search name or email"
                className="user-search-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchTerm(e.currentTarget.value);
                }}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formRoleFilter"
            >
              <span className="flex">
                <strong><Form.Label>Filter By Role</Form.Label></strong>
              </span>
              <Form.Check type="radio" id="role-radio-All">
                <Form.Check.Input
                  data-testid="role-filter-all"
                  name="roleFilter"
                  type="radio"
                  aria-label="All"
                  onChange={() => { setRoleFilter('All'); }}
                  checked={roleFilter === 'All'}
                />
                <Form.Check.Label
                  data-testid="role-filter-all-text"
                  onClick={() => { setRoleFilter('All'); }}
                >
                  All
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="radio" id="role-radio-Admin">
                <Form.Check.Input
                  id="roleFilterAdmin"
                  data-testid="role-filter-admin"
                  name="roleFilter"
                  type="radio"
                  aria-label="Admin"
                  onChange={() => { setRoleFilter('Admin'); }}
                  checked={roleFilter === 'Admin'}
                />
                <Form.Check.Label
                  data-testid="role-filter-admin-text"
                  onClick={() => { setRoleFilter('Admin'); }}
                >
                  Admin
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="radio" id="role-radio-Viewer">
                <Form.Check.Input
                  data-testid="role-filter-viewer"
                  name="roleFilter"
                  type="radio"
                  aria-label="Viewer"
                  onChange={() => { setRoleFilter('Viewer'); }}
                  checked={roleFilter === 'Viewer'}
                />
                <Form.Check.Label
                  data-testid="role-filter-viewer-text"
                  onClick={() => { setRoleFilter('Viewer'); }}
                >
                  Viewer
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="radio" id="role-radio-Team-Member">
                <Form.Check.Input
                  data-testid="role-filter-team-member"
                  name="roleFilter"
                  type="radio"
                  aria-label="Team Member"
                  onChange={() => { setRoleFilter('Team Member'); }}
                  checked={roleFilter === 'Team Member'}
                />
                <Form.Check.Label
                  data-testid="role-filter-team-member-text"
                  onClick={() => { setRoleFilter('Team Member'); }}
                >
                  Team Member
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Form>
        </div>
        <div id="UserAdministrationContent" className="col-9 h-100 container pull-right">
          {
            usersLoadStatus === 'failed'
              ? (
                <p>
                  There was an error loading the users list.
                  Please make sure you have the access to this organization and page,
                  then refresh the page.
                </p>
              )
              : <Table columns={columns} data={filteredTableData} pagination={pagination} />
          }
        </div>

      </div>
    </div>
  );
}
