import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Stack } from 'react-bootstrap';

const propTypes = {
  children: PropTypes.node.isRequired,
  MenuText: PropTypes.string,
};

type NavDrawerMenuProps = PropTypes.InferProps<typeof propTypes>;

function NavDrawerMenu({ MenuText, children }: NavDrawerMenuProps) {
  return (
    <Nav.Item>
      <div
        className="left-nav-section-header"
      >
        <h6
          className="left-nav-section-header-label"
        >
          {MenuText}
        </h6>
        <div
          className="pt-0 pb-0 pe-0"
        >
          <Stack direction="vertical" gap={0}>
            {children}
          </Stack>
        </div>
      </div>
    </Nav.Item>
  );
}

NavDrawerMenu.propTypes = propTypes;

NavDrawerMenu.defaultProps = {
  MenuText: '',
};

export default NavDrawerMenu;
