import React from 'react';
import './PublicServiceLevelAgreement.css';
import { Container } from 'react-bootstrap';
import NavFooter from '../../components/navbar/NavFooter';

function PublicServiceLevelAgreement() {
  document.title = 'Marshall Insights | Terms of Service Level Agreement';

  return (
    <div
      id="public-service-level-agreement-page"
      className="public-service-level-agreement-page display-grid container"
    >
      <Container
        className="row mt-5 w-100 text-left"
      >
        <h2>
          Service Level Agreement
        </h2>

        <p>
          The Services shall be available 95% of the time, measured monthly, excluding scheduled
          maintenance. Any downtime resulting from outages of third-party connections or utilities
          or other reasons beyond Marshall&apos;s reasonable control will be excluded from any such
          calculation.
        </p>

        <p>
          Marshall has help desk agents and software support engineers working 9 AM - 5 PM US
          Central Time Monday - Friday, excluding Federal Holidays. Help desk tickets are monitored
          and evaluated over the weekend and holidays but are only actioned if there is an
          indication there may be a serious issue with Marshall&apos;s systems. Automated system
          alerts are monitored during the weekend and are addressed in cases of major outages.
          Average user utilization is reduced ~95% on weekends.
        </p>
      </Container>

      <NavFooter />
    </div>
  );
}

export default PublicServiceLevelAgreement;
