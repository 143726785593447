import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import ItemCountCard from '../ItemCountCard/ItemCountCard';
import { getProductGroupBy } from '../../../controllers/product-service';
import { AsyncState } from '../../../utils/webRequests.type';
import './homeItemsRequiringAttention.css';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import sendRumError from '../../../utils/datadogRum';

export default function HomeItemsRequiringAttention() {
  const [dataLoadState, setDataLoadState] = useState<AsyncState>('uninitialized');
  const [subTitleText, setSubTitleText] = useState<string>('URLs unable to be tracked due to known issues');
  const [summaryCount, setSummaryCount] = useState<number>(0);
  const currentOrgId = useSelector(selectCurrentOrgId);
  const navigate = useNavigate();

  useEffect(() => {
    setDataLoadState('loading');

    // TODO: backend product-service needs a tweak for this to work correctly
    getProductGroupBy(
      Number(currentOrgId),
      'status',
    )
      .then((response) => {
        let myValue = 0;
        for (let i = 0; i < response.data.length; i += 1) {
          if (response.data[i].selector === 'requires attention') {
            myValue = response.data[i].count;
          }
        }

        setSummaryCount(myValue);
        if (myValue > 0) {
          setSubTitleText('URLs unable to be tracked');
        } else {
          setSubTitleText('Click here to upload or edit items.');
        }
        setDataLoadState('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setSummaryCount(0);
        setDataLoadState('failed');
      });
  }, [currentOrgId]);

  const handleCardClick = () => {
    navigate('/itemAdmin?status=requires%20attention');
  };

  return (
    <>
      {
        dataLoadState === 'loading' && (
          <Card>
            <Spinner animation="border" />
          </Card>
        )
      }
      {
        dataLoadState === 'failed' && (
          <Card
            data-testid="HomeItemsRequiringAttention-DataLoadState-Failed"
          >
            <p>
              Something went wrong
              <br />
              while loading this resource.
            </p>
          </Card>
        )
      }
      {
        dataLoadState === 'completed' && (
          <ItemCountCard
            datatestid="HomeItemsRequiringAttention-DataLoadState-Completed"
            value={summaryCount.toLocaleString()}
            title="Items Requiring Attention"
            subTitle={subTitleText}
            onClick={handleCardClick}
          />
        )
      }
    </>
  );
}
