import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './RetailerUrlFormatting.css';
import { retailerUrlFormats, RetailerUrlFormat } from './retailerUrlFormats';
import PageHeader from '../../components/PageHeader/PageHeader';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import { fetchOrganizationSubscriptions } from '../../controllers/subscription-service';
import { AsyncState } from '../../utils/webRequests.type';
import { RetailerResponse } from '../../controllers/subscription-service/types';
import sendRumError from '../../utils/datadogRum';
import { HelpLinkButton } from '../../components/modal/helpModal/helpModal';

export default function RetailerUrlFormatting() {
  const currentOrgId = useSelector(selectCurrentOrgId);
  const [selectedRetailers, setSelectedRetailers] = useState<RetailerUrlFormat[]>([]);
  const [subscribedRetailersFetchState, setSubscribedRetailersFetchState] = useState<AsyncState>('uninitialized');
  const [subscribedRetailers, setSubscribedRetailers] = useState<RetailerResponse[]>([]);

  // issue: you should have an async state for this...
  const loadSubscribedRetailers = async () => {
    // exit if invalid org (don't even try)
    if (Number(currentOrgId) < 1) { return; }

    setSubscribedRetailersFetchState('loading');
    fetchOrganizationSubscriptions(
      Number(currentOrgId),
      [
        'product_page',
        'product_reviews',
        'product_seller_info',
      ],
    )
      .then(async (response) => {
        const data = response.data.map((d) => (
          { id: d.retailer_id, name: d.retailer_name }
        ));
        setSubscribedRetailers(data);
        setSubscribedRetailersFetchState('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setSubscribedRetailersFetchState('failed');
      });
  };

  useEffect(() => {
    loadSubscribedRetailers();
  }, [currentOrgId]);

  const isSubscribedRetailer = (retailer: RetailerUrlFormat) => {
    const rv = subscribedRetailers.some((ele) => ele.name === retailer.retailer_db_name);
    return rv;
  };

  useEffect(() => {
    setSelectedRetailers(retailerUrlFormats.filter(isSubscribedRetailer));
  }, [subscribedRetailersFetchState]);

  // run after data load: scroll to element identified hash
  useEffect(() => {
    const hashArray = window.location.hash.split('#') ?? [];

    if (hashArray.length === 2) {
      const targetElement = document.getElementById(hashArray[1]);
      if (targetElement) {
        targetElement.scrollIntoView();
      }
    }
  }, [selectedRetailers]);

  return (
    <div className="page retailer-url-formatting-page h-100 col-12">
      <PageHeader title="Accepted Retailer Product URL Formats" returnText="Back to Home" returnLink="/" />
      <section id="intro">
        <p>
          If the retailer you are looking for is not listed,
          please reach out to the helpdesk by clicking
          <HelpLinkButton />
          .
        </p>
      </section>
      {
        selectedRetailers.map(
          (retailer) => (
            <div
              className="PageContent row"
              key={retailer.retailer_home}
              id={retailer.retailer_home}
            >
              <h3>{retailer.retailer_name}</h3>
              {
                retailer.requirements.length === 1 && (
                  <b>Requirement:</b>
                )
              }
              {
                retailer.requirements.length > 1 && (
                  <b>Requirements:</b>
                )
              }
              <ol>
                {
                  retailer.requirements.map(
                    (requirement) => requirement,
                  )
                }
              </ol>

              {
                retailer.examples.length === 1 && (
                  <b>Example:</b>
                )
              }
              {
                retailer.examples.length > 1 && (
                  <b>Examples:</b>
                )
              }
              <ol>
                {
                  retailer.examples.map(
                    (example) => example,
                  )
                }
              </ol>
            </div>
          ),
        )
      }
    </div>
  );
}
