/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Speaker({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M-0.021 12.316v7.464h3.199v6.398h1.066v-6.398h8.202l7.788 6.302v-20.259l-7.837 6.493h-12.418zM1.046 18.713v-5.331h4.265v5.331h-4.265zM13.077 13.137l6.090-5.046v15.756l-6.344-5.133h-6.447v-5.331h6.404l0.296-0.245z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M27.613 6.793l-0.767 0.767c2.495 1.953 4.108 4.985 4.108 8.392 0 3.498-1.701 6.599-4.311 8.544l0.772 0.772c2.799-2.143 4.606-5.518 4.606-9.316-0-3.706-1.722-7.010-4.407-9.159z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M24.187 10.22l-0.775 0.775c1.646 1.040 2.745 2.87 2.745 4.957 0 2.179-1.197 4.080-2.966 5.091l0.777 0.777c1.953-1.226 3.256-3.392 3.256-5.868 0-2.384-1.204-4.485-3.036-5.732z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Speaker;
