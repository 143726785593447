import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Nav, NavDropdown, Form, Spinner,
} from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';
import './searchSKUModal.css';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import {
  getSearchSKUProducts,
} from '../../../controllers/product-service';
import {
  ProductStatus,
  SearchSKURequest,
  SearchSKUResponse,
} from '../../../controllers/product-service/types';
import SearchInput from '../../SearchInput/SearchInput';
import { AsyncState } from '../../../utils/webRequests.type';
import sendRumError from '../../../utils/datadogRum';
import { STATUS_TO_DISPLAYED_TEXT } from '../../../utils/constants';

/* istanbul ignore next */
const dropHrefOnElementTree = (element: HTMLElement) => {
  if (element.hasAttribute && element.hasAttribute('href')) {
    element.removeAttribute('href');
  }
  if (element.hasChildNodes()) {
    element.childNodes.forEach((child) => {
      dropHrefOnElementTree(child as unknown as HTMLElement);
    });
  }
};

function SearchSKUModal(): JSX.Element {
  const navigate = useNavigate();
  // Show / Hide the search result(s)
  const [showSearchSKUResult, setShowSearchSKUResult] = useState<AsyncState>('uninitialized');
  const [searchSKUError, setSearchSKUError] = useState<string>('');
  const [isDropDownHidden, setDropDownHidden] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [resultDropdownContent, setResultDropdownContent] = (
    useState<JSX.Element | JSX.Element[]>([])
  );

  // Search result object
  const [searchSKUResult, setSearchSKUResult] = useState<null | SearchSKUResponse>(null);

  const currentOrgId = useSelector(selectCurrentOrgId) as number;

  // something to keep track of the setTimeout to clear the "it's empty" message
  const [clearResultTimer, setClearResultTimer] = useState<number | undefined>(undefined);

  const resetForm = () => {
    (formRef.current as HTMLFormElement).reset();
    setShowSearchSKUResult('uninitialized');
  };

  const translateStatus = (status:ProductStatus) => (
    (status in STATUS_TO_DISPLAYED_TEXT) ? STATUS_TO_DISPLAYED_TEXT[status] : status
  );

  // the request to product service
  const requestSearchSKU = async (
    searchTerm: string,
  ) => {
    const searchParams: SearchSKURequest = {
      term: searchTerm,
      limit: 20,
      status_list: ['active', 'requires attention'],
    };

    setShowSearchSKUResult('loading');

    getSearchSKUProducts(currentOrgId, searchParams)
      .then((response) => {
        setSearchSKUResult(response.data as SearchSKUResponse);
        setShowSearchSKUResult('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setSearchSKUError(error.message);
        setShowSearchSKUResult('failed');
      });
  };

  // handles flow post-debounce.. don't bother the product service if we don't have to.
  const searchSKUHandler = (s: string) => {
    // if empty: don't ping the API, just reset
    if (s.trim().length === 0) {
      resetForm();
      return;
    }

    // otherwise ping the API
    requestSearchSKU(s);
  };

  // debounce 500 ms
  const debouncedSearchSKUHandler = useMemo(
    () => debounce(searchSKUHandler, 500),
    [searchSKUResult, currentOrgId],
  );

  const changeUrl = (url: string) => {
    navigate(url, { replace: true });
    window.clearTimeout(clearResultTimer);
    setClearResultTimer(window.setTimeout(() => {
      setDropDownHidden(true);
    }, 50));
  };

  // and attaching the above hook...
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef !== null) {
      const wrapperElement = wrapperRef.current as unknown as HTMLDivElement;
      dropHrefOnElementTree(wrapperElement);
    }
  }, [wrapperRef, resultDropdownContent]);

  useEffect(() => {
    if (clearResultTimer) {
      window.clearTimeout(clearResultTimer);
      setClearResultTimer(undefined);
    }
    // Content for the dropdown.. various cases.
    switch (showSearchSKUResult) {
      case 'loading':
        setResultDropdownContent(
          <NavDropdown.Item>
            Searching ... &nbsp;
            <Spinner data-testid="login-spinner" size="sm" animation="grow" />
          </NavDropdown.Item>,
        );
        break;
      case 'completed':
        if (searchSKUResult === null || searchSKUResult.results.length === 0) {
          setResultDropdownContent(
            <NavDropdown.Item>
              <p>
                No results found for the provided input.
              </p>
            </NavDropdown.Item>,
          );
          // wait 5s before clearing message
          setClearResultTimer(window.setTimeout(() => {
            setResultDropdownContent([]);
          }, 5000));
        } else {
          setResultDropdownContent(
            searchSKUResult.results
              .sort((prod1, prod2) => prod1.manufacturer_sku.localeCompare(prod2.manufacturer_sku))
              .map((product) => (
                <NavDropdown.Item
                  key={product.id}
                  className="search-sku-result"
                  onClick={(e) => {
                    e.preventDefault();
                    changeUrl(`/skuDetails/${product.id}`);
                  }}
                >
                  <div>
                    <div className="d-flex flex-row justify-content-between">
                      <b className="sku-result-manufacturer_sku col-6">
                        {product.manufacturer_sku}
                      </b>
                      <div className="d-flex justify-content-end align-items-center sku-search-product-status-container col-6">
                        <span className="color-gizmo-grey sku-search-product-status capitalize align-middle">
                          {translateStatus(product.status)}
                        </span>
                        <div
                          className={`tracked-icon float-right tracked-icon-${product.status} ms-2 align-middle`}
                        />
                      </div>
                    </div>
                    <span className="ellipsis">
                      {product.model_name}
                    </span>
                    <br />
                    <span className="ellipsis">
                      <span>
                        {product.retailer_name}
                      </span>
                      :&nbsp;
                      <b>
                        {product.retailer_sku}
                      </b>
                    </span>
                  </div>
                </NavDropdown.Item>
              )),
          );
        }
        break;
      case 'failed':
        setResultDropdownContent(
          <NavDropdown.Item
            role="alert"
            className="fade alert alert-danger"
          >
            <p>
              {searchSKUError}
            </p>
          </NavDropdown.Item>,
        );
        break;
      case 'uninitialized':
      default:
        setResultDropdownContent(
          <NavDropdown.Item>
            <p>
              To search products by SKU,
              <br />
              please input text above.
            </p>
          </NavDropdown.Item>,
        );
        window.clearTimeout(clearResultTimer);
        setClearResultTimer(window.setTimeout(() => {
          setResultDropdownContent([]);
        }, 5000));
        break;
    }
  }, [showSearchSKUResult]);

  useEffect(
    // clear timeout on dismoun
    () => (
      () => {
        if (clearResultTimer) {
          window.clearTimeout(clearResultTimer);
        }
      }
    ),
    [clearResultTimer],
  );
  return (
    <Nav className="me-auto white-text">
      <div id="search-sku-area" ref={wrapperRef}>
        <NavDropdown
          className="search-sku-nav-dropdown show"
          id="search-sku-nav-dropdown"
          data-testid="search-sku-nav-dropdown"
          drop="down"
          title={(
            <Form ref={formRef} className="d-flex" id="search-sku-form">
              <SearchInput
                data-testid="search-sku-input"
                className="search-sku-input"
                onBlur={() => {
                  window.clearTimeout(clearResultTimer);
                  setClearResultTimer(window.setTimeout(() => {
                    setDropDownHidden(true);
                  }, 500));
                }}
                onClick={() => {
                  setDropDownHidden(false);
                }}
                onFocus={() => {
                  setDropDownHidden(false);
                }}
                placeholder="Search SKU"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDropDownHidden(false);
                  debouncedSearchSKUHandler(e.currentTarget.value);
                }}
              />
            </Form>
          )}
        >
          {
            isDropDownHidden
              ? null
              : (
                <>
                  {resultDropdownContent}
                  {searchSKUResult !== null
                    && (
                      <NavDropdown.Item
                        className="text-wrap bg-quicksight-active-grey sku-detail-item-count-information-item"
                        data-testid="sku-detail-item-count-information-item"
                        hidden={
                          searchSKUResult.summary.count <= 20
                          || showSearchSKUResult === 'loading'
                        }
                        onClick={() => {
                          try {
                            const searchSkuInput = formRef.current
                              ?.getElementsByClassName('search-input-control')[0] as HTMLInputElement;
                            changeUrl(
                              `/itemAdmin?search_term=${searchSkuInput.value}`,
                            );
                          } catch (_e) {
                            // pass
                            // safety to prevent errors in local
                          }
                        }}
                      >
                        Displaying 20 out of
                        {' '}
                        {(searchSKUResult).summary.count}
                        {' '}
                        products.
                        <br />
                        Navigate to
                        {' '}
                        <span className="ma-link no-break">Item Administration</span>
                        {' '}
                        for more.
                      </NavDropdown.Item>
                    )}
                </>
              )
          }
        </NavDropdown>
      </div>
    </Nav>
  );
}

export default SearchSKUModal;
