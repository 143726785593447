import React from 'react';
import { Column } from 'react-table';
import {
  Accordion,
  Card,
} from 'react-bootstrap';
import Table from '../../Table/Table';
import { SearchTermRowMessage } from '../../../controllers/sov-service/types';

type GenericResultItem = {
  warnings: SearchTermRowMessage[]
};

type ListResultItemsProps<T extends GenericResultItem> = {
  eventKey: string,
  data: T[],
  dataColumns: Column<T>[],
  headerClass: string,
  headerText: string,
};

const dropDownCountText = (
  length: number,
) => ((length !== 1) ? 'Items' : 'Item');

function ListResultItems<T extends GenericResultItem>(props: ListResultItemsProps<T>): JSX.Element {
  const {
    eventKey, data, dataColumns, headerClass, headerText,
  } = props;
  const warningCount = data.filter((t) => (t.warnings.length) > 0).length;
  return (
    <Accordion>
      <Accordion.Item
        eventKey={eventKey}
        hidden={data?.length === 0}
      >
        <Accordion.Header className={headerClass}>
          <strong>
            {data?.length ?? 0}
            &nbsp;&nbsp;
            {dropDownCountText(data?.length ?? 0)}
            &nbsp;
            {headerText}
          </strong>
          {warningCount
            ? (
              <span className="text-almost-black">
                &nbsp;
                (
                {warningCount}
                {' '}
                with
                {' '}
                <b>
                  Warning(s)
                </b>
                )
              </span>
            )
            : null}
        </Accordion.Header>
        <Accordion.Body>
          <Table columns={dataColumns} data={data} />
        </Accordion.Body>
      </Accordion.Item>
      <Card className="mt-4" hidden={data?.length !== 0}>
        <Card.Header className={headerClass}>
          <strong>
            0
            &nbsp;
            {headerText}
          </strong>
        </Card.Header>
      </Card>
    </Accordion>
  );
}

export default ListResultItems;
