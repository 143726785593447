/* eslint-disable react/function-component-definition */
import React, { FC } from 'react';
import { UsePaginationInstanceProps } from 'react-table';
import Button from 'react-bootstrap/Button';

import './TablePagination.scss';
import PaginationBlankSpace from './PaginationBlankSpace';

export type TablePaginationProps = {
  pageIndex?: number;
  pageSize?: number;
};

const TablePagination: FC<UsePaginationInstanceProps<{
  [key: string]: number;
}> & TablePaginationProps> = (props) => {
  const {
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
    pageIndex = 0,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pageSize = 10,
  } = props;

  // only 1 page.. don't need pagination
  if (pageCount < 2) {
    return null;
  }
  // 2-7 pages: will show direct links to all pages.
  if (pageCount < 8) {
    return (
      <nav className="table-pagination" aria-label="Table pagination">
        <ul className="pagination pagination-sm justify-content-center text-center">
          <li>
            <Button className="arrow left" onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="previous page">
              &lsaquo;
            </Button>
          </li>
          { [...Array(pageCount).keys()].map((pageNum) => (
            <li key={`Pagination-${pageNum}`}>
              <Button
                data-testid={`pagination-${pageNum}`}
                className={pageNum === pageIndex ? 'active' : ''}
                disabled={pageNum === pageIndex}
                onClick={() => gotoPage(pageNum)}
                aria-label={`Page ${pageNum + 1}`}
              >
                {pageNum + 1}
              </Button>
            </li>
          ))}
          <li>
            <Button className="arrow right" onClick={() => nextPage()} disabled={!canNextPage} aria-label="next page">
              &rsaquo;
            </Button>
          </li>
        </ul>
      </nav>
    );
  }
  // otherwise maintain 7 static positions.
  return (
    <nav className="table-pagination" aria-label="Table pagination">
      <ul className="pagination pagination-sm justify-content-center text-center">
        <li>
          <Button className="arrow left" onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="previous page">
            &lsaquo;
          </Button>
        </li>
        <li>
          <Button
            className={pageIndex === 0 ? 'active' : ''}
            data-testid="pagination-0"
            disabled={pageIndex === 0}
            onClick={() => gotoPage(0)}
            aria-label="Page 1"
          >
            1
          </Button>
        </li>

        <li>
          {(pageIndex < 4) ? (
            <Button
              className={pageIndex === 1 ? 'active' : ''}
              data-testid="pagination-1"
              disabled={pageIndex === 1}
              onClick={() => gotoPage(1)}
              aria-label="Page 2"
            >
              2
            </Button>
          ) : (
            <PaginationBlankSpace />
          )}
        </li>

        <li>
          {
            // 3 cases here:
            // case 1: low page number selected.. this is a static '3'
            (pageIndex < 4) ? (
              <Button
                className={pageIndex === 2 ? 'active' : ''}
                data-testid="pagination-2"
                disabled={pageIndex === 2}
                onClick={() => gotoPage(2)}
                aria-label="Page 3"
              >
                3
              </Button>
            ) : null
          }
          {
            // case 2: medium page number selected.. this value is dynamic
            (pageIndex > 3 && pageIndex < (pageCount - 4)) ? (
              <Button
                onClick={() => gotoPage(pageIndex - 1)}
                aria-label={`Page ${pageIndex}`}
              >
                {pageIndex}
              </Button>
            ) : null
          }
          {
            // case 3: high page number selected.. this is a static 'n-4'
            (pageIndex > (pageCount - 5)) ? (
              <Button
                className={pageIndex === (pageCount - 5) ? 'active' : ''}
                data-testid={`pagination-${(pageCount - 5)}`}
                disabled={pageIndex === (pageCount - 5)}
                onClick={() => gotoPage(pageCount - 5)}
                aria-label={`Page ${pageCount - 4}`}
              >
                {pageCount - 4}
              </Button>
            ) : null
          }
        </li>

        <li>
          {
            // 3 cases here:
            // case 1: medium page number selected.. this value is dynamic
            (pageIndex > 2 && pageIndex <= (pageCount - 4)) ? (
              <Button
                className="active"
                data-testid={`pagination-${(pageIndex)}`}
                disabled
                aria-label={`Page ${pageIndex + 1}`}
              >
                {pageIndex + 1}
              </Button>
            ) : null
          }
          {
            // case 2: low page number selected.
            (pageIndex < 3) ? (
              <Button
                onClick={() => gotoPage(3)}
                data-testid="pagination-3"
                aria-label="Page 4"
              >
                4
              </Button>
            ) : null
          }
          {
            // case 3: high page number selected.
            (pageIndex > (pageCount - 4)) ? (
              <Button
                onClick={() => gotoPage(pageCount - 4)}
                data-testid={`pagination-${(pageCount - 4)}`}
                aria-label={`Page ${pageCount - 3}`}
              >
                {pageCount - 3}
              </Button>
            ) : null
          }
        </li>

        <li>
          {
            // 3 cases here:
            // case 1: high page number selected.. this is a static 'pageCount - 2'
            (pageIndex > (pageCount - 5)) ? (
              <Button
                className={pageIndex === (pageCount - 3) ? 'active' : ''}
                data-testid={`pagination-${(pageCount - 3)}`}
                disabled={pageIndex === (pageCount - 3)}
                onClick={() => gotoPage(pageCount - 3)}
                aria-label={`Page ${pageCount - 2}`}
              >
                {pageCount - 2}
              </Button>
            ) : null
          }
          {
            // case 2: medium page number selected.. this value is dynamic
            (pageIndex > 2 && pageIndex < (pageCount - 4)) ? (
              <Button
                onClick={() => gotoPage(pageIndex + 1)}
                data-testid={`pagination-${(pageIndex + 1)}`}
                aria-label={`Page ${pageIndex + 2}`}
              >
                {pageIndex + 2}
              </Button>
            ) : null
          }
          {
            // case 3: low page number selected.. this is a static '5'
            (pageIndex < 3) ? (
              <Button
                onClick={() => gotoPage(4)}
                data-testid="pagination-4"
                aria-label="Page 5"
              >
                5
              </Button>
            ) : null
          }
        </li>

        <li>
          {(pageIndex > (pageCount - 5)) ? (
            <Button
              className={pageIndex === (pageCount - 2) ? 'active' : ''}
              data-testid={`pagination-${(pageCount - 2)}`}
              disabled={pageIndex === (pageCount - 2)}
              onClick={() => gotoPage((pageCount - 2))}
              aria-label={`Page ${pageCount - 1}`}
            >
              {pageCount - 1}
            </Button>
          ) : (
            <PaginationBlankSpace />
          )}
        </li>

        <li>
          <Button
            className={pageIndex === (pageCount - 1) ? 'active' : ''}
            data-testid={`pagination-${(pageCount - 1)}`}
            disabled={pageIndex === (pageCount - 1)}
            onClick={() => gotoPage(pageCount - 1)}
            aria-label={`Page ${pageCount}`}
          >
            {pageCount}
          </Button>
        </li>
        <li>
          <Button className="arrow right" onClick={() => nextPage()} disabled={!canNextPage} aria-label="next page">
            &rsaquo;
          </Button>
        </li>
      </ul>
    </nav>
  );
};

TablePagination.defaultProps = {
  pageIndex: 0,
  pageSize: 10,
};

export default TablePagination;
