import React, { ReactElement } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  Column,
  PluginHook,
  TableOptions,
  UsePaginationOptions,
  UseSortByOptions,
  UseSortByState,
  UsePaginationState,
  TableState,
  HeaderGroup,
  ColumnInstance,
  Row,
  Cell,
} from 'react-table';
import BTable from 'react-bootstrap/Table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Table.css';
import TablePagination, { TablePaginationProps } from './TablePagination';

interface TableOwnProps<D extends { [key: string]: unknown }> {
  columns: Column<D>[];
  data?: D[];
  pagination?: TablePaginationProps | boolean;
}

export type TableProps<D extends { [key: string]: unknown }> = TableOwnProps<D>;

export function AccessorTip(props: { value: string, className?: string }) {
  const { value, className } = props;

  return (
    <div className={className}>
      <OverlayTrigger
        delay={{ show: 100, hide: 400 }}
        overlay={(
          <Tooltip
            id={`assessor-tooltip-${value}`}
            data-testid={`assessor-tooltip-${value}`}
            className={className?.includes('capitalize') ? 'capitalize' : ''}
          >
            {value}
          </Tooltip>
        )}
      >
        <span>
          {value}
        </span>
      </OverlayTrigger>
    </div>
  );
}

AccessorTip.defaultProps = {
  className: '',
};

/**
 * Table component using [react-table](https://github.com/tannerlinsley/react-table) to display the data.
 * Can be grouped, filtered, sorted. Themed with theme-ui for consistency.
 */
export default function Table<D extends { [key: string]: unknown }>({
  columns,
  data = [],
  // ! this needs to be a REF so that it's current value is retained in the parent
  // (and protects it from re-renders)
  pagination,
}: TableProps<D>): ReactElement {
  const plugins: PluginHook<D>[] = [
    useSortBy,
  ];
  const initialState: Partial<TableState<D>>
  & Partial<UsePaginationState<D>>
  & Partial<UseSortByState<D>> = {};
  if (pagination) {
    plugins.push(usePagination);
    if (typeof pagination === 'object') {
      if (typeof pagination.pageIndex === 'number') {
        initialState.pageIndex = pagination.pageIndex;
      }
      if (typeof pagination.pageSize === 'number') {
        initialState.pageSize = pagination.pageSize;
      }
    }
  }
  const options: TableOptions<D>
  & UsePaginationOptions<D>
  & UseSortByOptions<D> = {
    columns,
    data,
    initialState,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableOptions = useTable<D>(options, ...plugins) as any;
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', when using pagination
    state: { pageIndex: statePageIndex, pageSize: statePageSize },
  } = tableOptions;
  const tableProps = getTableProps();

  return (
    <>
      <div className="ma-table-container">
        <BTable style={tableProps.style} className={`${tableProps.className ? `${tableProps.className}` : ''} w-100`} role={tableProps.role}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup) => {
              const headerGroupProps = headerGroup.getHeaderGroupProps();
              return (
                <tr key={headerGroupProps.key}>
                  {headerGroup.headers.map((column: ColumnInstance) => {
                    const headerProps = column.getHeaderProps();
                    const columnClass = (
                      `${String(column.Header).toLowerCase().replace(/\W/g, '')}-column`
                    );
                    return (
                      <th
                        key={headerProps.key}
                        className={columnClass}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {(page || rows).map((row: Row) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <tr key={rowProps.key} className="ma-table-row">
                  {row.cells.map((cell: Cell) => {
                    const cellProps = cell.getCellProps();
                    return (
                      <td key={cellProps.key} className="ma-table-cell">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
      </div>
      {pagination && (
        <TablePagination
          pageIndex={statePageIndex}
          pageSize={statePageSize}
          page={page}
          pageCount={tableOptions.pageCount}
          pageOptions={tableOptions.pageOptions}
          canPreviousPage={tableOptions.canPreviousPage}
          canNextPage={tableOptions.canNextPage}
          gotoPage={tableOptions.gotoPage}
          previousPage={tableOptions.previousPage}
          nextPage={tableOptions.nextPage}
          setPageSize={tableOptions.setPageSize}
        />
      )}
    </>
  );
}

Table.defaultProps = {
  data: [],
  pagination: false,
};
