import React from 'react';
import './PublicHelp.css';
import { Container } from 'react-bootstrap';
import NavFooter from '../../components/navbar/NavFooter';

function PublicHelp() {
  document.title = 'Marshall Insights | Help';

  return (
    <div id="public-help-page" className="public-help-page display-grid container justify-content-center">
      <Container
        className="row mt-5 w-100 text-center"
      >
        <span>
          Please reach out to
          &nbsp;
          <a
            href="mailto:help@marshallinsights.com"
            className="p-0"
          >
            help@marshallinsights.com
          </a>
          .
        </span>
      </Container>

      <NavFooter />
    </div>
  );
}

export default PublicHelp;
