import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Table, Tooltip, OverlayTrigger,
} from 'react-bootstrap';

import './CardTable.css';

const propTypes = {
  datatestid: PropTypes.string,
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
  ).isRequired,
  className: PropTypes.string,
};

type CardTableProps = {
  datatestid: string,
  title: string,
  columns: string[],
  tableData: string[][],
  className: string,
};

/* eslint-disable react/no-array-index-key */
export default function CardTable(props: CardTableProps) {
  const {
    datatestid,
    title,
    tableData,
    columns,
    className,
  } = props;
  return (
    <Card
      className={`card-table ${className}`}
      data-testid={datatestid}
    >
      {(tableData.length > 0)
        ? (
          <div className="side-margins-for-table">
            <Table responsive>
              <thead>
                <tr>
                  {columns.map((header, index) => (
                    <th key={`${title}-${header}-${index}`}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr
                    key={`${title}-${row}-${index}`}
                    className="card-table-row"
                  >
                    {row?.map((td, rowIndex) => (
                      <td key={`${title}-${row}-${td}-${rowIndex}`}>

                        <OverlayTrigger
                          delay={{ show: 100, hide: 400 }}
                          overlay={(
                            <Tooltip
                              id={`assessor-tooltip-email-${td}`}
                              data-testid={`assessor-tooltip-email-${td}`}
                            >
                              {td}
                            </Tooltip>
                          )}
                        >
                          <span
                            className="card-table-cell-container w-100"
                          >
                            {td}
                          </span>
                        </OverlayTrigger>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )
        : (
          <div
            className="card-table-value-nodata text-center text-muted font-weight-bold"
          >
            <p>N/A</p>
          </div>
        )}
      <Card.Footer>
        <div className="card-table-title-text text-center font-weight-bold">
          {title}
        </div>
      </Card.Footer>
    </Card>
  );
}

CardTable.propTypes = propTypes;

CardTable.defaultProps = {
  datatestid: '',
  className: '',
  title: '',
};
