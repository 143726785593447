/* istanbul ignore file */

import {
  configureStore, ThunkAction, Action, combineReducers, AnyAction, isRejected, Middleware,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import profileReducer from '../reducers/profile/profileSlice'; // eslint-disable-line import/no-cycle
import organizationsReducer from '../reducers/organizations/organizationsSlice'; // eslint-disable-line import/no-cycle
import notificationsReducer from '../reducers/notifications/notificationSlice'; // eslint-disable-line import/no-cycle
import organizationSubscriptionsReducer from '../reducers/subscriptions/organizationSubscriptionsSlice'; // eslint-disable-line import/no-cycle
import GlobalConfig from './config';
import sendRumError from '../utils/datadogRum';

let displayThunkErrors = true;

export const setDisplayThunkErrors = (show: boolean) => {
  displayThunkErrors = show;
};

const appReducer = combineReducers({
  profile: profileReducer,
  organizations: organizationsReducer,
  notifications: notificationsReducer,
  organizationSubscriptions: organizationSubscriptionsReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'STATE_RESET') {
    localStorage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['notifications'],
};

const logErrorMiddleWare: Middleware<
Record<string, never>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_store) => (next) => (action: AnyAction) => {
  // Check if the action is a Promise
  if (isRejected(action) && displayThunkErrors) {
    if (GlobalConfig.config.ENV === 'production') {
      sendRumError(action.error, {
        reduxAction: action,
      });
    } else {
      console.error(action.error); // eslint-disable-line no-console
    }
  }
  return next(action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({ serializableCheck: false })
      .concat(
        logErrorMiddleWare,
      )),
});

export const actions = {
  resetState: {
    type: 'STATE_RESET',
    data: undefined,
  },
};
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
