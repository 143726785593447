import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { headers } from '..';
import { ValidationError } from '../../utils/webRequests.type';
import useAuthenticationInterceptors from '../auth/useAuthenticationInterceptors';
import { DemoRequestPayload, DemoRequestResponse, IssueType } from './types';

let maintenanceService: AxiosInstance;

const fileHeaders = (orgID: number) => ({
  ...headers(orgID),
  'Content-Type': 'multipart/form-data',
});

export const initializeMaintenanceController = (serviceUrl: string) => {
  maintenanceService = axios.create({
    baseURL: serviceUrl,
  });
  useAuthenticationInterceptors(maintenanceService);
};

export const fetchIssues = async (orgId: number) => {
  const thePast = new Date();
  // request all notifications recorded in the past 365 days.
  thePast.setDate(thePast.getDate() - 30);

  const params = {
    org_id: orgId,
    recorded_timestamp_from: thePast.toISOString(),
  };

  return maintenanceService.get<IssueType[] | ValidationError>('/issues/', { params, headers: headers(orgId) });
};

export const fetchNotifications = async (orgId: number) => {
  const thePast = new Date();
  // request all notifications recorded in the past 365 days.
  thePast.setDate(thePast.getDate() - 365);

  const params = {
    org_id: orgId,
    recorded_timestamp_from: thePast.toISOString(),
  };

  return maintenanceService.get<IssueType[] | ValidationError>('/issues/', { params, headers: headers(orgId) });
};

export const fetchIssue = async (orgId: number, id: string) => maintenanceService.get(`/issue/${id}/`, { headers: headers(orgId) });

export const postIssue = async (orgId: number, payload: IssueType) => maintenanceService.post<IssueType>('/issue/', payload, { headers: headers(orgId) });

export const postAttachment = async (orgId: number, id: string, payload: FormData) => maintenanceService.post(`/issue/${id}/attachments/`, payload, { headers: fileHeaders(orgId) });

export const postBookDemo = async (
  payload: DemoRequestPayload,
) => maintenanceService.post<
DemoRequestPayload,
AxiosResponse<DemoRequestResponse>>(
  '/book_a_demo/',
  payload,
);

export const listFiles = async (orgId: number, id: string) => {
  const { data } = await fetchIssue(orgId, id);
  return data?.details?.file_list;
};

export const showStatus = async (orgId: number, id: string) => {
  const { data } = await fetchIssue(orgId, id);
  return data?.status;
};
