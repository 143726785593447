import { datadogRum } from '@datadog/browser-rum';
import { Dictionary } from '@reduxjs/toolkit';
import GlobalConfig from '../app/config';

const sendRumError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: Dictionary<any> = {},
) => {
  if (!error) {
    // ignore empty errors
    return;
  }

  // display errors to console.log IFF we are running on local (and not during a JEST run)
  if (GlobalConfig.config.ENV === 'local' && process.env.NODE_ENV !== 'test') { // istanbul ignore line
    console.log(error); // eslint-disable-line no-console
  }

  const newError = (error instanceof Error) ? error : new Error(error);
  const localStoragePersistRoot = (
    JSON.parse(window.localStorage.getItem('persist:root') ?? '{}')
  );

  const profile = Object.keys(localStoragePersistRoot).includes('profile')
    ? JSON.parse(localStoragePersistRoot.profile)
    : undefined;
  // context can't exceed 3 (or 16) kib
  datadogRum.addError(
    newError,
    {
      persistRootProfile: profile,
      ...context,
    },
  );
};

export default sendRumError;
