/* istanbul ignore file */

import React, { SVGProps } from 'react';

function AngleLeftCircle({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M29.312 15.992c0-7.366-5.97-13.337-13.337-13.337s-13.337 5.97-13.337 13.337 5.97 13.337 13.337 13.337 13.337-5.97 13.337-13.337zM3.706 15.992c0-6.765 5.504-12.27 12.27-12.27s12.27 5.505 12.27 12.27-5.505 12.27-12.27 12.27c-6.765 0-12.27-5.505-12.27-12.27z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M12.792 15.231l-0.754 0.754 6.035 6.035 0.754-0.754-5.281-5.281 5.256-5.256-0.754-0.754-3.013 3.013z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default AngleLeftCircle;
