import React from 'react';
import { Link } from 'react-router-dom';

const TimeDriftMessage: JSX.Element = (
  <>
    <p>
      Your device&apos;s system clock appears to be off.
      For security purposes you are being logged out of the
      application and you may need to adjust your device&apos;s
      clock before attempting to log back in.
    </p>
    <p>
      Please verify your device&apos;s clock is correct by visiting&nbsp;
      <Link to="https://time.gov">https://time.gov</Link>
      &nbsp;and contact your IT department if you
      need assistance in adjusting your device&apos;s clock to the
      correct time.
    </p>
    <p>
      For additional information, contact&nbsp;
      <Link
        className="ma-link"
        to="/login"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.location.href = 'mailto:help@marshallinsights.com';
        }}
      >
        help@marshallinsights.com
      </Link>
      .
    </p>
  </>
);

export default TimeDriftMessage;
