export type FeatureListBlock = {
  key: string,
  order: number,
  label: string,
  bullets: string[],
};

export type FeatureGroupBlock = {
  key: string,
  order: number,
  label: string,
  features: FeatureListBlock[],
};

/* List of feature groups, with nested feature list / text
assumed things are already in 'order'
*/
export const publicFeatureGroups: Readonly<FeatureGroupBlock[]> = [
  {
    key: 'availability-feature',
    order: 100,
    label: 'Availability Feature Group',
    features: [
      {
        key: 'availability-overview',
        order: 110,
        label: 'Availability Overview',
        bullets: [
          'Items not available on retailer site',
          'Today\'s item availability text (e.g. Available: Ships in 3-5 Days)',
          'Today\'s buy box winner',
        ],
      },
      {
        key: 'availability-history',
        order: 120,
        label: 'Availability History',
        bullets: [
          'Tracked items not purchasable on retailer site(s) over time',
          'Breakdown of availability statuses across tracked retailer site(s)',
          'Historical export detailing item availability by day',
        ],
      },
    ],
  },
  {
    key: 'pricing-feature',
    order: 100,
    label: 'Pricing Feature Group',
    features: [
      {
        key: 'pricing-overview',
        order: 210,
        label: 'Pricing Overview',
        bullets: [
          'Today\'s buy box price',
          'Today\'s retailer price',
          'Margins at a given price (requires cost and MSRP data to be provided by Client)',
          'Price changes against prior reporting periods',
          'Items above or below MSRP (requires MSRP data to be provided by Client)',
        ],
      },
      {
        key: 'pricing-history',
        order: 220,
        label: 'Pricing History',
        bullets: [
          'Historical pricing data by day for as long as items have been tracked by Client',
          'Historical buy box winner',
          'Price changes against prior reporting periods',
          'Retail prices across the market pinpointing which retailer(s) may be driving prices down (only applies to retailers whose data Client subscribes to in the Marshall Insights application)',
        ],
      },
      {
        key: 'map-overview',
        order: 230,
        label: 'MAP Overview',
        bullets: [
          'Items below MAP (requires MAP pricing data to be provided by Client)',
          'Today\'s buy box price',
          'MAP violations by retailer',
          'MAP price violation history',
        ],
      },
    ],
  },
  {
    key: 'reviews-feature',
    order: 300,
    label: 'Reviews Feature Group',
    features: [
      {
        key: 'reviews-overview',
        order: 310,
        label: 'Reviews Overview',
        bullets: [
          'Count of SKUs in rating bands (e.g. 4 - 5 stars)',
          'Average item rating by retailer',
          'Average item rating by category',
          'Today\'s item rating',
          'Today\'s review count',
        ],
      },
      {
        key: 'reviews-details',
        order: 320,
        label: 'Reviews Details',
        bullets: [
          'Count of reviews by retailer',
          'Full text and star rating of each review',
          'Identification of syndicated reviews',
        ],
      },
    ],
  },
  {
    key: 'content-feature',
    order: 400,
    label: 'Content Feature Group',
    features: [
      {
        key: 'content-overview',
        order: 410,
        label: 'Content Overview',
        bullets: [
          'Collection of item product titles, descriptions, bullet points, images, videos, and other digital assets present at a given retailer',
          'Counts of the digital assets identified in the preceding bullet point',
          'Title, description, and bullet point content length',
          'Presence of enhanced content',
        ],
      },
    ],
  },
  {
    key: 'marketplace-feature',
    order: 500,
    label: 'Marketplace Feature Group',
    features: [
      {
        key: 'most-common-3p-sellers',
        order: 510,
        label: 'Most Common 3P Sellers',
        bullets: [
          '3rd party seller identification where applicable marketplace E-Commerce environments exist',
          'Daily capture of buy box status and marketplace seller count at online retailers',
          'Number of offers of your tracked items from a 3rd party seller',
          'Number of buy box wins from a 3rd party seller offering your tracked items',
          'Links to 3rd party seller pages where applicable',
        ],
      },
      {
        key: 'category-rankings',
        order: 520,
        label: 'Category Rankings',
        bullets: [
          'Amazon bestseller ranks by Amazon category for each day an Amazon item is tracked using Marshall Insights (categories subject to change at Amazon\'s discretion)',
        ],
      },
    ],
  },
  {
    key: 'sales-analytics-feature',
    order: 600,
    label: 'Sales Analytics Feature Group',
    features: [
      {
        key: 'amazon-vendor-central-POS',
        order: 610,
        label: 'Amazon VC POS',
        bullets: [
          'Amazon Vendor Central POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'amazon-seller-central-POS',
        order: 612,
        label: 'Amazon SC POS',
        bullets: [
          'Amazon Seller Central POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'home-depot-POS',
        order: 630,
        label: 'Home Depot POS',
        bullets: [
          'Home Depot POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'lowes-POS',
        order: 640,
        label: 'Lowes POS',
        bullets: [
          'Lowes POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'meijer-POS',
        order: 650,
        label: 'Meijer POS',
        bullets: [
          'Meijer POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'target-POS',
        order: 660,
        label: 'Target POS',
        bullets: [
          'Target POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'walmart-1p-POS',
        order: 670,
        label: 'Walmart 1P POS',
        bullets: [
          'Walmart 1P POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'walmart-3p-POS',
        order: 672,
        label: 'Walmart 3P POS',
        bullets: [
          'Walmart 3P POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'wayfair-POS',
        order: 680,
        label: 'Wayfair POS',
        bullets: [
          'Wayfair POS reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
    ],
  },
  {
    key: 'advertising-analytics-feature',
    order: 700,
    label: 'Advertising Analytics Feature Group',
    features: [
      {
        key: 'amazon-advertising',
        order: 710,
        label: 'Amazon Advertising',
        bullets: [
          'Amazon Advertising search reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'amazon-dsp',
        order: 720,
        label: 'Amazon DSP',
        bullets: [
          'Amazon Advertising DSP reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
      {
        key: 'wayfair-advertising',
        order: 730,
        label: 'Wayfair Advertising',
        bullets: [
          'Wayfair Advertising search reporting',
          'KPI aggregations and historical sales trends',
        ],
      },
    ],
  },
  {
    key: 'share-of-voice-feature',
    order: 800,
    label: 'Share of Voice Feature Group',
    features: [
      {
        key: 'share-of-voice',
        order: 810,
        label: 'Share of Voice',
        bullets: [
          'Monitoring brand presence in retailer search results using Client-provided search terms',
        ],
      },
    ],
  },
];
