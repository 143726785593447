import React, { useEffect, useState } from 'react';
import { Nav, Stack } from 'react-bootstrap';
import './LeftNavbar.css';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import NavDrawerMenu from './NavDrawerMenu';
import SubMenu from './subMenu';
import { selectCurrentOrganization } from '../../reducers/organizations/organizationsSlice';
import { isCognitoLoggedin } from '../../controllers/auth';
import {
  getModuleFromOrgData,
  makeBreadCrumbMap,
} from '../../controllers/user-service';
import { AngleLeftCircle, AngleRightCircle } from '../../icons';
import { MenuItemType, MenuMapType, OrganizationType } from '../../controllers/user-service/types';
import { publicPageList } from '../../utils/constants';

export const getMenuItems = (currentOrg: OrganizationType | undefined): MenuItemType[] => {
  const viewData = currentOrg ? getModuleFromOrgData(currentOrg) : [];
  return viewData
    .filter((view) => view.isVisible)
    .sort((view0, view1) => view0.displayOrder - view1.displayOrder);
};

const LeftNavBar: React.FC = function LeftNavBar() {
  const location = useLocation();

  const shouldBeExpanded = () : boolean => ['/', '', '/login'].includes(location.pathname);
  const [expanded, setExpanded] = useState<boolean>(shouldBeExpanded());

  const currentOrg = useSelector(selectCurrentOrganization);

  // open/close navbar if href changed...
  useEffect(() => {
    setExpanded(shouldBeExpanded());
  }, [location]);

  // TODO define centralized way to determine if navbars render or not.
  if (!isCognitoLoggedin() || ['/login', '/logout', '/reset'].includes(location.pathname)) {
    return null;
  }

  if (publicPageList.includes(location.pathname)) {
    return null;
  }

  const toggleNav = () => setExpanded(!expanded);
  const menuItems = getMenuItems(currentOrg);
  const breadCrumbMap = makeBreadCrumbMap(menuItems);
  const breadcrumbMatch = location.pathname.replace('/module/', '');

  const makeSubMenu = (subMenuMap: MenuMapType | undefined) => {
    const subMenuKeys: string[] = Object.keys(subMenuMap || {});
    return (
      subMenuKeys.map((subMenuText) => {
        const isSubMenuActive = !!subMenuMap
          && subMenuMap[subMenuText].items.some((element) => (
            element.path === breadcrumbMatch
          ));
        return (
          <SubMenu
            subMenuText={subMenuText}
            key={subMenuText}
            isActiveClass={isSubMenuActive ? 'leftNavActiveParent' : ''}
          >
            <Stack>
              {
                !!subMenuMap
                && subMenuMap[subMenuText].subMenu
                && makeSubMenu(subMenuMap[subMenuText].subMenu)
              }
              {
                !!subMenuMap
                && (subMenuMap[subMenuText].items ?? []).map((item: MenuItemType) => (
                  <Link
                    key={item.path}
                    to={`/module/${item.path}`}
                    className={`sidebar-link ${item.path === breadcrumbMatch ? 'highlight' : ''}`}
                  >
                    {item.friendlyName}
                  </Link>
                ))
              }
            </Stack>
          </SubMenu>
        );
      })
    );
  };

  const menuTextArray: string[] = Object.keys(breadCrumbMap);
  return (
    <div
      id="left-nav"
      data-testid="left-nav"
      className="sidebar"
    >
      <div
        className={expanded ? 'sidebar-expanded' : 'sidebar-collapsed'}
        id="left-nav-content"
        data-testid="left-nav-content"
      >
        <AngleRightCircle
          onClick={toggleNav}
          className={`sidebar-icon fa-sm collapsed ${expanded ? 'invisible' : 'visible'
          }`}
          data-testid="leftNavbar-expand-icon"
        />
        <AngleLeftCircle
          fill="white"
          stroke="white"
          onClick={toggleNav}
          className={`sidebar-icon fa-sm ${expanded ? 'visible' : 'invisible'
          }`}
          data-testid="leftNavbar-collapse-icon"
        />
        <Nav
          defaultActiveKey="/"
          className="ml-auto leftNavbar"
          data-testid="leftNavbar"
        >
          <Stack direction="vertical">
            {
              (menuTextArray.length > 0)
              && menuTextArray.map((menuText: string) => (
                <NavDrawerMenu MenuText={menuText} key={menuText}>
                  {
                    breadCrumbMap[menuText].items.map(
                      (item: MenuItemType) => (
                        <Link
                          to={`/module/${item.path}`}
                          key={item.path}
                          className={`sidebar-link ${item.path === breadcrumbMatch ? 'highlight' : ''}`}
                        >
                          {
                            item.path === 'share_of_voice/overview' ? (
                              <i className={`pe-7s-accordion icon-${item.path.split('/')[item.path.split('/').length - 1]}`} />
                            ) : null
                          }
                          {item.path.split('/')[item.path.split('/').length - 1]}
                        </Link>
                      ),
                    )
                  }
                  {
                    breadCrumbMap[menuText].subMenu
                    && makeSubMenu(breadCrumbMap[menuText].subMenu)
                  }
                </NavDrawerMenu>
              ))
            }
            {
              (menuTextArray.length === 0 && currentOrg)
              && (
                <p className="leftnav-empty">
                  You have not been granted access to any analytics views.
                  Please request access from an administrator within your organization.
                </p>
              )
            }
          </Stack>
        </Nav>
      </div>
    </div>
  );
};

export default LeftNavBar;
