import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { headers } from '..'; // eslint-disable-line import/no-cycle
import { VIEWER_GRANTS } from '../../utils/constants'; // eslint-disable-line import/no-cycle
import partialMatch from '../../utils/partialMatch';
import { // eslint-disable-line import/no-cycle
  checkUserServiceToken,
  refreshSession,
  hasGrants,
  isCognitoLoggedin,
} from '../auth';
import { // eslint-disable-line import/no-cycle
  DecodedUserServiceToken,
  UserServiceResponse,
  UserGrant,
  UserGrantMutable,
} from '../auth/types';
import {
  EditableUserFields,
  MenuItemType,
  MenuMap,
  MenuMapType,
  OrganizationType,
  UpdateUserPayload,
  UserType,
  ViewDataType,
} from './types';
import sendRumError from '../../utils/datadogRum';

let userService: AxiosInstance;

// constant to help with user filters
export const UserRoleFilterMap = {
  All: ['Owner', 'Admin', 'Viewer', 'Team Member'],
  Admin: ['Owner', 'Admin'],
  Viewer: ['Viewer'],
  'Team Member': ['Team Member'],
};

let isFetchingToken = false;

export const initializeUserController = (serviceUrl: string) => {
  userService = axios.create({
    baseURL: serviceUrl,
  });

  userService.interceptors.request.use(async (config) => {
    const ignoreCase = [
      {
        method: 'post',
        url: '/user/confirm',
      },
      {
        method: 'post',
        url: '/user/forgot',
      },
      {
        method: 'options',
      },
    ];

    for (let i = 0; i < ignoreCase.length; i += 1) {
      // ignore if matching
      if (partialMatch(ignoreCase[i], config)) {
        return config;
      }
    }

    if (partialMatch({ method: 'get', url: '/token' }, config)) {
      if (isFetchingToken) {
        /*
        eslint-disable-next-line
        no-param-reassign,
        @typescript-eslint/no-explicit-any,
        @typescript-eslint/no-unused-vars,
        no-async-promise-executor
        */
        config.adapter = (_config) => new Promise<any>(async (resolve) => {
          let retry = 0;

          // sleep until token is loaded or retry count is exceeded
          while (isFetchingToken) {
            if (retry < 12) {
              // eslint-disable-next-line no-await-in-loop
              await new Promise((resolveSleep) => { setTimeout(resolveSleep, 200); });
              retry += 1;
            } else {
              throw Error(`failed to resolve token. Retried ${retry} times`);
            }
          }
          const res = {
            data: {
              token: localStorage.getItem('user_service_token'),
            },
            status: 200,
            statusText: 'OK',
            headers: { 'content-type': 'text/plain; charset=utf-8' },
            config,
            request: {},
          };
          // eslint-disable-next-line no-promise-executor-return
          return resolve(res);
        }).finally(() => {
          // set isFetchingToken to false so other waiting calls run;
          isFetchingToken = false;
        });
      } else {
        isFetchingToken = true;
      }
    } else if (!checkUserServiceToken()) {
      const newConfig = await refreshSession()
        .then(() => ({
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('user_service_token')}`,
          } as AxiosRequestHeaders,
        }))
        .catch(Promise.reject.bind(Promise));
      return newConfig;
    }
    return config;
  }, (error) => Promise.reject(error));

  userService.interceptors.response.use(
    async (response) => {
      // when first token is fetched flip fetching flag
      if (partialMatch({ method: 'get', url: '/token' }, response.config)) {
        isFetchingToken = false;
      }
      return Promise.resolve(response);
    },
    (error) => Promise.reject(error),
  );
};

const getBearerToken = () => `Bearer ${localStorage.getItem('cognito_jwt')}`;

export const setAccessToken = async () => {
  // need this check, otherwise it loops endlessly
  if (!isCognitoLoggedin()) {
    throw Error('user is not logged in');
  }
  try {
    const response = await userService.get<UserServiceResponse>('/token', {
      headers: {
        Authorization: getBearerToken(),
      },
    });
    localStorage.setItem('user_service_token', response.data.token);
    window.dispatchEvent(new Event('user_service_token_change'));
  } catch (error) {
    sendRumError(
      error,
      {
        requestedAction: 'user-service:setAccessToken',
      },
    );
  }
};

export const fetchOrganizations = async (
  orgId: number | null = null,
  organizations: string[] | null = null,
) => {
  const authHeader = !orgId ? headers() : headers(orgId);
  const result = await userService.get<OrganizationType[]>('/organizations/', {
    headers: authHeader,
    params: {
      organizations,
    },
  });
  return {
    ...result,
    data: result.data.filter((row) => !['disabled', 'dead'].includes(row.status)),
  };
};

export const fetchOrganization = async (orgId: number) => userService.get<OrganizationType[]>('/organizations/', {
  headers: headers(orgId),
});

export const makeBreadCrumbMap = (moduleArray: MenuItemType[]) => {
  const breadCrumbMap: MenuMapType = {};
  moduleArray.forEach((module: MenuItemType) => {
    const subModules: string[] = module.breadcrumb.split(' -> ');
    let breadCrumbPointer: MenuMap;
    subModules.forEach((_subModule, i) => {
      if (i === subModules.length - 1) { // The end of the breadcrumb trail
        breadCrumbPointer.items.push(module);
      } else if (i === 0) { // The first item in the list
        if (breadCrumbMap[subModules[i]]) { // First Menu exists
          breadCrumbPointer = breadCrumbMap[subModules[i]];
        } else { // First Menu doesn't exist
          breadCrumbMap[subModules[i]] = {
            items: [],
            subMenu: {},
          };
          breadCrumbPointer = breadCrumbMap[subModules[i]];
        }
      } else if (breadCrumbPointer.subMenu
        && breadCrumbPointer.subMenu[subModules[i]]) { // submenu exists
        breadCrumbPointer = breadCrumbPointer.subMenu[subModules[i]];
      } else if (breadCrumbPointer.subMenu
        && !breadCrumbPointer.subMenu[subModules[i]]) { // submenu object exists but not key
        breadCrumbPointer.subMenu[subModules[i]] = {
          items: [],
          subMenu: {},
        };
        breadCrumbPointer = breadCrumbPointer.subMenu[subModules[i]];
      } else { // submenu doesn't exist
        breadCrumbPointer.subMenu = {};
        breadCrumbPointer.subMenu[subModules[i]] = {
          items: [],
          subMenu: {},
        };
        breadCrumbPointer = breadCrumbPointer.subMenu[subModules[i]];
      }
    });
  });
  return breadCrumbMap;
};

export const hasViewPermission = (
  orgId: string,
  viewPermissions: UserGrant[],
) => hasGrants(Number(orgId), viewPermissions.concat(['VIEWER']));

export const getModuleFromOrgData = (org: OrganizationType): MenuItemType[] => {
  const result: MenuItemType[] = [];

  if (org.modules) {
    Object.keys(org.modules).forEach((moduleKey: string) => {
      (org.modules[moduleKey].view_data ?? []).forEach((viewData: ViewDataType) => {
        if (hasViewPermission(String(org.id), viewData.valid_permissions)) {
          result.push({
            module: org.name,
            breadcrumb: viewData.breadcrumb,
            friendlyName: viewData.friendly_name,
            path: viewData.path,
            isVisible: viewData.is_visible,
            displayOrder: viewData.display_order ?? 0,
          });
        }
      });
    });
  }
  return result;
};

export const consolidateUserGrants = (payload: Partial<EditableUserFields>, orgId: string) => {
  const resultPayload: Partial<EditableUserFields> = { ...payload };
  if (resultPayload?.grants && resultPayload.grants[orgId]) {
    if (resultPayload.grants[orgId].includes('ORG_ADMIN')) {
      resultPayload.grants[orgId] = ['ORG_ADMIN'];
    } else if (resultPayload.grants[orgId].includes('ORG_OWNER')) {
      resultPayload.grants[orgId] = ['ORG_OWNER'];
    } else if (resultPayload.grants[orgId].includes('VIEWER')) {
      resultPayload.grants[orgId] = resultPayload.grants[orgId].filter(
        (grant) => !VIEWER_GRANTS.includes(grant as unknown as UserGrantMutable),
      );
    }
    resultPayload.grants[orgId].push('MEMBER');
    // drop duplicates
    resultPayload.grants[orgId] = [...(new Set(resultPayload.grants[orgId]))];
  }
  return resultPayload;
};
// eslint-disable-next-line arrow-body-style
export const getBreadCrumbFromOrg = (org: OrganizationType) => {
  return makeBreadCrumbMap(getModuleFromOrgData(org));
};

export const fetchUsers = async (orgId: number) => userService.get<UserType[]>('/users', {
  headers: headers(orgId),
  params: {
    organization_id: String(orgId),
  },
});

export const fetchUser = async (orgId: number, userId: string) => userService.get<UserType>(`/user/${userId}`, {
  headers: headers(orgId),
});

export const fetchViews = async (orgId: number, path: string) => userService.get(`/organization/${orgId}/view/?path=${encodeURIComponent(path)}`, {
  headers: headers(orgId),
});

export const createUser = async (
  orgId: number,
  payload: EditableUserFields,
) => userService.post(
  '/user/',
  consolidateUserGrants(payload, orgId.toString()),
  { headers: headers(orgId) },
);

const patchUser = async (
  userId: string,
  orgId: number,
  payload: UpdateUserPayload,
) => userService.patch<DecodedUserServiceToken>(
  `/user/${userId}`,
  payload,
  { headers: headers(orgId) },
);

export const updateUser = async (
  userId: string,
  orgId: number,
  payload: UpdateUserPayload,
) => patchUser(
  userId,
  orgId,
  consolidateUserGrants(payload, orgId.toString()),
);

export const deleteUser = async (
  userId: string,
  orgId: number,
) => patchUser(
  userId,
  orgId,
  {
    grants: {
      [orgId.toString()]: [],
    },
  },
);

export const forgotPassword = async (email: string) => userService.post('/user/forgot', { email });

export const regenerateTemporaryPassword = async (email: string): Promise<null> => userService.post('/user/temporary-password/', { email });

export const confirmForgotPassword = async (email: string, confirmationCode: string, password: string) => userService.post('/user/confirm', { email, password, confirmationCode });
