/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import './PermissionsSwitchBoard.css';
import { UserGrantMutable, GrantMapMutable } from '../../controllers/auth/types';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import {
  PermisionModule,
  PermisionSubModule,
  defaultPermisionsMap,
} from './defaultPermissionsMap';
import { fetchOrganizationSubscriptionsWithCaching } from '../../controllers/subscription-service';
import { AsyncState } from '../../utils/webRequests.type';
import LoadingPage from '../../pages/LoadingPage/LoadingPage';
import { OrganizationSubscription } from '../../controllers/subscription-service/types';
import { RoleSelection } from '../../controllers/user-service/types';
import { NO_PERM_GRANT_MAP } from '../../utils/constants';
import sendRumError from '../../utils/datadogRum';

type PermissionsSwitchBoardProps = {
  setPermissions: React.Dispatch<React.SetStateAction<GrantMapMutable>>,
  initialPermissions?: Partial<GrantMapMutable>,
  roleHook: RoleSelection,
  isAdmin?: boolean,
};

const getModuleGrants = (module: PermisionModule) => module.subModules.map((subModule) => subModule.grant);

function PermissionsSwitchBoard(props: PermissionsSwitchBoardProps) {
  const {
    setPermissions,
    initialPermissions,
    roleHook: role,
    isAdmin,
  } = props;

  const navigate = useNavigate();
  const [fetchSubscriptionStatus, setFetchSubscriptionStatus] = useState<AsyncState>('uninitialized');
  const [subscribedCollectionTypes, setSubscribedCollectionTypes] = useState<string[]>([]);
  const currentOrgId = useSelector(selectCurrentOrgId);
  const [enabledGrants, setEnabledGrants] = useState<GrantMapMutable>({
    ...NO_PERM_GRANT_MAP,
    ...initialPermissions,
  });
  const hasGrantAccess = (moduleGrants: UserGrantMutable[]) => moduleGrants.some((moduleGrant) => enabledGrants[moduleGrant] ?? false);

  useEffect(() => {
    switch (role) {
      case 'Owner':
        setEnabledGrants({
          ...NO_PERM_GRANT_MAP,
          ORG_OWNER: true,
        });
        break;
      case 'Admin':
        setEnabledGrants({
          ...NO_PERM_GRANT_MAP,
          ORG_ADMIN: true,
        });
        break;
      case 'Viewer':
        setEnabledGrants({
          ...enabledGrants,
          ORG_ADMIN: false,
          ORG_OWNER: false,
          VIEWER: true,
        });
        break;
      default:
        setEnabledGrants({
          ...enabledGrants,
          ORG_ADMIN: false,
          ORG_OWNER: false,
          VIEWER: false,
        });
    }
  }, [role]);

  useEffect(() => {
    // used to prevent high level components from erroring out due to unmounting
    // use isMounted flow sparringly
    let isMounted = true;
    fetchOrganizationSubscriptionsWithCaching(currentOrgId).then(({ data }) => {
      const curDateString = (new Date()).toISOString().substring(0, 10);

      const uniqueTypes = [...new Set((data as OrganizationSubscription[])
        .filter(
          (subscription: OrganizationSubscription) => (subscription.deactivated_at > curDateString),
        )
        .map((subscription: OrganizationSubscription) => subscription.collection_type))];

      if (isMounted) {
        setSubscribedCollectionTypes(uniqueTypes);
        setFetchSubscriptionStatus('completed');
      }
    })
      .catch((error) => {
        sendRumError(error);
        if (isMounted) {
          setFetchSubscriptionStatus('failed');
        }
      });
    return () => {
      isMounted = false;
    };
  }, [currentOrgId]);

  let displayedPermisionsMap: PermisionModule[] = defaultPermisionsMap.map((module) => ({
    ...module,
    subModules: module.subModules.filter(
      (subModule) => (
        subModule.subscriptionRequirement.length === 0
        || subModule.subscriptionRequirement.some((s) => subscribedCollectionTypes.includes(s))
      ),
    ),
  }));
  displayedPermisionsMap = displayedPermisionsMap.filter((module) => module.subModules.length > 0);

  useEffect(() => {
    if (fetchSubscriptionStatus === 'completed') {
      setPermissions(enabledGrants);
    }
  }, [enabledGrants, fetchSubscriptionStatus]);

  useEffect(() => {
    if (fetchSubscriptionStatus === 'failed') {
      navigate('/error');
    }
  }, [fetchSubscriptionStatus]);

  const handleSubModuleChange = (enabled: boolean, grant: UserGrantMutable) => {
    setEnabledGrants({ ...enabledGrants, [grant]: enabled });
  };

  const handleModuleChange = (enabled: boolean, module: PermisionModule) => {
    const moduleGrants = getModuleGrants(module);
    const newGrants: GrantMapMutable = { ...enabledGrants as unknown as GrantMapMutable };
    moduleGrants.forEach((grant) => {
      newGrants[grant] = enabled;
    });
    setEnabledGrants({ ...enabledGrants, ...newGrants });
  };

  if (fetchSubscriptionStatus !== 'completed') {
    return <LoadingPage />;
  }

  return (
    <div className="permission-switchboard-container">
      {
        displayedPermisionsMap.map((module) => {
          const isModuleChecked = module.subModules.every((subModule: PermisionSubModule) => hasGrantAccess([...subModule.allowedGrants, subModule.grant]));
          const text = (
            <div>
              <h3>{module.name}</h3>
              <p className="muted">{module.subText}</p>
            </div>
          );

          return (
            <div key={`module-${module.name}`} className="row">
              <Form.Check
                type="switch"
                id={`module-${module.name}-label`}
                label={text}
                onChange={(event) => handleModuleChange(event.currentTarget.checked, module)}
                disabled={isAdmin}
                checked={isModuleChecked || isAdmin}
              />
              <div key={`submodules-${module.name}-${Math.random()}`}>
                {
                  module.subModules.map((subModule) => {
                    const subModuleText = (
                      <div>
                        <h4>{subModule.name}</h4>
                        <p className="muted">{subModule.subText}</p>
                      </div>
                    );
                    return (
                      <div key={`submodule-${module.name}-${subModule.name}`} className="submodule-container col-10 offset-2">
                        <Form.Check
                          type="switch"
                          id={`submodule-${module.name}-${subModule.name}-label`}
                          label={subModuleText}
                          onChange={(event) => handleSubModuleChange(event.currentTarget.checked, subModule.grant)}
                          disabled={isAdmin}
                          checked={hasGrantAccess([...subModule.allowedGrants, subModule.grant]) || isAdmin}
                        />
                      </div>
                    );
                  })
                }
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

PermissionsSwitchBoard.defaultProps = {
  initialPermissions: NO_PERM_GRANT_MAP,
  isAdmin: false,
};

export default PermissionsSwitchBoard;
