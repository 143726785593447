/* eslint-disable react/jsx-no-useless-fragment */
import React, { } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import NotAuthorized from '../../pages/NotAuthorized/NotAuthorized';
import { hasGrants, isCognitoLoggedin } from '.';
import { AdminGrant, UserGrant } from './types';

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  requestedGrants: PropTypes.arrayOf(PropTypes.string),
  adminGrants: PropTypes.arrayOf(PropTypes.string),
};

type ProtectedRouteType = {
  children: React.ReactNode,
  title?: string
  requestedGrants: UserGrant[],
  adminGrants: AdminGrant[],
};

export default function ProtectedRoute(
  {
    children,
    title,
    requestedGrants,
    adminGrants,
  }: ProtectedRouteType,
) {
  const currentOrgId = useSelector(selectCurrentOrgId);
  const location = useLocation();

  if (title) {
    document.title = `Marshall Insights | ${title.trim()}`;
  }

  if (!isCognitoLoggedin()) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
        replace
      />
    );
  }
  return hasGrants(currentOrgId, requestedGrants, adminGrants)
    ? <>{children}</>
    : <NotAuthorized />;
}

ProtectedRoute.propTypes = propTypes;

ProtectedRoute.defaultProps = {
  requestedGrants: ['MEMBER'],
  adminGrants: ['MEMBER'],
  title: undefined,
};
