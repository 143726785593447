import React from 'react';
import './PublicCookiePolicy.css';
import { Container } from 'react-bootstrap';
import NavFooter from '../../components/navbar/NavFooter';

function PublicCookiePolicy() {
  document.title = 'Marshall Insights';

  return (
    <div id="public-cookie-policy-page" className="public-cookie-policy-page display-grid container justify-content-center">
      <Container
        className="row mt-5 w-100 text-left"
      >
        <h2>Cookies and Web Beacons</h2>

        <p>
          Like any other website, Marshall Insights uses &quot;cookies&quot;.
          These cookies are used to store information including
          visitors&apos; preferences, and the pages on the website that
          the visitor accessed or visited. The information is used
          to optimize the users&apos; experience by customizing our web
          page content based on visitors&apos; browser type and/or other
          information.
        </p>

        <p>
          Most interactive websites use cookies to let us retrieve the
          user&apos;s details for each visit. Cookies are used by our website
          to enable the functionality of certain areas to make it easier
          for people visiting our website. Some of our affiliate/advertising
          partners may also use cookies.
        </p>

        <p>
          By accessing Marshall Insights, you agreed to use cookies in
          agreement with the Marshall Associates, Inc.&apos;s Privacy Policy.
        </p>
      </Container>

      <NavFooter />
    </div>
  );
}

export default PublicCookiePolicy;
