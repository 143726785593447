import React, { useEffect, useState } from 'react';
import {
  Form, Container, Row, Button, Modal, Spinner, Alert,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import './bookADemoModal.css';
import { AsyncState } from '../../../utils/webRequests.type';
import { getTextColor } from '../../../utils/stringFormatting';
import { postBookDemo } from '../../../controllers/maintenance-service';
import { DemoRequestPayload } from '../../../controllers/maintenance-service/types';
import { phoneRegExp } from '../../../utils/validateRegex';
import sendRumError from '../../../utils/datadogRum';

const MAX_INFO_TEXT_LENTH = 1000;

const bookDemoInitialValues: DemoRequestPayload = {
  name: '',
  company: '',
  email: '',
  phone: '',
  additional_information: '',
};
const bookDemoValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'This name is too short')
    .max(50, 'Name is too long')
    .required('Required'),
  company: Yup.string()
    .min(2, 'Company name is too short')
    .max(50, 'Company name is too long')
    .required('Required'),
  email: Yup.string().email('This email is not valid')
    .required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'This phone number is not valid')
    .required('Required'),
  additional_information: Yup.string(),
});

function BookADemoModal(): JSX.Element {
  const [show, setShow] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<AsyncState>('uninitialized');

  let upLoadtext: React.ReactElement | null = null;

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const formik = useFormik({
    initialValues: bookDemoInitialValues,
    validationSchema: bookDemoValidationSchema,
    onReset: () => {
      setSubmitStatus('uninitialized');
    },
    onSubmit: (payload) => {
      if (!formik.isValid) {
        return;
      }
      setSubmitStatus('loading');
      postBookDemo(payload)
        // new Promise((resolve) => { resolve(undefined); })
        .then(
          () => {
            setShow(false);
          },
        ).catch(
          (e) => {
            sendRumError(e);
            setSubmitStatus('failed');
          },
        );
    },

  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);

  useEffect(() => {
    setSubmitStatus('uninitialized');
  }, [formik.values]);

  switch (submitStatus) {
    case 'loading':
      upLoadtext = (
        <Alert variant="light">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Working...</span>
          </Spinner>
        </Alert>
      );
      break;
    case 'failed':
      upLoadtext = (
        <Alert variant="danger" className="mt-2">
          <span>
            Failed to send this request. Please email support at&nbsp;
            <a className="ma-link" href="mailto:help@marshallinsights.com">
              help@marshallinsights.com
            </a>
            .
          </span>
        </Alert>
      );
      break;
    case 'completed':
    case 'uninitialized':
    default:
      upLoadtext = null;
      break;
  }

  return (
    <>
      <div onClick={handleShow} role="button" tabIndex={0} onBlurCapture={() => { }}>
        <span>
          <Button
            className="btn-action"
            id="openBookADemoModalButton"
            data-testid="openBookADemoModalButton"
            type="button"
            disabled={show}
          >
            Book a Demo
          </Button>
        </span>
      </div>
      <Modal className="book-a-demo-modal" show={show} onHide={handleClose} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Book a Demo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="book-a-demo-modal-content" data-testid="book-a-demo-modal">
            <Form id="book-a-demo-form" onSubmit={formik.handleSubmit}>
              <Row>
                <p>
                  Please enter your information, as well as anything
                  that you would like us to know before we schedule
                  a demonstration with you.
                </p>
              </Row>
              <Row className="bookADemoInputContainer">
                <Form.Group controlId="formName">
                  <Form.Label>
                    Name
                  </Form.Label>
                  <Form.Control
                    type="input"
                    className="pb-0"
                    placeholder="Your name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    isInvalid={formik.errors.name !== undefined && formik.submitCount > 0}
                  />
                  <Form.Control.Feedback className="small pt-0 mt-0" type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="bookADemoInputContainer">
                <Form.Group controlId="formCompany">
                  <Form.Label>
                    Company
                  </Form.Label>
                  <Form.Control
                    type="input"
                    className="pb-0"
                    placeholder="Your company's name"
                    name="company"
                    onChange={formik.handleChange}
                    value={formik.values.company}
                    isInvalid={formik.errors.company !== undefined && formik.submitCount > 0}
                  />
                  <Form.Control.Feedback className="small pt-0 mt-0" type="invalid">
                    {formik.errors.company}
                  </Form.Control.Feedback>

                </Form.Group>
              </Row>
              <Row className="bookADemoInputContainer">
                <Form.Group controlId="formEmail">
                  <Form.Label>
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    className="pb-0"
                    placeholder="Your email address"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    isInvalid={formik.errors.email !== undefined && formik.submitCount > 0}
                  />
                  <Form.Control.Feedback className="small pt-0 mt-0" type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>

                </Form.Group>
              </Row>
              <Row className="bookADemoInputContainer">
                <Form.Group controlId="formPhone">
                  <Form.Label>
                    Phone
                  </Form.Label>
                  <Form.Control
                    type="phone"
                    className="pb-0"
                    placeholder="Your Phone Number"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    isInvalid={formik.errors.phone !== undefined && formik.submitCount > 0}
                  />
                  <Form.Control.Feedback className="small pt-0 mt-0" type="invalid">
                    {formik.errors.phone}
                  </Form.Control.Feedback>

                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="formAddtionalInformation">
                  <Form.Label>
                    Additional Information
                  </Form.Label>
                  <Form.Control
                    data-testid="book-a-demo-modal-message-box"
                    className="book-a-demo-modal-message-box"
                    as="textarea"
                    placeholder="What else would you like us to know?"
                    name="additional_information"
                    maxLength={MAX_INFO_TEXT_LENTH}
                    onChange={formik.handleChange}
                    value={formik.values.additional_information}
                    rows={4}
                  />
                  <div className={`book-a-demo-entry-length-container text-end ${getTextColor(formik.values.additional_information.length, MAX_INFO_TEXT_LENTH)}`}>
                    {`${formik.values.additional_information.length}`}
                    /
                    {`${MAX_INFO_TEXT_LENTH}`}
                  </div>
                </Form.Group>
              </Row>
              {upLoadtext}
              <hr />
              <Container className="help-buttons">
                <Button
                  id="submit-help-button"
                  data-testid="help-modal-submit"
                  disabled={(
                    formik.isSubmitting
                  )}
                  type="submit"
                >
                  Send Request
                </Button>
                <Button variant="danger" onClick={handleClose}>Close</Button>
              </Container>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BookADemoModal;
