import React from 'react';
import {
  Button,
  Container,
  Modal,
  Row,
} from 'react-bootstrap';

type Props = {
  show?: boolean,
  noteText: string,
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  confirmDeleteCallBack: () => void,
  failConfirmationCallback: () => void,
};

export default function NotesDeleteConfirmation({
  show,
  noteText,
  setShow,
  confirmDeleteCallBack,
  failConfirmationCallback,
}: Props) {
  const handleConfirm: React.EventHandler<React.MouseEvent<HTMLButtonElement>> = () => {
    confirmDeleteCallBack();
    setShow(false);
  };

  const handleReject: React.EventHandler<React.MouseEvent<HTMLButtonElement>> = () => {
    failConfirmationCallback();
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        failConfirmationCallback();
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Note?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <p>Are you sure you want to delete this note?</p>
          </Row>
          <Row className="mx-1 bg-quicksight-grey rounded">
            <p className="text-muted text-truncate">
              {noteText}
            </p>
          </Row>
          <Row className="mt-3">
            <div className="col-6 d-flex justify-content-center">
              <Button variant="danger" className="w-50" onClick={handleConfirm}>
                Yes
              </Button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <Button className="ma-btn w-50" onClick={handleReject}>
                No
              </Button>
            </div>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>

  );
}

NotesDeleteConfirmation.defaultProps = {
  show: false,
};
