import React from 'react';
import './PublicFeatures.css';
import { Container } from 'react-bootstrap';
import NavFooter from '../../components/navbar/NavFooter';
import { publicFeatureGroups } from './PublicFeatureList';
import PageHeader from '../../components/PageHeader/PageHeader';

function PublicFeatures() {
  document.title = 'Marshall Insights | Features';

  return (
    <div id="public-features-page" className="public-features-page display-grid container justify-content-center">
      <PageHeader
        title="Features Available"
        returnText="Back to Home"
        returnLink="/home"
      />
      <hr />
      {
        publicFeatureGroups.map(
          (g) => (
            <div
              key={`${g.key}-block`}
              className="row mt-5 w-100"
            >
              <Container
                key={`${g.key}-group`}
                id={`${g.key}-group`}
                data-testid={`${g.key}-group`}
                className="col-6 col-offset-3"
              >
                <h3>
                  {g.label}
                </h3>
                {
                  g.features.map(
                    (f) => (
                      <div
                        key={f.key}
                      >
                        <b>
                          {f.label}
                        </b>
                        <ol>
                          {f.bullets.map(
                            (t, index) => (
                              <li
                                key={`${f.key}-${index}`}
                              >
                                {t}
                              </li>
                            ),
                          )}
                        </ol>
                      </div>
                    ),
                  )
                }
              </Container>
            </div>
          ),
        )
      }
      <hr />
      <NavFooter />
    </div>
  );
}

export default PublicFeatures;
