import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store'; // eslint-disable-line import/no-cycle
import {
  fetchOrganizationSubscriptionsWithCaching,
} from '../../controllers/subscription-service';
import { AsyncState } from '../../utils/webRequests.type';
import { OrganizationSubscription } from '../../controllers/subscription-service/types';

export type OrganizationSubscriptionsState = {
  status: AsyncState,
  data: OrganizationSubscription[],
  types: string[],
};

const initialState: OrganizationSubscriptionsState = {
  status: 'uninitialized',
  data: [],
  types: [],
};

export const updateOrganizationSubscriptions = createAsyncThunk(
  'subscriptions/fetchOrganizationSubscribedCollectionTypes',
  async (orgId: number) => {
    if (orgId < 1) {
      return [];
    }

    const response = await fetchOrganizationSubscriptionsWithCaching(
      orgId,
    );
    if (response.status >= 300) {
      throw Error('Unable to acquire subscription data');
    }
    return response.data;
  },
);

export const organizationSubscriptionsSlice = createSlice({
  name: 'organizationSubscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateOrganizationSubscriptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrganizationSubscriptions.fulfilled, (state, action) => {
        state.status = 'completed';
        state.data = action.payload ?? [];

        const curDateString = (new Date()).toISOString().substring(0, 10);
        const tempTypes: string[] = [];
        state.data.forEach((ele) => {
          if (!tempTypes.includes(ele.collection_type) && ele.deactivated_at > curDateString) {
            tempTypes.push(ele.collection_type);
          }
        });

        state.types = tempTypes;
      })
      .addCase(updateOrganizationSubscriptions.rejected, (state) => {
        state.status = 'failed';
        state.data = initialState.data;
        state.types = initialState.types;
      });
  },
});

// -- Commenting out: Not in use yet
// export const selectOrganizationSubscriptionsData = (
//   state: RootState,
// ) => state.organizationSubscriptions.data;
export const selectOrganizationSubscriptionsStatus = (
  state: RootState,
) => state.organizationSubscriptions.status;
export const selectOrganizationSubscriptionsTypes = (
  state: RootState,
) => state.organizationSubscriptions.types;

export default organizationSubscriptionsSlice.reducer;
