import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Settings.css';
import {
  Alert,
  Button,
  Form,
} from 'react-bootstrap';

import {
  changePassword,
  validatePassword,
  alerts,
  refreshSession,
} from '../../controllers/auth';
import { AlertType } from '../../controllers/auth/types';
import PasswordCriteria from '../../components/passwordCriteria/PasswordCriteria';
import { updateUser } from '../../controllers/user-service';
import { selectProfileData, selectProfileStatus, updateProfile } from '../../reducers/profile/profileSlice';
import PageHeader from '../../components/PageHeader/PageHeader';
import SnackBar from '../../components/snackBar/SnackBar';
import { AppDispatch } from '../../app/store';
import GlobalConfig from '../../app/config';
import sendRumError from '../../utils/datadogRum';

const prettyErrorMessage = (error: Error) => {
  switch (error.message) {
    case 'Incorrect username or password.':
      return 'Incorrect current password.';
    case 'User could not be retrieved':
      return 'Error updating your password. Please refresh and try again.';
    case 'User is not authenticated':
      return 'Incorrect current password.';
    default:
      if (typeof error.message === 'undefined') {
        return 'Unknown issue.  Please contact support.';
      }
      return `Please contact support: ${error.message}`;
  }
};

type UserNameState = {
  formFirstName: string,
  formLastName: string,
};

type ErrorObject = {
  show: boolean,
  errorMessage: string,
};

export default function Settings() {
  const user = useSelector(selectProfileData);
  const [passwordError, setPasswordError] = useState<AlertType | undefined>(undefined);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [proposedPassword, setProposedPassword] = useState<string>('');
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [errorMap, setError] = useState<ErrorObject>({ show: false, errorMessage: '' });
  const [nameMap, setName] = useState<UserNameState>({ formFirstName: user?.first_name || '', formLastName: user?.last_name || '' });
  const [updateNameDisabledState, setUpdateNameDisabledState] = useState<boolean>(true);
  const [updatePasswordDisabledState, setUpdatePasswordDisabledState] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();

  // TODO polish up with error handling and state / token updates
  /* istanbul ignore next */
  const handleNameChange = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userId = user?.user_id;
    const orgId = user?.default_organization_id;
    const payload = {
      first_name: nameMap.formFirstName,
      last_name: nameMap.formLastName,
    };
    if (!userId) {
      const userIdError = 'No user ID provided';
      setError({ show: true, errorMessage: userIdError });
    } else {
      updateUser(userId, orgId, payload)
        .then(() => {
          refreshSession()
            .then(() => {
              dispatch(updateProfile());
            });
        })
        .catch((error) => {
          sendRumError(error);
          const updateError = `Error updating user: ${error}`;
          setError({ show: true, errorMessage: updateError });
        });
    }
  };

  const userStatus = useSelector(selectProfileStatus);
  if (userStatus === 'uninitialized') {
    dispatch(updateProfile());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const passwordValidation = validatePassword(proposedPassword) as any[];
  const shortPasswordDetails = passwordValidation.map((detail) => detail.message);

  const handlePasswordChange = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (updatePasswordDisabledState) {
      setError({ show: true, errorMessage: 'Please re-check the password requirements.' });
      return;
    }
    changePassword(currentPassword, confirmedPassword)
      .then(() => {
        setPasswordError(alerts.passwordSuccess);
      })
      .catch((error) => {
        sendRumError(error);
        const cognitoPasswordAlert: AlertType = {
          msg: prettyErrorMessage(error),
          var: 'danger',
        };
        setPasswordError(cognitoPasswordAlert);
      });
    event.currentTarget.reset();
  };

  // trigger a PW submit button state check
  useEffect(() => {
    let buttonEnable = true;
    if (currentPassword.length === 0) {
      buttonEnable = false;
    }
    if (proposedPassword.length === 0
      || confirmedPassword.length === 0
      || proposedPassword !== confirmedPassword) {
      buttonEnable = false;
    }
    if (shortPasswordDetails.length > 0) {
      buttonEnable = false;
    }
    setUpdatePasswordDisabledState(!buttonEnable);
  }, [currentPassword, shortPasswordDetails, confirmedPassword]);

  const handleNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newName = {
      ...nameMap,
      [event.target.id]: event.target.value.trim(),
    };
    setName(newName);
    setUpdateNameDisabledState(newName.formFirstName.length === 0
      || newName.formLastName.length === 0);
  };

  return (
    <div className="page settings-page h-100">
      <PageHeader title="User Settings" returnText="Back to Home" returnLink="/" />
      <div className="PageContent row">
        <div className="h-100 col-4">
          <span className="col-100 flex">
            <strong className="ml-1">Email:</strong>
            <p className="text-a8-grey">
              {user?.email}
            </p>
          </span>
          <span className="col-100 flex">
            <strong className="ml-1">First Name:</strong>
            <p className="text-a8-grey">
              {user?.first_name}
            </p>
          </span>
          <span className="col-100 flex">
            <strong className="ml-1">Last Name:</strong>
            <p className="text-a8-grey">
              {user?.last_name}
            </p>
          </span>
        </div>
        <div className="settings-form-container col-4 h-100">
          <SnackBar
            style={{
              position: 'absolute', width: '50vw', zIndex: 1000,
            }}
            header="Error"
            alertText={errorMap.errorMessage}
            show={errorMap.show}
            onClose={() => setError({ show: false, errorMessage: '' })}
          />
          <div className="row">
            <Form onSubmit={handleNameChange}>
              <div className="col-12">
                <strong className="settings-form-title">Change User Information</strong>
                <p className="settings-form-subtitle">Update personal information</p>
                <hr />
              </div>
              <div className="col-11 offset-1">
                <Form.Group className="mb-3" controlId="formFirstName" data-testid="formFirstName">
                  <span className="flex">
                    <strong><Form.Label>First Name:</Form.Label></strong>
                    <p className="text-a8-grey">
                      {user?.first_name}
                    </p>
                  </span>
                  <Form.Control type="text" placeholder="Enter new first name" onChange={handleNameInput} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName" data-testid="formLastName">
                  <span className="flex">
                    <strong><Form.Label>Last Name:</Form.Label></strong>
                    <p className="text-a8-grey">
                      {user?.last_name}
                    </p>
                  </span>
                  <Form.Control type="text" placeholder="Enter new last name" onChange={handleNameInput} />
                </Form.Group>
                <Button
                  type="submit"
                  data-testid="submitNameChange"
                  disabled={updateNameDisabledState}
                >
                  Update Name
                </Button>
                <br />
                <br />
              </div>
            </Form>
          </div>
          <div className="row">
            <Form onSubmit={handlePasswordChange}>
              <div className="col-12">
                <strong className="settings-form-title">Change Password</strong>
                <p className="settings-form-subtitle">Update the password you use to access Marshall Insights</p>
                <hr />
              </div>
              <div className="col-11 offset-1">
                <Form.Group className="mb-3" controlId="formCurrentPassword">
                  <span className="flex">
                    <strong><Form.Label>Current Password:</Form.Label></strong>
                  </span>
                  <Form.Control
                    type="password"
                    name="currentPassword"
                    placeholder="Enter current password"
                    onChange={(event) => setCurrentPassword(event.currentTarget.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formNewPassword">
                  <span className="flex">
                    <strong><Form.Label>New Password:</Form.Label></strong>
                  </span>
                  <Form.Control
                    type="password"
                    name="proposedPassword"
                    placeholder="Enter new password"
                    onChange={(event) => setProposedPassword(event.currentTarget.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formConfirmPassword">
                  <Form.Control
                    type="password"
                    name="confirmedPassword"
                    placeholder="Confirm new password"
                    onChange={(event) => setConfirmedPassword(event.currentTarget.value)}
                  />
                </Form.Group>
                <PasswordCriteria
                  proposedPassword={proposedPassword}
                  confirmedPassword={confirmedPassword}
                />
                {
                  passwordError !== undefined
                  && <Alert variant={passwordError.var}>{passwordError.msg}</Alert>
                }
                <Button
                  type="submit"
                  disabled={updatePasswordDisabledState}
                >
                  Update Password
                </Button>
                <br />
                <br />
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div
        className="version-footer"
      >
        Version:&nbsp;
        {GlobalConfig.config.REACT_APP_VERSION}
      </div>
    </div>
  );
}
