import React from 'react';
import {
  Accordion, Button, Form,
} from 'react-bootstrap';
import { removeDuplicateStrings } from '../../utils/arrayTools';

import './TableFilters.css';

export type CheckFilters = {
  [name: string]: boolean;
};

export const valueArrayToCheckFilterMap = (array: string[]) => removeDuplicateStrings(array)
  .sort((a, b) => a.localeCompare(b))
  .reduce((a, v) => ({ ...a, [v]: v }), {});

export const setAllCheckFilters = (
  filterMap: CheckFilters,
  filterHook: React.Dispatch<React.SetStateAction<CheckFilters>>,
  check: boolean,
) => {
  const entries = Object.entries(filterMap).map((elem) => [elem[0], check]);
  const newFilter = Object.fromEntries(entries);
  filterHook(newFilter);
};

export const enableOnlyOneCheckFilters = (
  filterMap: CheckFilters,
  label: string,
  filterHook: React.Dispatch<React.SetStateAction<CheckFilters>>,
) => {
  const entries = Object.entries(filterMap).map((elem) => [elem[0], false]);
  const newFilter = Object.fromEntries(entries);
  newFilter[label] = true;
  filterHook(newFilter);
};

export const getCheckedFilterNames = (filters: CheckFilters) => Object.keys(
  filters,
).filter((filterName) => filters[filterName]).sort();

const calculateSelectAllState = (filters: CheckFilters) => {
  const filtersLength = Object.keys(filters).length;
  const selectedFiltersLength = getCheckedFilterNames(filters).length;
  if (filtersLength === selectedFiltersLength) return true;
  if (selectedFiltersLength === 0) return false;
  return null;
};

const selectAllStateClass = (s: boolean | null) => ((s === null) ? 'checkbox-grey-bg' : '');

// Purpose of function is to set / clear all values in a filter to true / false
const setCheckFilters = (filters: CheckFilters, desiredValue = true) => Object.keys(filters)
  .reduce(
    (prev: CheckFilters, cur: string) => ({
      ...prev,
      [cur]: desiredValue,
    }),
    {},
  );

export const displayFilterSelectors = (
  name: string,
  getData: () => CheckFilters,
  setFilterState: React.Dispatch<React.SetStateAction<CheckFilters>>,
  selectAllState: boolean | null,
  setSelectAllState: React.Dispatch<React.SetStateAction<boolean | null>> | null,
  Overlay: React.ElementType | null,
) => {
  const data = getData();
  const filterNames = Object.keys(data).sort(
    (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
  );
  const className = `table-filter-${name.toLowerCase().replace(/\s/g, '')}-dropdown`;
  return (
    <div>
      {
        (filterNames.length === 0 || (filterNames.length === 1 && filterNames[0] === '')) && Overlay
          ? (
            <Overlay name={name} />
          )
          : (
            <Accordion.Item
              eventKey={`${name}-filter-dropdown`}
              key={`${name}-filter-dropdown`}
              data-testid={className}
              className={className}
            >
              <Accordion.Header>{name}</Accordion.Header>
              <Accordion.Body>
                {setSelectAllState !== null && (
                  <Form.Check
                    type="checkbox"
                    key={`${name}-selectAll`}
                    id={`${name}-selectAll`}
                    data-testid={`${name}-selectAll`}
                    label="(Select All)"
                    className={selectAllStateClass(selectAllState)}
                    checked={selectAllState === null ? false : selectAllState}
                    onChange={() => {
                      const newSelectAllState = (
                        selectAllState === null ? false : !selectAllState
                      );
                      setSelectAllState(newSelectAllState);
                      setFilterState(setCheckFilters({ ...data }, newSelectAllState));
                    }}
                  />
                )}
                {
                  filterNames.map((checkedName: string) => (
                    <Form.Check
                      type="checkbox"
                      key={`${name}-${checkedName}`}
                      id={`${name}-${checkedName}`}
                      data-testid={`${name}-${checkedName}`}
                      label={checkedName === '' ? '(blank)' : checkedName}
                      checked={data[checkedName]}
                      onChange={() => {
                        const toggledFilters = {
                          ...data,
                          [checkedName]: !data[checkedName],
                        };
                        setFilterState(toggledFilters);
                        if (setSelectAllState !== null) {
                          setSelectAllState(calculateSelectAllState(toggledFilters));
                        }
                      }}
                    />
                  ))
                }
              </Accordion.Body>
            </Accordion.Item>
          )
      }
    </div>
  );
};

export function CheckFilterLabel(props:
{
  className?: string,
  text: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}) {
  const { className, text, onClick } = props;
  return (
    <div className="check-filter-label-container">
      <span className={className}>
        {text}
      </span>
      {
        onClick
          ? (
            <Button
              onClick={onClick}
              className="ma-link ma-btn-link check-filter-only-selector"
            >
              {' only'}
            </Button>
          )
          : null
      }
    </div>
  );
}

CheckFilterLabel.defaultProps = {
  className: '',
  onClick: undefined,
};
