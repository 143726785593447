import React from 'react';
import { Link } from 'react-router-dom';
import { AsyncState } from '../../utils/webRequests.type';

const seeAcceptedUrlFormats = (
  <Link
    to="/retailerUrlFormatting"
    className="ma-link"
    target="_blank"
  >
    See Accepted URL Formats
  </Link>
);

export const messageMap = (state: AsyncState, isItemAdmin: boolean) => {
  switch (state) {
    case 'loading':
      return 'Please wait: Your template is being generated...';
    case 'failed':
      return 'There was an issue downloading your template';
    default:
      return isItemAdmin ? seeAcceptedUrlFormats : null;
  }
};

export const colorClass: { [state in AsyncState]: string } = {
  loading: 'text-success',
  uninitialized: '',
  completed: '',
  failed: 'text-danger',
};

type DownloadFileStatusProps = {
  status: AsyncState,
  isItemAdmin?: boolean,
};

function DownloadFileStatus({ status, isItemAdmin = false }: DownloadFileStatusProps) {
  return (
    <div
      className={`downloadFileStatus ${colorClass[status]}`}
      data-testid="download-message-status-text"
    >
      {messageMap(status, isItemAdmin)}
    </div>
  );
}
DownloadFileStatus.defaultProps = {
  isItemAdmin: false,
};
export default DownloadFileStatus;
