import React from 'react';
import {
  NavDropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/*
This Component is a NavDropdown.Item skin,
with some handling of navigation / href behaviours
so that they don't conflict with each other
*/

const propTypes = {
  children: PropTypes.node.isRequired,
  'data-testid': PropTypes.string,
  'data-custom-name': PropTypes.string,
  navigateTo: PropTypes.string.isRequired,
};

type MINavDropdownItemProps = PropTypes.InferProps<typeof propTypes>;

function MINavDropdownItem({
  children,
  'data-testid': dataTestid,
  'data-custom-name': dataCustomName,
  navigateTo,
}: MINavDropdownItemProps) {
  const navigate = useNavigate();

  return (
    <NavDropdown.Item
      data-testid={dataTestid}
      data-custom-name={dataCustomName}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(navigateTo);
      }}
      href={navigateTo}
    >
      {children}
    </NavDropdown.Item>
  );
}

MINavDropdownItem.propTypes = propTypes;

MINavDropdownItem.defaultProps = {
  'data-testid': 'minavdropdown-blank-id',
  'data-custom-name': 'minavdropdown-no-name',
};

export default MINavDropdownItem;
