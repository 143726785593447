import React, { useEffect, useState } from 'react';
import {
  ListGroup,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  NavDropdown,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectOrganizationsData, selectOrganizationsStatus,
} from '../../../reducers/organizations/organizationsSlice';
import {
  selectCurrentOrgId,
  selectProfileData, setCurrentOrg, updateProfile,
} from '../../../reducers/profile/profileSlice';
import { updateUser } from '../../../controllers/user-service';
import { refreshSession } from '../../../controllers/auth';
import { AsyncState } from '../../../utils/webRequests.type';
import './orgModal.css';
import SearchInput from '../../SearchInput/SearchInput';
import { AppDispatch } from '../../../app/store';

export type DisplayOrgType = {
  name: string,
  id: number
};

function OrgModal() {
  const dispatch = useDispatch<AppDispatch>();
  const orgDataStatus = useSelector(selectOrganizationsStatus);
  const [listFilter, setListFilter] = useState<string>('');
  const [changingDefaultOrg, setchangingDefaultOrg] = useState<AsyncState>('uninitialized');
  const [show, setShow] = useState(false);

  const currentUser = useSelector(selectProfileData);
  // eslint-disable-next-line radix
  const authorizedOrgs = Object.keys(currentUser.grants).map((id) => parseInt(id, 10));
  const orgsList: DisplayOrgType[] = useSelector(selectOrganizationsData).map((org) => ({
    name: org.name,
    id: org.id,
  }))
    .filter((org) => authorizedOrgs.includes(org.id) || authorizedOrgs.includes(0));

  const currentOrgId = useSelector(selectCurrentOrgId);
  const navigate = useNavigate();
  const orgModalSortByName = (a: DisplayOrgType, b: DisplayOrgType) => {
    const an = a.name.toLowerCase();
    const bn = b.name.toLowerCase();
    return new Intl.Collator().compare(an, bn);
  };

  const defaultOrgId = currentUser?.default_organization_id;
  const updateDefaultOrgId = (orgId: number) => {
    setchangingDefaultOrg('loading');
    updateUser(currentUser?.user_id, orgId, {
      default_organization_id: orgId,
    })
      .then(refreshSession)
      .then(() => {
        dispatch(updateProfile());
        setchangingDefaultOrg('completed');
      });
  };

  useEffect(() => {
    const isValidOrg = orgsList.some((org) => org.id === currentOrgId);
    if (!isValidOrg && orgDataStatus === 'completed') {
      if (orgsList.length < 1) {
        throw Error('Failed to load orgs');
      }
    }
  }, [currentOrgId, orgsList]);

  const currentDisplayOrg = orgsList.find((org) => org.id === currentOrgId);

  const makeActiveItem = () => (
    <Row>
      <Col
        xs={8}
      >
        <span
          className={`ma-link org-modal-current-org-name ${(currentOrgId === defaultOrgId) ? 'default-org' : ''}`}
        >
          {
            changingDefaultOrg === 'loading' ? <Spinner animation="grow" size="sm" data-testid="loading-spinner" /> : currentDisplayOrg?.name
          }
        </span>
      </Col>
      <Col
        xs={4}
        onClick={(e) => {
          e.preventDefault();
          updateDefaultOrgId(currentOrgId);
        }}
      >
        {
          currentOrgId === defaultOrgId
            ? <span className="keep-almost-black">My Default Org</span>
            : (
              <span className="ma-link">
                Make Default
              </span>
            )
        }
      </Col>
    </Row>
  );

  const displayedOrgs = orgsList.filter(
    (org) => (
      (org.name.toLowerCase().includes(listFilter.toLowerCase())
        || org.id.toString() === listFilter)
      && org.id !== currentOrgId
    ),
  );

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <NavDropdown.Item
        data-testid="linkOrgModal"
        onClick={() => {
          setShow(true);
          setListFilter('');
        }}
        role="button"
        tabIndex={0}
      >
        <p>
          Select Organization
        </p>
        <p className="tooltip-color mb-0">
          View data for other organizations you have access to
        </p>
      </NavDropdown.Item>
      <Modal
        className="org-modal mt-5"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container data-testid="org-modal">
            <Row>
              <Col xs={12}>
                <Form className="d-flex">
                  <SearchInput
                    data-testid="org-search-input"
                    placeholder="Search organization"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setListFilter(e.currentTarget.value);
                    }}
                  />
                </Form>
              </Col>
            </Row>
            <Container className="org-option-list">
              <Row>
                <ListGroup variant="flush" className="mt-2">
                  <ListGroup.Item as="li">{makeActiveItem()}</ListGroup.Item>
                  {displayedOrgs.sort(orgModalSortByName).map((org) => (
                    <ListGroup.Item
                      as="a"
                      key={`listorg-${org.id}`}
                    >
                      <Row>
                        <Col
                          onClick={() => {
                            dispatch(setCurrentOrg(org.id));
                            navigate('/');
                            handleClose();
                          }}
                          xs={8}
                          className={`ma-link ${(org.id === defaultOrgId) ? 'default-org' : ''}`}

                        >
                          {org.name}
                        </Col>
                        <Col
                          xs={4}
                          onClick={() => {
                            updateDefaultOrgId(org.id);
                            handleClose();
                          }}
                        >
                          {
                            org.id === defaultOrgId
                              ? <span className="keep-almost-black">My Default Org</span>
                              : (
                                <span className="ma-link">
                                  Make Default
                                </span>
                              )
                          }
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Row>
            </Container>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrgModal;
