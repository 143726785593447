import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store'; // eslint-disable-line import/no-cycle
import { fetchNotifications } from '../../controllers/maintenance-service';
import { IssueType } from '../../controllers/maintenance-service/types';
import { AsyncState, ValidationError } from '../../utils/webRequests.type';

export type NotificationsState = {
  status: AsyncState,
  data: IssueType[],
};

const initialState: NotificationsState = {
  status: 'uninitialized',
  data: [],
};

export const updateNotifications = createAsyncThunk<IssueType[], number, { state: RootState }>(
  'notifications/getNotifications',
  async (orgId: number) => {
    if (orgId < 1) {
      return [];
    }
    const response = await fetchNotifications(orgId);
    if (response.data && !(response.data as ValidationError).detail) {
      return (response.data as IssueType[])
        .filter((issue) => issue.status === 'open');
    }
    throw Error(`Error loading issues. issues are malformed of type ${String(typeof response.data)}`);
  },
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(updateNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateNotifications.fulfilled, (state, action) => {
        state.status = 'completed';
        state.data = (action.payload != null) ? action.payload : [];
      })
      .addCase(updateNotifications.rejected, (state) => {
        state.status = 'failed';
        state.data = initialState.data;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNotificationsData = (state: RootState) => state.notifications.data;
export const selectNotificationsStatus = (state: RootState) => state.notifications.status;

export default notificationsSlice.reducer;
