import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { v4 as uuid4 } from 'uuid';
import { NavigateMessagePayload } from '../../utils/webRequests.type';
import SnackBar, { AlertTypes } from '../snackBar/SnackBar';
import './NavigationMessage.css';
import TimeDriftMessage from './TimeDriftMessage';

const alertHeaders: Record<AlertTypes, string> = {
  primary: '',
  secondary: '',
  success: '',
  danger: 'Error',
  warning: 'Warning',
  info: 'Info',
  dark: '',
  light: '',
};

type MessageQueueItem = NavigateMessagePayload & { id: string };

function NavigationMessage() {
  const location = useLocation();
  const [messageQueue, setMessageQueue] = useState<MessageQueueItem[]>([]);
  useEffect(() => {
    const hasMessage = !!(location.state && location.state?.message);
    if (hasMessage) {
      const newQueue = [
        {
          ...location.state.message,
          id: uuid4(),
        },
        ...messageQueue,
      ];
      setMessageQueue(uniqBy(newQueue, 'message'));
    }
  }, [location.state]);

  const removeFromQueue = (id: string) => {
    setMessageQueue((prevQueue) => prevQueue.filter((item) => item.id !== id));
  };

  const translateMessage = (message:React.ReactNode): React.ReactNode => {
    switch (message) {
      case 'TimeDriftMessage':
        return TimeDriftMessage;
      default:
        return message;
    }
  };

  return (
    <div className="MessageContainer position-absolute start-50 mt-2 translate-middle-x">
      {messageQueue.map(({ message, alertType, id }) => (
        <div key={id} className="d-flex justify-content-center">
          <SnackBar
            show
            alertText={translateMessage(message)}
            alertType={alertType}
            header={alertHeaders[alertType]}
            onClose={() => removeFromQueue(id)}
            style={{ zIndex: 1000, width: 'auto', minWidth: '40rem' }}
          />
        </div>
      ))}
    </div>
  );
}

export default NavigationMessage;
