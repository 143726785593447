/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Attention({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M15.999 3.472l-14.394 25.057h28.789l-14.395-25.057zM15.999 5.612l12.553 21.85h-25.105l12.552-21.85z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M15.466 13.601h1.066v7.464h-1.066v-7.464z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M15.998 22.664c-0.441 0-0.798 0.358-0.798 0.8 0 0.44 0.357 0.799 0.798 0.799 0.443 0 0.801-0.358 0.801-0.799 0-0.442-0.358-0.8-0.801-0.8z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Attention;
