import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';

function UntrackedProduct() {
  document.title = 'Marshall Insights | Untracked Product';
  return (
    <div className="page page-untracked-product">
      <PageHeader title="Untracked Product" returnText="Back to Home" returnLink="/" />
      <div className="error-message-container">
        <p>
          This product has not been added or has been added and is not tracked.
          <br />
          To add a new item or track an existing one, navigate to
          {' '}
          <Link
            className="ma-link"
            to="/itemAdmin"
            target="_blank"
            rel="noopener noreferrer"
          >
            Item Administration
          </Link>
          .
        </p>
        <p>
          Click
          {' '}
          <Link className="ma-link" to="/">here</Link>
          {' '}
          to go to the homepage.
        </p>
      </div>
    </div>
  );
}

export default UntrackedProduct;
