import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container, Button, Modal, Spinner,
} from 'react-bootstrap';
import { CellProps, Column } from 'react-table';
import { Bell } from '../../../icons';
import './notificationModal.css';

import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import {
  updateNotifications,
  selectNotificationsData,
  selectNotificationsStatus,
} from '../../../reducers/notifications/notificationSlice';

import Table from '../../Table/Table';

import { IssueType } from '../../../controllers/maintenance-service/types';
import { monitoredIssueDetailTypes } from '../../../utils/constants';
import { AppDispatch } from '../../../app/store';

function NotificationCell(props: CellProps<IssueType, string>) {
  const { row } = props;
  const issue = row.original;
  const { details } = issue;
  const retailerNameLabel = typeof details === 'object' && 'retailer_name' in details ? details.retailer_name : null;

  return (
    <span className="notification-message">
      <span className="dot bg-coral-red">&nbsp;</span>
      {
        (retailerNameLabel !== null)
          ? (
            <b>
              {retailerNameLabel}
              :&nbsp;
            </b>
          )
          : null
      }
      {issue?.message}
    </span>
  );
}

function NotificationModal(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const [show, setShow] = useState<boolean>(false);
  const currentOrgId = useSelector(selectCurrentOrgId);

  const columns: Column<IssueType>[] = [
    {
      Header: 'Notification',
      accessor: 'message',
      Cell: NotificationCell,
    },
  ];

  // Modal show and close
  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const issuesData = useSelector(selectNotificationsData).filter(
    (issue) => monitoredIssueDetailTypes.includes(issue.type),
  );
  const issuesStatus = useSelector(selectNotificationsStatus);

  useEffect(() => {
    if (Number(currentOrgId) > 0) {
      dispatch(updateNotifications(currentOrgId));
    }
  }, [currentOrgId]);

  return (
    <>
      <div onClick={handleShow} role="button" tabIndex={0}>
        <span>
          <Button className="black-background user-info-item" variant="dark">
            <Bell
              height="23"
              width="23"
            />
            <span className="notification-nav-label text-white" data-testid="notification-nav-label">Notifications</span>
            <div className={issuesData.length > 0 ? 'notification-count-container' : 'notification-count-container gizmo-grey'} data-testid="notification-count-container">
              {
                (issuesData.length > 99)
                  ? '99+'
                  : issuesData.length
              }
            </div>
          </Button>
        </span>
      </div>
      <Modal className="notifications-modal mt-5" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="notifications-modal-content" data-testid="notifications-modal">
            <p className="notification-instructions">
              A&nbsp;
              <span className="text-coral-red">RED</span>
              &nbsp;notification is associated with issues regarding data collection and can&apos;t
              be acknowledged.
            </p>
            <hr />
            {
              ['uninitialized', 'loading'].includes(issuesStatus)
                ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )
                : (
                  <div className="notifications-modal-table-container">
                    {
                      issuesStatus === 'failed'
                        ? (
                          <p>
                            There was an error loading your notifications.
                            Please confirm you have access to this view, then refresh.
                          </p>
                        )
                        : null
                    }
                    {
                      issuesData.length > 0
                        ? (
                          <Table
                            columns={columns}
                            data={[...issuesData]}
                            pagination={false}
                          />
                        )
                        : (issuesStatus !== 'failed') && (<p>There are no retailer collection or feature outages at this time.</p>)
                    }
                  </div>
                )
            }
            <hr />
            <Container className="notifications-buttons">
              <Button variant="danger" onClick={handleClose}>Close</Button>
            </Container>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NotificationModal;
