import { isAxiosError, AxiosError } from 'axios';
import { FastApiError } from './types';
import sendRumError from '../utils/datadogRum';

const noCacheHeaders = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
};

export const headers = (orgID: number | undefined = undefined, ignore_cache = true) => {
  let result: { [key: string]: string } = orgID
    ? {
      Authorization: `Bearer ${localStorage.getItem('user_service_token')}`,
      'MAI-ORGANIZATION-ID': String(orgID),
    }
    : {
      Authorization: `Bearer ${localStorage.getItem('user_service_token')}`,
    };

  if (ignore_cache) {
    result = {
      ...result,
      ...noCacheHeaders,
    };
  }
  return result;
};

export const parseFastAPIError = (error: Error | undefined) => {
  if (error && isAxiosError(error)) {
    const axiosError = error as AxiosError<FastApiError>;
    if (axiosError.response) {
      const { data: fastApiErrorData } = axiosError.response;
      try {
        return fastApiErrorData.detail.map(({ msg }) => msg);
      } catch (e) {
        sendRumError(e);
      }
    }
  }
  return '';
};

export default {
  headers,
  parseFastAPIError,
};
