import React from 'react';
import { useSelector } from 'react-redux';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import './Home.css';
import NotificationCard from '../../components/card/NotificationCard/NotificationCard';
import HomeItemsRequiringAttention from '../../components/card/homeItemsRequiringAttention/homeItemsRequiringAttention';
import HomeTrackedItemsOverview from '../../components/card/homeTrackedItemsOverview/homeTrackedItemsOverview';
import HomeTrackedByRetailer from '../../components/card/homeTrackedByRetailer/homeTrackedByRetailer';
import HomeTrackedByCategory from '../../components/card/homeTrackedByCategory/homeTrackedByCategory';

import { selectProfileStatus, selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import LoadingPage from '../LoadingPage/LoadingPage';

export default function Home() {
  const currentProfileStatus = useSelector(selectProfileStatus);
  const currentOrgId = useSelector(selectCurrentOrgId);

  return (
    <div
      id="home-page"
      className="page home-page h-100"
    >
      {(currentProfileStatus !== 'completed' || currentOrgId < 1)
        ? (
          <LoadingPage />
        )
        : (
          <>
            <Container className="notificationBlock">
              <Row><b>Notifications</b></Row>
              <Row className="g-1 gy-5">
                <NotificationCard />
              </Row>
            </Container>
            <Container className="productsBlock">
              <Row><b>Tracked Item Overview</b></Row>
              <Row className="g-2 gx-5 gy-5">
                <Col className="col-6">
                  <HomeTrackedItemsOverview />
                </Col>
                <Col className="col-6">
                  <HomeItemsRequiringAttention />
                </Col>
                <Col className="col-6">
                  <HomeTrackedByRetailer />
                </Col>
                <Col className="col-6">
                  <HomeTrackedByCategory />
                </Col>
              </Row>
            </Container>
          </>
        )}
    </div>
  );
}
