/* eslint-disable react/function-component-definition */
import React, { FC } from 'react';
import './TablePagination.scss';

const PaginationBlankSpace: FC = () => (
  <div
    className="paginationBlankSpace"
  >
    ...
  </div>
);

export default PaginationBlankSpace;
