import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Form,
  Accordion,
  OverlayTrigger,
} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import {
  selectCurrentOrgId,
} from '../../reducers/profile/profileSlice';
import { selectCurrentOrganization } from '../../reducers/organizations/organizationsSlice';
import PageHeader from '../../components/PageHeader/PageHeader';
import Table, {
  AccessorTip,
} from '../../components/Table/Table';
import {
  fetchProducts,
  getPortfolioFileWithBody,
  fetchProductFacets,
} from '../../controllers/product-service';
import {
  PortfolioFilter,
  ProductFacets,
  ProductVisibleColumnsResponse,
  ProductsGroupByResult,
} from '../../controllers/product-service/types';
import { CheckFilters, displayFilterSelectors, getCheckedFilterNames } from '../../components/Table/TableFilters';
import { Download, Info } from '../../icons';
import './ItemAdmin.css';
import { UploadItemsModal } from '../../components/modal';
import SearchInput from '../../components/SearchInput/SearchInput';
import { AsyncLazyState, AsyncState } from '../../utils/webRequests.type';
import LoadingPage from '../LoadingPage/LoadingPage';
import DownloadFileStatus from '../../components/downloadFileStatus/downloadFileStatus';
import {
  AccessorTipStatus,
  AccessorTipWithLink,
} from './TableOverlays';
import sendRumError from '../../utils/datadogRum';

const columns: Column<ProductVisibleColumnsResponse>[] = [
  {
    Header: 'Retailer',
    accessor: ({ retailer_name }) => <AccessorTip value={retailer_name} />,
  },
  {
    Header: 'Retailer SKU',
    accessor: (
      {
        retailer_sku,
        product_url,
      },
    ) => <AccessorTipWithLink value={retailer_sku} link={product_url} />,
  },
  {
    Header: 'Manufacturer SKU',
    accessor: ({ manufacturer_sku }) => <AccessorTip value={manufacturer_sku} />,
  },
  {
    Header: 'Brand',
    accessor: ({ brand }) => <AccessorTip value={brand} />,
  },
  {
    Header: 'Category',
    accessor: ({ category }) => <AccessorTip value={category || ''} />,
  },
  {
    Header: 'Model Name',
    accessor: ({ model_name }) => <AccessorTip value={model_name} />,
  },
  {
    Header: 'Tracked',
    accessor: ({ status }) => <AccessorTipStatus value={status} />,
  },
];

function MissingFilterOverlay(props: { name: string }) {
  const { name } = props;
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button className="accordion-button collapsed mock-accordion" type="button">
          <span>
            {`${name}: Not in use`}
            &nbsp;
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                (
                  <Tooltip
                    id={`unfilled-values-tooltip-${name}`}
                    data-testid={`unfilled-values-tooltip-${name}`}
                  >
                    <div>
                      To update your product information:
                      <ol>
                        <li>
                          Click&nbsp;
                          <span className="text-blushing-salmon">Add/Edit Items</span>
                          .
                        </li>
                        <li>
                          Update the values.
                        </li>
                        <li>
                          And click&nbsp;
                          <span className="text-blushing-salmon">Upload Item Changes</span>
                          .
                        </li>
                      </ol>
                    </div>
                  </Tooltip>
                )
              }
            >
              <i
                className="text-gizmo-grey"
                data-testid={`unfilled-values-overlay-${name}`}
              >
                <Info
                  height="20"
                  width="20"
                />
              </i>
            </OverlayTrigger>
          </span>
        </button>
      </h2>
    </div>
  );
}

export default function ItemAdmin() {
  const [products, setProducts] = useState<ProductVisibleColumnsResponse[]>([]);
  const [apiError, setApiError] = useState<boolean>(false);
  const [productStatus, setProductStatus] = useState<AsyncLazyState>('uninitialized');
  // filters
  const [searchTerm, setSearchTerm] = useState('');
  const [retailers, setRetailers] = useState<CheckFilters>({});
  const [categories, setCategories] = useState<CheckFilters>({});
  const [subCategories, setSubCategories] = useState<CheckFilters>({});
  const [brands, setBrands] = useState<CheckFilters>({});
  const [tags, setTags] = useState<CheckFilters>({});
  const [track, setTrack] = useState<CheckFilters>({});
  const [validUrl, setValidUrl] = useState<CheckFilters>({});
  // SelectAll States
  const [retailerSelectAll, setRetailerSelectAll] = useState<boolean | null>(true);
  const [categorySelectAll, setCategorySelectAll] = useState<boolean | null>(true);
  const [subCategorySelectAll, setSubCatgorySelectAll] = useState<boolean | null>(true);
  const [brandSelectAll, setBrandSelectAll] = useState<boolean | null>(true);
  const [tagSelectAll, setTagSelectAll] = useState<boolean | null>(true);

  const [downloadPortfolioStatus, setDownloadPortfolioStatus] = useState<AsyncState>('uninitialized');

  const orgName = useSelector(selectCurrentOrganization)?.name.toLowerCase().replace(/[^a-z0-9]/gmi, ' ').replace(/\s+/g, '-');
  const currentOrgId = useSelector(selectCurrentOrgId);

  const [searchParams, setSearchParams] = useSearchParams();

  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  // fire once on load..
  useEffect(() => {
    if (searchParams.get('search_term')) {
      setSearchTerm((searchParams.get('search_term') ?? '').trim().toLowerCase());
    }
    // not the smartest.. if there is 1 and only 1 option.. then this should not run
    // but then again, if there is 1 and only 1 option.. the user probably does not care
    if (searchParams.get('brand')) {
      setBrandSelectAll(false);
    }
    if (searchParams.get('retailer')) {
      setRetailerSelectAll(false);
    }
    if (searchParams.get('category')) {
      setCategorySelectAll(false);
    }
    if (searchParams.get('subcategory')) {
      setSubCatgorySelectAll(false);
    }
    if (searchParams.get('tag')) {
      setTagSelectAll(false);
    }
  }, [searchParams]);

  // If there is at least 1 value that is selected.. and one that is not
  // then return a list of the selected values
  // otherwise it is trivial, and return an empty list.
  const generateValueListIfNotTrivial = (filter: CheckFilters) => {
    const valueList: string[] = [];
    let foundSelected = false;
    let foundUnSelected = false;
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        valueList.push(key.trim());
        foundSelected = true;
      } else {
        foundUnSelected = true;
      }
    });
    return (foundSelected && foundUnSelected) ? valueList : [];
  };

  // generates filter body for the POST /portfolio-file/filtered/ request
  const generateFilterBody = () => {
    const filterBody: { [key: string]: string | string[] } = {};
    if (lowerCaseSearchTerm && lowerCaseSearchTerm.trim().length > 0) {
      filterBody.search_term = lowerCaseSearchTerm.trim();
    }

    const filterKeys = {
      retailer_names: retailers,
      brands,
      categories,
      subcategories: subCategories,
      tags,
    };
    Object.entries(filterKeys).forEach(([key, value]) => {
      const valueList = generateValueListIfNotTrivial(value);
      if (valueList.length > 0) {
        filterBody[key] = valueList;
      }
    });

    // status list requires a little logic to populate correctly
    const statusConditions = [
      { condition: track.No, status: 'disabled' },
      { condition: track.Yes && validUrl.No, status: 'requires attention' },
      { condition: track.Yes && validUrl.Yes, status: 'active' },
    ];

    const statusList = statusConditions
      .filter((item) => item.condition)
      .map((item) => item.status);

    if (statusList.length > 0 && statusList.length < 3) {
      filterBody.statuses = statusList;
    }

    return filterBody;
  };

  const failedLoad = () => {
    setProductStatus('failed');
    setApiError(true);
    setProducts([]);
    setBrands({});
    setCategories({});
    setSubCategories({});
    setTags({});
    setTrack({});
    setValidUrl({});
  };

  const loadFilter = (
    data: ProductsGroupByResult[],
    queryParameterKey = '',
    totalProductCount = -1,
  ) => {
    let queryParam = '';
    if (queryParameterKey) {
      queryParam = (searchParams.get(queryParameterKey) ?? '').trim();
    }

    const result: CheckFilters = {};
    let productCount = 0;

    // not using a reduce function as we have to do some funny stuff for "blank"
    data.forEach((row: ProductsGroupByResult) => {
      if (row.selector && row.selector.trim()) {
        result[row.selector.trim()] = !queryParam || (queryParam === row.selector);
      } else {
        result[''] = !queryParam;
      }
      productCount += row.count;
    });

    // insert a blank if
    //   - we have gaps (nulls exist)
    //   - and the requested count is positive (don't spawn for tags)
    if (totalProductCount > 0 && productCount < totalProductCount) {
      result[''] = !queryParam;
    }

    return result;
  };

  useEffect(() => {
    if (currentOrgId) {
      setProductStatus('loading');

      // load filters
      const facetsPromise = fetchProductFacets(currentOrgId)
        .then(async (response) => {
          const productFacets = response.data as ProductFacets;

          let requestedStatus = '';
          if (searchParams.get('status')) {
            requestedStatus = (searchParams.get('status') ?? '').trim();
          }

          setRetailers(loadFilter(productFacets.filter_dict.retailers, 'retailer', productFacets.total_product_count));
          setBrands(loadFilter(productFacets.filter_dict.brands, 'brand', productFacets.total_product_count));
          setCategories(loadFilter(productFacets.filter_dict.categories, 'category', productFacets.total_product_count));
          setSubCategories(loadFilter(productFacets.filter_dict.subcategories, 'subcategory', productFacets.total_product_count));
          setTags(loadFilter(productFacets.filter_dict.tags, 'tag'));

          // set default Track / Valid url filters.. if requested via URI
          if (requestedStatus > '') {
            setTrack({
              Yes: ['active', 'requires attention'].includes(requestedStatus),
              No: ['disabled'].includes(requestedStatus),
            });
            setValidUrl({
              Yes: ['active', 'disabled'].includes(requestedStatus),
              No: ['requires attention', 'disabled'].includes(requestedStatus),
            });
          } else {
            setTrack({
              Yes: true,
              No: true,
            });
            setValidUrl({
              Yes: true,
              No: true,
            });
          }
        });

      const productsPromise = fetchProducts(
        currentOrgId,
        {
          org_id: `${currentOrgId}`,
          include_retailer: 'true',
          visible_columns_only: 'true',
        },
      )
        .then(async (response) => {
          setProducts(response.data as ProductVisibleColumnsResponse[]);
        });

      Promise.all([
        productsPromise,
        facetsPromise,
      ])
        .then(() => {
          setProductStatus('completed');
        })
        .catch((error) => {
          sendRumError(error);
          failedLoad();
        });
    }
  }, [currentOrgId]);

  // Memoizing to speed things up (and reduce the number of unnecessary refreshes)
  const checkedRetailerNames = useMemo(() => getCheckedFilterNames(retailers), [retailers]);
  const checkedBrandNames = useMemo(() => getCheckedFilterNames(brands), [brands]);
  const checkedCategoryNames = useMemo(() => getCheckedFilterNames(categories), [categories]);
  const checkedSubCategoryNames = useMemo(
    () => getCheckedFilterNames(subCategories),
    [subCategories],
  );
  const checkedTagNames = useMemo(() => getCheckedFilterNames(tags), [tags]);
  const checkedTrackNames = useMemo(() => getCheckedFilterNames(track), [track]);
  const checkedValidNames = useMemo(() => getCheckedFilterNames(validUrl), [validUrl]);

  const filterByTag = Object.values(tags).some((tag) => tag === false);

  const filteredTableData = useMemo(
    () => products.filter(
      (product) => (
        (
          product.manufacturer_sku.toLowerCase().includes(lowerCaseSearchTerm)
          || (product.retailer_sku ?? '').toLowerCase().includes(lowerCaseSearchTerm)
          || lowerCaseSearchTerm === ''
        )
        && checkedBrandNames.includes(product.brand)
        && (
          (checkedRetailerNames.includes(product.retailer_name))
        )
        && checkedCategoryNames.includes(product.category || '')
        && checkedSubCategoryNames.includes(product.subcategory || '')
        && (
          !filterByTag
          || product.tags.some((tag) => checkedTagNames.includes(tag))
          || (product.tags.length === 0 && checkedTagNames.includes(''))
        )
        && (
          (checkedTrackNames.includes('Yes') && product.status !== 'disabled')
          || (checkedTrackNames.includes('No') && product.status === 'disabled')
        )
        && (
          (checkedValidNames.includes('Yes') && product.status !== 'requires attention')
          || (checkedValidNames.includes('No') && product.status === 'requires attention')
        )
      ),
    ),
    [
      products,
      lowerCaseSearchTerm,
      checkedBrandNames,
      checkedRetailerNames,
      checkedCategoryNames,
      checkedSubCategoryNames,
      filterByTag,
      checkedTagNames,
      checkedTrackNames,
      checkedValidNames,
    ],
  );

  const pagination = {
    pageSize: 10,
  };

  // move down after the Memo, so that use memo count is constant
  if (['loading', 'uninitialized'].includes(productStatus)) {
    return <LoadingPage displayText="Loading Products" displayEllipsis />;
  }

  const downloadPortfolioFile = () => {
    // generates the BODY of the request (the filter)
    // will be an empty dictionary if nothing is to be filtered.
    const filterBody = generateFilterBody();
    setDownloadPortfolioStatus('loading');
    getPortfolioFileWithBody(
      currentOrgId as number,
      filterBody as PortfolioFilter,
      filteredTableData.length === 0,
    )
      .then(({ data }) => {
        const dateNow = new Date();
        const timeStamp = `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}-${dateNow.getHours()}-${dateNow.getMinutes()}-${dateNow.getSeconds()}`;
        const filename = `portfolio-file-${orgName}-${timeStamp}.xlsx`;

        const portfolioFile = new File(
          [data],
          filename,
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        );
        const downloadUrl = window.URL.createObjectURL(portfolioFile);
        const link = document.createElement('a');
        link.download = filename;
        link.href = downloadUrl;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        setDownloadPortfolioStatus('completed');
      })
      .catch((error) => {
        sendRumError(error);
        setDownloadPortfolioStatus('failed');
      });
  };

  return (
    <div id="item-administration-page" className="page h-100">
      <PageHeader title="Manage Items" returnText="Back to Home" returnLink="/">
        <UploadItemsModal />
        <Button
          className="add-edit-button btn"
          onClick={downloadPortfolioFile}
        >
          Add/Edit Items
          <Download />
        </Button>
        <DownloadFileStatus status={downloadPortfolioStatus} isItemAdmin />
        <div
          className="text-white stealth-text"
          data-testid="product-status"
        >
          {productStatus}
        </div>
      </PageHeader>
      <div className="row gx-5">
        <div
          className="col-3 filter-pane"
        >
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-3">
              <span className="flex">
                <strong><Form.Label>Search Products</Form.Label></strong>
              </span>
              <SearchInput
                data-testid="item-search-input"
                placeholder="Search SKU"
                disabled={productStatus !== 'completed'}
                className="item-search-input"
                value={searchTerm}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (searchParams.get('search_term')) {
                    setSearchParams({});
                  }
                  setSearchTerm(e.currentTarget.value);
                }}
              />
            </Form.Group>
            <div
              className="accordion-container"
            >
              <Accordion defaultActiveKey={['Retailer-filter-dropdown']} alwaysOpen>
                {displayFilterSelectors('Retailer', () => retailers, setRetailers, retailerSelectAll, setRetailerSelectAll, MissingFilterOverlay)}
                {displayFilterSelectors('Brand', () => brands, setBrands, brandSelectAll, setBrandSelectAll, MissingFilterOverlay)}
                {displayFilterSelectors('Category', () => categories, setCategories, categorySelectAll, setCategorySelectAll, MissingFilterOverlay)}
                {displayFilterSelectors('Subcategory', () => subCategories, setSubCategories, subCategorySelectAll, setSubCatgorySelectAll, MissingFilterOverlay)}
                {displayFilterSelectors('Tags', () => tags, setTags, tagSelectAll, setTagSelectAll, MissingFilterOverlay)}
                {displayFilterSelectors('Tracked', () => track, setTrack, null, null, MissingFilterOverlay)}
                {displayFilterSelectors('Valid URL', () => validUrl, setValidUrl, null, null, MissingFilterOverlay)}
              </Accordion>
            </div>
          </Form>
        </div>
        <div className="col-9 h-100 container">
          {
            apiError
              ? (
                <p>
                  There was an error loading products.
                  Please make sure you have the access to this organization and page, then refresh
                </p>
              )
              : null
          }
          {
            (
              filteredTableData.length > 0
              && !apiError
            )
              ? <Table columns={columns} data={filteredTableData} pagination={pagination} />
              : !apiError && (<p>There are no products matching your filters.</p>)
          }
        </div>
      </div>
    </div>
  );
}
