/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Graph3({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M28.262 14.401v12.795h-5.331v-23.457h-1.066v23.457h-5.331v-15.994h-1.066v15.994h-5.331v-6.398h-1.066v6.398h-5.327v-9.596h-1.066v9.596h-0.005v1.066h26.656v-13.861z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Graph3;
