import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordCriteria from '../../components/passwordCriteria/PasswordCriteria';
import SnackBar, { AlertTypes } from '../../components/snackBar/SnackBar';
import { confirmForgotPassword } from '../../controllers/user-service';
import { validatePassword } from '../../controllers/auth';
import validateEmail from '../../utils/validateEmail';
import NotFound from '../NotFound/NotFound';
import PageHeader from '../../components/PageHeader/PageHeader';
import { AsyncState } from '../../utils/webRequests.type';
import './Login.css';
import sendRumError from '../../utils/datadogRum';

function Reset() {
  document.title = 'Marshall Insights | Reset Password';
  const [searchParams] = useSearchParams();
  const confirmationCode = searchParams.get('code') || '';
  const [proposedPassword, setProposedPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [submitDisabledState, setSubmitDisabledState] = useState<boolean>(true);
  const [confirmPasswordState, setConfirmPasswordState] = useState<AsyncState>('uninitialized');
  const [messageMap, setMessageMap] = useState({ show: false, alertType: 'danger', alertText: '' });
  const navigate = useNavigate();
  const resetValues = () => {
    setProposedPassword('');
    setConfirmedPassword('');
    setUserEmail('');
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    resetValues();
    setConfirmPasswordState('loading');
    confirmForgotPassword(userEmail, confirmationCode, proposedPassword)
      .then(() => {
        setConfirmPasswordState('completed');
        setMessageMap({ show: true, alertText: 'Sucessfully Reset Password. You will be redirected shortly', alertType: 'success' });
        setTimeout(() => {
          navigate('/', { replace: true });
        }, 3000);
      })
      .catch((error) => {
        sendRumError(error);
        setConfirmPasswordState('failed');
        setMessageMap({ show: true, alertText: error.message, alertType: 'danger' });
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const passwordValidation = validatePassword(proposedPassword) as any[];

  useEffect(() => {
    let buttonEnable = true;

    // if userEmail is not a valid email address
    if (!validateEmail(userEmail)) {
      buttonEnable = false;
    }

    // Password checks:
    if (proposedPassword.length === 0
      || confirmedPassword.length === 0
      || proposedPassword !== confirmedPassword) {
      buttonEnable = false;
    }
    if (passwordValidation.length > 0) {
      buttonEnable = false;
    }

    setSubmitDisabledState(!buttonEnable);
  }, [passwordValidation, confirmedPassword, userEmail]);

  if (!confirmationCode) {
    return (
      <NotFound />
    );
  }

  return (
    <div className="page reset-page h-100 col-6 container justify-content-center">
      <PageHeader
        title="Change Password"
        titleWidth="col-12"
      />
      <Form className="align-self-center" onSubmit={handleSubmit}>
        <p>Reset your password to access Marshall Insights.</p>
        <hr />
        <Form.Group className="mb-3" controlId="formEmail">
          <span className="flex">
            <strong><Form.Label>Your Email:</Form.Label></strong>
          </span>
          <Form.Control
            type="email"
            name="userEmail"
            placeholder="Enter your email"
            onChange={(event) => setUserEmail(event.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formProposedPassword">
          <span className="flex">
            <strong><Form.Label>New Password:</Form.Label></strong>
          </span>
          <Form.Control
            type="password"
            name="proposedPassword"
            placeholder="Enter new password"
            onChange={(event) => setProposedPassword(event.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formConfirmedPassword">
          <span className="flex">
            <strong><Form.Label>Confirm Password:</Form.Label></strong>
          </span>
          <Form.Control
            type="password"
            name="confirmedPassword"
            placeholder="Confirm new password"
            onChange={(event) => setConfirmedPassword(event.currentTarget.value)}
          />
        </Form.Group>
        <PasswordCriteria
          proposedPassword={proposedPassword}
          confirmedPassword={confirmedPassword}
        />
        <SnackBar
          show={messageMap.show}
          alertText={messageMap.alertText}
          alertType={messageMap.alertType as AlertTypes}
          onClose={() => setMessageMap({ show: false, alertText: '', alertType: 'danger' })}
          header={messageMap.alertType === 'danger' ? 'Error' : 'Success'}
          style={{
            position: 'absolute',
            width: '40vw',
            zIndex: 1000,
            top: '10rem',
          }}
        />
        <div className="justify-content-md-center text-center w-100">
          <Button
            type="submit"
            variant="primary"
            disabled={submitDisabledState || ['loading', 'completed'].includes(confirmPasswordState)}
          >
            {confirmPasswordState === 'loading' ? <Spinner data-testid="login-spinner" size="sm" animation="grow" /> : 'Update Password'}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Reset;
