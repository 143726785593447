import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { HelpLinkButton } from '../modal/helpModal/helpModal';
import { Trash } from '../../icons';

const DCAInstructionsWalmartConnectAdvertising: JSX.Element = (
  <Container
    className="border border-info rounded mb-3"
    data-testid="dca-instructions-walmart-connect-advertising"
  >
    <Row>
      <h3
        className="text-capitalize mt-2"
      >
        How to add an Advertiser account from Walmart Connect Advertising
      </h3>
    </Row>
    <Row>
      <Container>
        <b>
          Note:
        </b>
        <ul>
          <li>
            Walmart Connect Advertiser accounts already in the Insights application cannot be
            added more than once.
          </li>
          <li>
            To add an Advertiser account in Walmart Connect Advertising to Insights, you must have
            Admin privileges for the Advertiser account in Walmart Connect Advertising.
          </li>
        </ul>
      </Container>
    </Row>
    <Row className="ps-3 pad-child-list-elements">
      <ol>
        <li>
          <b>
            Sign into Walmart Connect Advertising and navigate to the Admin Management page:
          </b>
          <ul>
            <li>
              Use a separate browser tab to sign in to Walmart Connect Advertising.
            </li>
            <li>
              On the upper right, click on&nbsp;
              <b>
                Account
              </b>
              &nbsp;and choose&nbsp;
              <b>
                Admin
              </b>
              .
            </li>
            <li>
              On the Admin page, Navigate to the&nbsp;
              <b>
                API Partner - Advertiser level
              </b>
              &nbsp;section.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Add an API Partner:
          </b>
          <ul>
            <li>
              Click on the&nbsp;
              <b>
                + Add Partner
              </b>
              &nbsp; button on the lower right hand corner of the page.
            </li>
            <li>
              In the&nbsp;
              <b>
                API Partner name
              </b>
              &nbsp;dropdown, scroll and select&nbsp;
              <b>
                Marshall Insights
              </b>
              .
            </li>
            <li>
              Set the level of access to be&nbsp;
              <b>
                Read access
              </b>
              .
            </li>
            <li>
              Click on the&nbsp;
              <b>
                Add partner
              </b>
              &nbsp;button on the right.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Get the Advertiser ID:
          </b>
          <ul>
            <li>
              Scroll to the top of the&nbsp;
              <b>
                Admin
              </b>
              &nbsp;page on the Walmart Connect Advertising site.
            </li>
            <li>
              Locate the&nbsp;
              <b>
                Advertiser ID
              </b>
              &nbsp;and copy it to your clipboard.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Paste in the Advertiser ID below:
          </b>
          <ul>
            <li>
              Place it in the&nbsp;
              <b>
                Advertiser ID
              </b>
              &nbsp;field in this modal.  This value should be a number.
            </li>
            <li>
              Click the&nbsp;
              <span className="badge bg-black">
                Add
              </span>
              &nbsp;button below.
            </li>
          </ul>
        </li>
        <li>
          <b>
            Review the authorized Walmart Connect Advertising accounts:
          </b>
          <ul>
            <li>
              Check that all authorized accounts appear in this interface.
            </li>
            <li>
              To remove any account, click the trash icon (
              <Trash fill="var(--blushing-salmon)" />
              ) next to it.
            </li>
            <li>
              If any accounts are missing, repeat the above steps to add them.
            </li>
          </ul>
        </li>
      </ol>
    </Row>
    <Row>
      <b>
        Troubleshooting:
      </b>
      <p>
        If you encounter any issues or have questions, contact
        <HelpLinkButton />
        .
      </p>
    </Row>
  </Container>
);

/* Instructions for re-authorization: in storage until we're ready to deploy it
    <Row>
      <b>
        How to re-authorize access to Criteo:
      </b>
      <br />
      <p>
        <ul>
          <li>
            Open&nbsp;
            <NavLink
              to="https://consent.criteo.com"
              target="_blank"
            >
              https://consent.criteo.com
            </NavLink>
          </li>
          <li>
            Select the account(s) to be re-authorized in the &quot;Marshall Insights&quot; Section
          </li>
          <li>
            Click on &quot;Revoke selected access&quot;.
          </li>
        </ul>
      </p>
    </Row>
*/

export default DCAInstructionsWalmartConnectAdvertising;
